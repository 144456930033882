<div class="ajuste" *ngIf="innerWidth>600"></div>
<div style="margin-top: 19%;" *ngIf="innerWidth<600"></div>
<!---->
<div *ngIf="carregando == true">
    <mat-spinner></mat-spinner>
</div>
<!---->
<button (click)="disponiveis()">teste</button>
<button (click)="HC()">Historico Clinico</button>
<div *ngIf="carregando == false">
    <div>
        <img src="{{urlink}}" alt="Avatar" class="avatar">
      
        <br>
        <div style="text-align: center;    
                margin-top:-10px;
                margin-bottom:50px;
                font-size: 20pt;">
            {{user.nombre}}
            <img src="../../../assets/icons/edit.png" style="height: 20px;" (click)="toMedicoGeral()">
        </div>

        <div style=" 
        width: 80%;  
        height: 1px;  
        background-color: rgb(160, 160, 160);  
        margin-bottom: 70px;  
        margin-right: auto;  
        margin-left: auto;  
        margin-top: 0px;">
        </div>

    </div>
    <mat-grid-list cols="2" rowHeight="200px" style="margin-left: 25%; margin-right: 25%;" gutterSize="5px"
        *ngIf="innerWidth>600">
        <mat-grid-tile>

            <button mat-raised-button class="button-style" (click)="toOrientacao()">
                <br>
                <img src="../../../assets/icons/steto.png" class="icon">
                <br>
                <p>Encontrar Orientacion</p>
                <br>
            </button>
        </mat-grid-tile>

        <mat-grid-tile>
            <button mat-raised-button class="button-style" (click)="toOrientacaoProgramada()">
                <br>
                <img src="../../../assets/icons/agenda.png" class="icon">
                <br>
                <p style="text-align: center;">Orientaciones Programadas</p>
                <br>
            </button>
        </mat-grid-tile>
        <mat-grid-tile>
            <button mat-raised-button class="button-style" (click)="toHistorico()">
                <br>
                <img src="../../../assets/icons/calendar.png" class="icon">
                <br>
                Historial de Orientaciones
                <br>
            </button>
        </mat-grid-tile>
        <mat-grid-tile>
            <button mat-raised-button class="button-style" (click)="toDatos()">
                <br>
                <img src="../../../assets/icons/grafico.jpg" class="icon">
                <br>
                Datos
                <br>
            </button>
        </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="2" rowHeight="200px" style="margin-left: 10%; margin-right: 10%;" gutterSize="5px"
        *ngIf="innerWidth<600">
        <mat-grid-tile>

            <button mat-raised-button class="button-style" (click)="toOrientacao()">
                
                <img src="../../../assets/icons/steto.png" class="icon">
                <br>
                Solicitar <br> Orientacion
                <br>
            </button>
        </mat-grid-tile>

        <mat-grid-tile>
            <button mat-raised-button class="button-style" (click)="toOrientacaoProgramada()">
                <img src="../../../assets/icons/agenda.png" class="icon">
                <br>
                Orientaciones <br> Programadas
                <br>
            </button>
        </mat-grid-tile>
        <mat-grid-tile>
            <button mat-raised-button class="button-style" (click)="toHistorico()">
                <img src="../../../assets/icons/calendar.png" class="icon">
                <br>
                Historial de <br> Orientaciones
                <br>
            </button>
        </mat-grid-tile>
        <mat-grid-tile>
            <button mat-raised-button class="button-style" (click)="toDatos()">
                <img src="../../../assets/icons/grafico.jpg" class="icon">
                <br>
                Datos
                <br>
                <br>
            </button>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<div class="ajuste"></div>