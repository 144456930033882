import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { Chart } from 'chart.js';

@Component({
    selector: 'app-datos',
    templateUrl: './datos.component.html',
    styleUrls: ['./datos.component.css']
})
export class DatosComponent implements OnInit {

    medico: string = '';

    constructor(
        private amplifyService: AmplifyService,
        private router: Router
    ) {
        this.amplifyService = amplifyService;
        this.amplifyService.authStateChange$.subscribe(authState => {
            this.medico = authState.user.attributes.profile;
            if (authState.state != "signedIn" && (authState.user.attributes.profile != "medico-geral" || authState.user.attributes.profile != "medico-especialista")) {
                this.router.navigateByUrl("");
            }
        })
    }

    toPrincipal() {
        if (this.medico == "medico-geral") {
            this.router.navigate(['principalG']);
        } else if (this.medico == "medico-especialista") {
            this.router.navigate(['principalE']);
        } else {
            this.router.navigateByUrl("");
        }
    }

    reload() {
        location.reload();
    }

    ngOnInit() {
        setTimeout(() => {
            if (this.medico == '') {
                this.reload();
            }
        }, 0)
        const grafico1 = new Chart("grafico1", {
            type: 'bar',
            data: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],//legenda de cada item
                datasets: [{
                    label: 'Últimos 7 días', //legenda do grafico
                    data: [12, 19, 3, 5, 2, 3], // valores que seram recebido do banco de dados
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });

        const grafico2 = new Chart("grafico2", {
            type: 'line',
            data: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'], //legenda de cada item
                datasets: [{
                    label: 'Horas trabajadas', //legenda do grafico
                    data: [12, 19, 3, 5, 2, 3], // valores que seram recebido do banco de dados
                    backgroundColor: 'rgba(255, 99, 132)',
                    borderColor: 'rgba(255, 99, 132)',
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });

        const grafico3 = new Chart("grafico3", {
            type: 'doughnut',
            data: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],//legenda de cada item
                datasets: [{
                    label: 'Últimos 7 días', //legenda do grafico
                    data: [12, 19, 3, 5, 2, 3], // valores que seram recebido do banco de dados
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });

        const grafico4 = new Chart("grafico4", {
            type: 'line',
            data: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],//legenda de cada item
                datasets: [{
                    label: 'Últimos 7 días', //legenda do grafico
                    data: [89, 19, 3, 50, 22, 3], // valores que seram recebido do banco de dados
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    borderWidth: 1
                }]
            },
            options: {
                animations: {
                    tension: {
                        duration: 1000,
                        easing: 'linear',
                        from: 1,
                        to: 0,
                        loop: true
                    }
                },
                scales: {
                    y: { // defining min and max so hiding the dataset does not change scale range
                        min: 0,
                        max: 100
                    }
                }
            }
        });

    }
}
