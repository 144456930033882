import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { Me } from 'src/app/model/modelo-me';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { ImgService } from 'src/app/service/img/img.service';

@Component({
  selector: 'app-principal-especialista',
  templateUrl: './principal-especialista.component.html',
  styleUrls: ['./principal-especialista.component.css']
})
export class PrincipalEspecialistaComponent implements OnInit {
  user = {
    nombre: '',
    id: ''
  }
  loading = true;
  carregando = true;
  urlink: any = "../../../assets/avatar.png";

  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private servico: CadastroService,
    private imgService: ImgService,
    private amplifyService: AmplifyService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn" || authState.user.attributes.profile != "medico-especialista") {
        this.router.navigateByUrl("");
      }
      this.user.id = authState.user.username;// retornar o ID do usuario
    })
  }

  ngOnInit(): void {
    this.getMedicoEspecialista();
    this.recuperaIMG();
    this.innerWidth = window.innerWidth;
    if (this.user.id == '') { // Recarreca a pag caso nao esteja carregado os dados
      location.reload();
    }
  }
  disponiveis() {
    this.router.navigate(['orientacion-esp']);
  }
  toMedicoEspecialista() {
    this.router.navigate(['edit-me']);
    setTimeout(function () { location.reload(); }, 0);
  }
  toHistorico() {
    this.router.navigate(['historial']);
  }
  toDatos() {
    this.router.navigate(['datos']);
  }

  getMedicoEspecialista() {
    this.servico.getMedicoEspecialista(this.user.id).subscribe((me: Me) => {
      this.construtorMedicoEspecialista(me);

    })
  }
  construtorMedicoEspecialista(me: any) {
    this.user.nombre = me.name
  }

  recuperaIMG() {
    this.imgService.getIMG(this.user.id).subscribe((img: any) => {
      if (!img.errorMessage) {
        this.urlink = `data:image/jpeg;base64,${img}`;
        this.carregando = false;
        this.loading = false;
      } else {
        this.carregando = false;
        this.loading = false;
      }
    });
  }
}
