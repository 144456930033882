<br>
<h1 class="text">Orietaciones Programadas</h1>
<hr>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="imagem">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"> <img src="/../../assets/avatar.jpg" class="avatar"> </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef> Data </th>
        <td mat-cell *matCellDef="let element"> {{element.data}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="star" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-button color='primary' (click)='toChat()'>
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
<button mat-raised-button color="warn" class='botaoVoltar' (click)='toPrincipal()'>
    <mat-icon>reply</mat-icon> Voltar
</button>