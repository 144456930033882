import { Component, HostListener, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { onAuthUIStateChange, AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { AmplifyService } from 'aws-amplify-angular';
import { Auth } from 'aws-amplify';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { Paciente } from 'src/app/model/modelo-paciente';
import { AdministradorService } from 'src/app/service/adm/adm.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  escondido = false;//Esconder os botoes na producao

  autenticado = false;
  cadastro = false;
  zoneinfo = '';
  registrado = false;
  profile = '';
  public innerWidth: any;
  userId: CognitoUserInterface | undefined;
  authState: AuthState | undefined;
  usuario = ' ';

  adm = {
    name: '',
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private servico: CadastroService,
    private ref: ChangeDetectorRef,
    private amplifyService: AmplifyService,
    private servicoADM: AdministradorService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      //console.log(authState)
      if (authState.state == "signedIn") {
        this.usuario = authState.user.e
        this.autenticado = true;
        this.zoneinfo = authState.user.attributes.zoneinfo;
        this.profile = authState.user.attributes.profile;
        this.getPaciente(authState.user.username);
        this.getUsuarioID(authState.user.username);
      }
    })
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.userId = authData as CognitoUserInterface;
      this.ref.detectChanges();
    })
  }

  getPaciente(id: string) {
    this.servico.getPaciente(id).subscribe((usuario: Paciente) => {
      if (usuario.id == id) {
        this.registrado = true;
      }
      if (usuario.name != null) {
        this.cadastro = true;
      }
    })
  }

  getUsuarioID(id: string) {
    this.servicoADM.getAdmID(id).subscribe((usuario: any) => {
      this.construirADMnome(usuario);
    })
  }
  construirADMnome(usuario: any) {
    this.adm.name = usuario.name;
  }
  // Encaminha para a pag de logar

  toMedico() {
    if (this.zoneinfo == 'welcome') {
      this.router.navigateByUrl('sala-de-confirmacao');//a mudar
      setTimeout(function () { location.reload(); }, 0)
    } else if (this.zoneinfo == 'waiting') {
      this.router.navigateByUrl('espera');
      //setTimeout(function () { location.reload(); }, 0)
    } else if (this.zoneinfo == 'denied' || this.zoneinfo == 'registered' || this.zoneinfo == null) {
      this.router.navigateByUrl('resgistro-medico');
      setTimeout(function () { location.reload(); }, 0)
    } else if (this.zoneinfo == 'accepted') {
      if (this.profile == 'medico-especialista') {
        this.router.navigateByUrl('principalE');
      } else if (this.profile == 'medico-geral') {
        this.router.navigateByUrl('principalG');
      }
    }
    else {
      console.log('error')
    }
  }

  async deslogar() {

    try {
      await Auth.signOut({ global: true });
      this.autenticado = false;
      this.router.navigate([''])
      setTimeout(function () { location.reload(); })
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  ngOnDestroy() {
    return onAuthUIStateChange;
  }
}
