<br>
<h1 class="titulo">Programado</h1>
<hr>
<div class="ajuste"></div>

<mat-card class="card">
    <h1 class="text">Médico</h1>
    <br>
    <img src="{{avatar}}" class="avatar">
    <mat-card-content>
        <br>
        <div>{{nome}}</div>
        <br>
        <div>{{programacao.local}}</div>
        <br>
        <div>{{programacao.data}}</div>
        <br>
        <div>{{programacao.horario}}</div>
        <br>
        <br>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="toPrincipal()">OK</button>
    </mat-card-actions>
</mat-card>