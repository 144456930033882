<div class="ajuste"></div>
<!--
   <link href='https://fonts.googleapis.com/css?family=Tangerine' rel='stylesheet'>
   <link href='https://fonts.googleapis.com/css?family=Heeboo' rel='stylesheet'>

   <mat-toolbar class="header" style="z-index: 10; position: fixed;">-->
        <!--Controle para o icone-->
         <!--<button mat-icon-button *ngIf="innerWidth<600" [matMenuTriggerFor]="menu">
            <mat-icon class="icon">menu</mat-icon>
        </button>

        <mat-menu class="mobile-menu" #menu="matMenu">
            <button mat-button (click)="toExperincia()"><span class="text">Experiencia</span></button>
            <p></p>
            <button mat-button><span class="text">Comunidad</span></button>
            <ul style="padding-right: 10px;" class="sub_text">
                <li><a (click)="toSmartfilms2020()">Smart Films 2020</a></li>
                <li><a (click)="toCovid19()">Covid-19</a></li>
            </ul>
            <p></p>
            <button mat-button><span class="text" >Nosotros</span></button>
            <ul style="padding-right: 10px;" class="sub_text">
                <li><a (click)="toQuienessomos()">Quiénes somos</a></li>
                <li><a (click)="toComooperamos()">Cómo operamos</a></li>
                <li><a (click)="toEconverde()">TEDIeconomía verde</a></li>
            </ul>
        </mat-menu>

        <a (click)="toHome()"> <img src="../../../assets/tedilogo.png" class="img"></a>

        <div *ngIf="innerWidth>600">
            <button mat-button class="text" (click)="toExperincia()">Experiencia</button>
            <button mat-button class="text" [matMenuTriggerFor]="menu1">Comunidad</button>
            <mat-menu #menu1="matMenu">
                <button class="text" (click)="toSmartfilms2020()" mat-menu-item>Smart Films 2020</button>
                <button class="text" (click)="toCovid19()" mat-menu-item>Covid-19</button>
            </mat-menu>
            <button mat-button class="text" [matMenuTriggerFor]="menu2">Nosotros</button>
            <mat-menu #menu2="matMenu">
                <button class="text" (click)="toQuienessomos()" mat-menu-item>Quiénes somos</button>
                <button class="text" (click)="toComooperamos()" mat-menu-item>Cómo operamos</button>
                <button class="text" (click)="toEconverde()" mat-menu-item>TEDIeconomía verde</button>
            </mat-menu>
        </div>

        <span class="example-spacer"></span>
        <button mat-raised-button color="primary" (click)="toPrecadastro()" *ngIf="autenticado==false && escondido == false">Registro Previo</button>

        <div *ngIf="autenticado==true && profile == 'paciente' && cadastro == true">
            <button mat-icon-button class="icon" (click)="toPrincipal()">
                <mat-icon>person</mat-icon>
            </button>
        </div>

        <div *ngIf="autenticado==true && profile == 'paciente' && cadastro == false">
            <button mat-icon-button class="icon" (click)="toEdit()">
                <mat-icon>person</mat-icon>
            </button>
        </div>

        <div *ngIf="autenticado == true && (profile != 'administrator' && profile != 'paciente')">
            <button mat-icon-button class="icon" (click)="toMedico()" >
                <mat-icon>person</mat-icon>
            </button>
        </div>

        <h2 style="color:white;" *ngIf="autenticado == true && profile == 'administrator'">{{adm.name}}</h2>
        <div *ngIf="autenticado == true && profile == 'administrator'">
            <button mat-icon-button class="icon" (click)="toADM()" >
                <mat-icon>person</mat-icon>
            </button>
        </div>

        <div>
            <button mat-icon-button class="icon" *ngIf="autenticado==true" (click)="deslogar()">
                <mat-icon>keyboard_return</mat-icon>
            </button>
        </div>
    </mat-toolbar>-->
