<div class="container-fluid bg-body py-5 my-5">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <div class="imgcontainer">
    <img src="../../../assets/tedilogobg.png" style="width: 400px;">
  </div>

  <div class="formulario" *ngIf="controle==0">
    <form>
      <mat-form-field appearance="fill" class="cadastro">
        <mat-label>Email</mat-label>
        <input matInput type="text" id="name" name="name" [(ngModel)]="user.login" required>
      </mat-form-field>
      <br>

      <mat-form-field appearance="fill" class="cadastro">
        <mat-label>Contraseña</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" id="senha" name="senha" [(ngModel)]="user.senha" required>

        <mat-icon mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          {{hide ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>
      <br>
      <button mat-raised-button color="primary" class="enterbtn" type="submit" [class.spinner]="loading"
        [disabled]="loading" (click)="signIn()">Entrar</button>

      <button mat-button color="primary" class="registerbtn" type="submit" [disabled]="loading"
        (click)="toRegistra()">¡Regístrate Ahora!</button>
      <br>
      <button mat-button color="primary" class="registerbtn" type="submit" [disabled]="loading"
        (click)="esqueceuAsenha()">Olvidé la contraseña</button>
    </form>
  </div>

  <div class="formulario" *ngIf="controle==1">
    <form>
      <mat-form-field appearance="fill" class="cadastro">
        <mat-label>Email</mat-label>
        <input matInput type="text" id="name" name="name" [(ngModel)]="email" required>
      </mat-form-field>
      <br>
      <br>
      <button mat-raised-button color="primary" class="enterbtn" type="submit" (click)="novoCodigo()">Enviar</button>
      <button mat-button color="primary" class="registerbtn" type="submit" (click)="logar()">Voltar login</button>
    </form>
  </div>

  <div class="formulario" *ngIf="controle==2">
    <form>
      <mat-form-field appearance="fill" class="cadastro">
        <mat-label>Email</mat-label>
        <input matInput type="text" id="name" name="name" [(ngModel)]="email" required>
      </mat-form-field>
      <br>

      <mat-form-field appearance="fill" class="cadastro">
        <mat-label>Codigo</mat-label>
        <input matInput type="text" id="name" name="name" [(ngModel)]="code" required>
      </mat-form-field>
      <br>

      <mat-form-field appearance="fill" class="cadastro">
        <mat-label>Senha</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" id="senha" name="senha" [(ngModel)]="senha" required>

        <mat-icon mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          {{hide ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>
      <br>
      <mat-form-field appearance="fill" class="cadastro">
        <mat-label>ContraSenha</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" id="nSenha" name="nSenha" [(ngModel)]="novaSenha" required>

        <mat-icon mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          {{hide ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>
      <br>
      <button mat-button color="primary" class="registerbtn" type="submit" (click)="novaSenhaCode()">Confirmar</button>
    </form>
  </div>

  <div class="formulario" *ngIf="controle==3">
    <h1>Valide Sua Senha</h1>
    <form>
      <mat-form-field appearance="fill" class="cadastro">
        <mat-label>Nova Senha</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" id="oldSenha" name="oldSenha" [(ngModel)]="senha" required>

        <mat-icon mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          {{hide ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>
      <br>

      <mat-form-field appearance="fill" class="cadastro">
        <mat-label>Confirmar Senha</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" id="senha" name="senha" [(ngModel)]="novaSenha" required>

        <mat-icon mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          {{hide ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>
      <br>
      <button mat-raised-button color="primary" class="enterbtn" type="submit" (click)="senhaADM()">Entrar</button>
    </form>
  </div>
</div>
<app-footer></app-footer>
