

<mat-grid-list cols="1" rowHeight="50px" *ngIf="innerWidth>600" style="background-color:rgb(242, 242, 242)">
    <div style="margin-top: 60px;"></div>
        <mat-grid-tile [rowspan]="8">
            <img src="../../../assets/backgrounds/SF2020.webp" style="width: 450px; height: 270px; position: absolute; z-index: 0;">
            <div class="video">
                <video style="width: 100%; height: 50%; display: block; margin-left: auto; margin-right: auto ; border-radius: 0px; pointer-events: none;" loop autoplay >
                    <source src="../../../assets/backgrounds/bwclouds.mp4" type="video/mp4" />
                    Browser not supported
                </video>
            </div>
        </mat-grid-tile>

        <mat-grid-tile [rowspan]="2" >
        </mat-grid-tile>

        <mat-grid-tile [rowspan]="7" style="background-color: rgb(135, 53, 28) ;">
            <p class="titulo-img">CREEMOS EN EL SÉPTIMO ARTE</p>
            <p class="texto-img">TEDI crea y cree en los artistas. <br> Junto a un gran equipo que hizo parte de estos proyectos, logramos quedar <br> seleccionados en tres categorías del Festival de cine SmartFilms 2020.</p>
            <p class="texto-out-img">#SmartFilms #TediEsSmartFilms #YoSoySmartFilms <br> #CafamOficial #HelloMoto #EmprenderDePelicula</p>
        </mat-grid-tile>

        <mat-grid-tile [rowspan]="2" >
        </mat-grid-tile>

        
        <mat-grid-tile [rowspan]="8">
            <img src="../../../assets/backgrounds/moviemedistetodo.webp" style="width: 321px; height: 463px; z-index: 0; margin-right: 20px;">
            <img src="../../../assets/backgrounds/movietedimaks.webp" style="width: 321px; height: 463px; z-index: 0; margin-right: 20px;">
            <img src="../../../assets/backgrounds/movieetapadeunpulso.webp" style="width: 321px; height: 463px; z-index: 0;">
        </mat-grid-tile>

        <mat-grid-tile [rowspan]="2" >
        </mat-grid-tile>

        <mat-grid-tile [rowspan]="15">
            <p class="titulo-img" style="top: 10%; z-index: 1;">NUESTROS ESTRENOS</p>
            <img src="../../../assets/backgrounds/camerabg.webp" style="position:absolute;margin-left: auto; z-index: 0; margin-right: auto;">
            <img src="../../../assets/backgrounds/movies1.webp" style="width: 315px; height: 558px; position: relative; z-index: 1; top: 5%; margin-left: 0px;">
            <img src="../../../assets/backgrounds/movies2.webp" style="width: 315px; height: 558px; position: relative; z-index: 1; top: 5%; margin-left: 10px;">
            <img src="../../../assets/backgrounds/movies3.webp" style="width: 315px; height: 558px; position: relative; z-index: 1; top: 5%; margin-left: 10px;">
        </mat-grid-tile>

        <mat-grid-tile [rowspan]="2" >
        </mat-grid-tile>

</mat-grid-list>




<mat-grid-list cols="1" rowHeight="50px" *ngIf="innerWidth<600" style="background-color:rgb(242, 242, 242)">

    <mat-grid-tile [rowspan]="6">
        <div style="margin-top: 60px;"></div>
        <img src="../../../assets/backgrounds/SF2020.webp" style="width: 300px; height: 180px; position: absolute; z-index: 0;">
        <div class="video">
            <video style="width: 100%; height: 100%; display: block; margin-left: auto; margin-right: auto ; border-radius: 0px; pointer-events: none;" loop autoplay >
                <source src="../../../assets/backgrounds/bwclouds.mp4" type="video/mp4" />
                Browser not supported
            </video>
        </div>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="7" style="background-color: rgb(135, 53, 28) ;">
        <p class="titulo-img" style="font-size: 20px;">CREEMOS EN EL SÉPTIMO ARTE</p>
        <p class="texto-img" style="font-size: 16px;">TEDI crea y cree en los artistas. <br> Junto a un gran equipo que hizo parte de estos proyectos, logramos quedar <br> seleccionados en tres categorías del Festival de cine SmartFilms 2020.</p>
        <p class="texto-out-img" style="font-size: 16px;">#SmartFilms #TediEsSmartFilms #YoSoySmartFilms <br> #CafamOficial #HelloMoto #EmprenderDePelicula</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>

    
    <mat-grid-tile [rowspan]="9">
        <img src="../../../assets/backgrounds/moviemedistetodo.webp" style="width: 321px; height: 463px; z-index: 0; margin-right: 20px;">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="9">
        <img src="../../../assets/backgrounds/movietedimaks.webp" style="width: 321px; height: 463px; z-index: 0; margin-right: 20px; margin-top: 25px;">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="9">
        <img src="../../../assets/backgrounds/movieetapadeunpulso.webp" style="width: 321px; height: 463px; z-index: 0; margin-right: 20px; margin-top: 5px;">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="13">
        <p class="titulo-img" style="font-size: 20px; top: 5%; z-index: 1;">NUESTROS ESTRENOS</p>
        <img src="../../../assets/backgrounds/camerabg.webp" style="position:absolute;margin-left: auto; z-index: 0; margin-right: auto;">
        <img src="../../../assets/backgrounds/movies1.webp" style="width: 315px; height: 558px; position: relative; z-index: 1; top: 5%; margin-left: 0px;">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="13">
        <img src="../../../assets/backgrounds/camerabg.webp" style="position:absolute;margin-left: auto; z-index: 0; margin-right: auto;">
        <img src="../../../assets/backgrounds/movies2.webp" style="width: 315px; height: 558px; position: relative; z-index: 1; top: 0%; margin-left: 10px;">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="13">
        <img src="../../../assets/backgrounds/camerabg.webp" style="position:absolute;margin-left: auto; z-index: 0; margin-right: auto;">
        <img src="../../../assets/backgrounds/movies3.webp" style="width: 315px; height: 558px; position: relative; z-index: 1; top: 0%; margin-left: 10px;">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>

</mat-grid-list>
