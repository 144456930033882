<mat-grid-list class="fixed-bg" cols="1" rowHeight="50px" *ngIf="innerWidth>600" style="background-color:rgb(242, 242, 242)">
    <div style="margin-top: 60px;"></div>
    <mat-grid-tile [rowspan]="4">

        <p class="titulo-img">TEDIeconomía Verde</p>

    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2">
    </mat-grid-tile>
        
    <mat-grid-tile [rowspan]="4">
    <p class="texto-out-imge">Sabemos que generar economía nunca debe ir en contravía<br>
         de nuestro planeta, por eso entendemos que los recursos del<br>
          planeta tierra son limitados y que, en virtud del principio<br>
           regenerativo de la naturaleza, TEDI trabaja incansablemente<br>
            por conocer y retribuirle a la naturaleza algo de lo que por su<br>
             operación haya utilizado.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <p class="texto-out-imgd">Fomentamos el bienestar de las personas a través de la<br>
             práctica de la equidad social en todo lo que hacemos.<br>
              Nuestro modelo económico basado en colaboración nos<br>
               permite reducir la huella ambiental dado el conocimiento y el<br>
                compromiso que tenemos frente a los riesgos ambientales<br>
                 que puedan presentarse al operar el servicio de Orientación TEDI.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <p class="texto-out-imge">TEDI se encuentra alineado con algunos de los Objetivos de<br>
             Desarrollo Sostenible – ODS procurando solucionar<br>
              problemas con comunidades, nuestra responsabilidad social y<br>
               problemas a nivel planeta, nuestro compromiso con la Tierra. </p>
    </mat-grid-tile>
        
    <mat-grid-tile [rowspan]="4">
                <p class="texto-out-imgd">Tenemos claro que fomentar una cultura de bienestar en las<br>
                     personas va más allá de la prestación de un servicio de<br>
                      calidad con responsabilidad, por eso, nuestro modelo<br>
                       económico propende por una opción viable, rápida y de<br>
                       pago directo a nuestros prestadores de servicios médicos<br>
                        que les permitirá circular también su economía de forma<br>
                         diferente mejorando su calidad de vida y de forma<br>
                          socialmente incluyente.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <p class="texto-out-imge">Los pilares de nuestro servicio de Orientación en salud se<br>
             basan en la prevención de la enfermedad como eje<br>
              fundamental del bienestar de las personas más que en el<br>
               tratamiento de esta. No obstante, en nuestra integralidad<br>
                atendemos a pacientes en cualquier etapa de su patología,<br>
                 brindándoles oportunidades de acceso y empoderamiento en<br>
                  el conocimiento de su condición de salud con el fin de darles<br>
                   calidad de vida a través del valor como personas. </p>
    </mat-grid-tile>
        
    <mat-grid-tile [rowspan]="4">
                <p class="texto-out-imgd">Así mismo, creemos que los servicios de salud deben tratarse<br>
                     más sobre las personas que sobre las instituciones que<br>
                      prestan atención en salud y muchos modelos actuales no<br>
                       brindan soluciones efectivas que sean incluyentes, una forma<br>
                        en la que contrarrestamos esto, es institucionalizando a las<br>
                        personas y a los profesionales en salud, empoderándolos<br>
                         para que naturalmente puedan cambiar la forma en que<br>
                          tradicional se hace medicina.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2">
    </mat-grid-tile>

</mat-grid-list>








<mat-grid-list class="fixed-bg" cols="1" rowHeight="50px" *ngIf="innerWidth<600" style="background-color:rgb(242, 242, 242)">
    <div style="margin-top: 30px;"></div>
    <mat-grid-tile [rowspan]="4">

        <p class="titulo-img" style="font-size: 40px; right: 35px;">TEDIeconomía Verde</p>

    </mat-grid-tile>

        
    <mat-grid-tile [rowspan]="4">
    <p style="font-size: 16px; left: 10px; margin-left: 10px; margin-right: 10px; text-align: left; color: rgb(255, 255, 255); font-family: 'Heeboo';">
        Sabemos que generar economía nunca debe ir en contravía
         de nuestro planeta, por eso entendemos que los recursos del
          planeta tierra son limitados y que, en virtud del principio
           regenerativo de la naturaleza, TEDI trabaja incansablemente
            por conocer y retribuirle a la naturaleza algo de lo que por su
             operación haya utilizado.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <p style="font-size: 16px; left: 10px; margin-left: 10px; margin-right: 10px; text-align: right; color: rgb(255, 255, 255); font-family: 'Heeboo';">
            Fomentamos el bienestar de las personas a través de la
             práctica de la equidad social en todo lo que hacemos.
              Nuestro modelo económico basado en colaboración nos
               permite reducir la huella ambiental dado el conocimiento y el
                compromiso que tenemos frente a los riesgos ambientales
                 que puedan presentarse al operar el servicio de Orientación TEDI.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <p style="font-size: 16px; left: 10px; margin-left: 10px; margin-right: 10px; text-align: left; color: rgb(255, 255, 255); font-family: 'Heeboo';">
            TEDI se encuentra alineado con algunos de los Objetivos de
             Desarrollo Sostenible – ODS procurando solucionar
              problemas con comunidades, nuestra responsabilidad social y
               problemas a nivel planeta, nuestro compromiso con la Tierra. </p>
    </mat-grid-tile>
        
    <mat-grid-tile [rowspan]="4">
                <p style="font-size: 16px; left: 10px; margin-left: 10px; margin-right: 10px; text-align: right; color: rgb(255, 255, 255); font-family: 'Heeboo';">
                    Tenemos claro que fomentar una cultura de bienestar en las
                     personas va más allá de la prestación de un servicio de
                      calidad con responsabilidad, por eso, nuestro modelo
                       económico propende por una opción viable, rápida y de
                       pago directo a nuestros prestadores de servicios médicos
                        que les permitirá circular también su economía de forma
                         diferente mejorando su calidad de vida y de forma
                          socialmente incluyente.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <p style="font-size: 16px; left: 10px; margin-left: 10px; margin-right: 10px; text-align: left; color: rgb(255, 255, 255); font-family: 'Heeboo';">
            Los pilares de nuestro servicio de Orientación en salud se
             basan en la prevención de la enfermedad como eje
              fundamental del bienestar de las personas más que en el
               tratamiento de esta. No obstante, en nuestra integralidad
                atendemos a pacientes en cualquier etapa de su patología,
                 brindándoles oportunidades de acceso y empoderamiento en
                  el conocimiento de su condición de salud con el fin de darles
                   calidad de vida a través del valor como personas. </p>
    </mat-grid-tile>
        
    <mat-grid-tile [rowspan]="4">
                <p style="font-size: 16px; left: 10px; margin-left: 10px; margin-right: 10px; text-align: right; color: rgb(255, 255, 255); font-family: 'Heeboo';">
                    Así mismo, creemos que los servicios de salud deben tratarse
                     más sobre las personas que sobre las instituciones que
                      prestan atención en salud y muchos modelos actuales no
                       brindan soluciones efectivas que sean incluyentes, una forma
                        en la que contrarrestamos esto, es institucionalizando a las
                        personas y a los profesionales en salud, empoderándolos
                         para que naturalmente puedan cambiar la forma en que
                          tradicional se hace medicina.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2">
    </mat-grid-tile>

</mat-grid-list>
    
    