import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';

@Component({
  selector: 'app-avaliacao',
  templateUrl: './avaliacao.component.html',
  styleUrls: ['./avaliacao.component.css']
})
export class AvaliacaoComponent implements OnInit {

  data = "04/11/2020";
  horario = "6 pm";
  nome = "Jose";
  urlink: string="../../../assets/avatar.png";

  rating = 0;
  starCount = 5;
  ratingArr: boolean[] = []; // true = solid star; false = empty star

  snackBarDuration = 1000;
  response = [
    'Em que posdemos melhorar?',
    'Em que posdemos melhorar??',
    'Faremos melhor da proxima vez',
    'Estamos feliz por te gostado!',
    'Muito Obrigado!'
  ]

  constructor(
    private amplifyService: AmplifyService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { 
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn") {
        this.router.navigateByUrl("");
      }
    })
    this.ratingArr = Array(this.starCount).fill(false);
  }


  ngOnInit(): void {
  }

  returnStar(i: number) {
    if (this.rating >= i + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  onClick(i: number) {
    this.rating = i + 1;
    this.snackBar.open(this.response[i], '', {
      duration: this.snackBarDuration,
      panelClass: ['snack-bar']
    });

  }


  toHistorico(){
    this.router.navigate(['historico']);
  }

  

}
