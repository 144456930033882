import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Paises } from 'src/app/model/paises';
import { AdmService } from 'src/app/service/adm2/adm.service';
import { ImgService } from 'src/app/service/img/img.service';



@Component({
  selector: 'app-registro-medico',
  templateUrl: './registro-medico.component.html',
  styleUrls: ['./registro-medico.component.css']
})

export class RegistroMedicoComponent implements OnInit {

  //Controle do autocomplete
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  options = this.paises.paises
  
  carregando = true;

  user = {
    id: '',
    role: '',
    email: '',
    zoneinfo: ''
  }

  loading = false;
  comentario = '';

  conta = {
    img: '',
    nombre: '',
    tipodocumento: '',
    documento: '',
    nacionalidad: '',
    genero: '',
    ciudad: '',
    nacimiento: '',
    direccion: '',
    email: '',
    phone: '',
    rethus: '',
  }

  urlink: any = "../../../assets/avatar.png";
  percentDone: number = 0;
  uploadSuccess: boolean = false;
  size: any;
  width: number = 0;
  height: number = 0;
  imageChangedEvent: any = '';
  controleIMG: number = 0;
  controle: number = 0;
  avatar: number = 0;

  async selectFile(event: any) {
    await this.tamanho(event);
  }

  constructor(
    private paises: Paises,
    public dialog: MatDialog,
    private imgService: ImgService,
    private amplifyService: AmplifyService,
    private servico: AdmService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn" && (authState.user.attributes.profile != 'medico-especialista' || authState.user.attributes.profile != 'medico-geral')) {
        this.router.navigateByUrl('');
      }
      this.user.id = authState.user.username;// retornar o ID do usuario
      this.user.role = authState.user.attributes.profile;// 
      this.user.email = authState.user.attributes.email;
      this.conta.email = authState.user.attributes.email;
      this.user.zoneinfo = authState.user.attributes.zoneinfo;
      this.getUsuarioID();
    })
  }

  tamanho(event: any) {
    this.percentDone = 100;
    this.uploadSuccess = true;
    let image: any = event.target.files[0];
    this.size = image.size;
    let fr = new FileReader();
    fr.onload = () => { // when file has loaded
      var img: any = new Image();
      img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        this.cortar(event);
      };
      img.src = fr.result; // This is the data URL 
    };
    fr.readAsDataURL(image);
  }

  cortar(event: any) {
    if (this.width < 100 || this.height < 100) {
      this.openDialogErro();
    } else if (this.width == this.height && this.width <= 256) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (event: any) => {
        this.avatar = 1;
        this.urlink = event.target.result;
      }
    } else {
      this.controleIMG = 1;
      this.fileChangeEvent(event);
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.urlink = event.base64;
    this.controle = 1;
    this.avatar = 1;
  }

  image64() {
    this.controleIMG = 0;
  }

  ngOnInit(): void {
    this.recuperaIMG();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    //this.conta.nacionalidad = filterValue;
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  verificar() {
    let aux = 0;
    for (let i = 0; i < this.options.length - 1; i++) {
      if (this.options[i] == this.conta.nacionalidad) {
        this.cadastrar();
        aux = 1;
      }
    }
    if( aux == 0){
      this.conta.nacionalidad = '';
    }
  }

  getUsuarioID() {
    this.servico.getUsuarioID(this.user.id).subscribe((usuario: any) => {
      const tamanho = usuario.length;
      console.log(usuario)
      console.log(usuario[tamanho - 1])
      this.construtor(usuario[tamanho - 1]);
    })
  }

  construtor(usuario: any) {
    if (usuario.avatar != null) {
      this.avatar = usuario.avatar;
    }
    this.comentario = usuario.comment,
      this.conta.nombre = usuario.name,
      this.conta.tipodocumento = usuario.document_type,
      this.conta.documento = usuario.document_number,
      this.conta.genero = usuario.gender,
      this.conta.nacionalidad = usuario.nationality,
      this.conta.ciudad = usuario.city,
      this.conta.direccion = usuario.address,
      this.conta.nacimiento = usuario.birth,
      this.conta.phone = usuario.phone,
      this.conta.rethus = usuario.rethus
  }

  cadastrar() {
    this.loading = true;
    const data = {
      avatar: this.avatar,
      id: this.user.id,
      role: this.user.role,// tipo de medico
      name: this.conta.nombre,
      document_type: this.conta.tipodocumento,
      document_number: this.conta.documento,
      gender: this.conta.genero,
      birth: this.conta.nacimiento,
      email: this.user.email,
      nationality: this.conta.nacionalidad,
      city: this.conta.ciudad,
      address: this.conta.direccion,
      phone: this.conta.phone,// Verificar se esta e a variavel
      rethus: this.conta.rethus,
      interest_group: '',
      interest_specialty: ''
    };
    this.servico.putMedicoParaAprovacao(data)
      .subscribe(
        response => {
          console.log(response);
          console.log(data);
          if(this.avatar == 1){
            this.salvarIMG();
          }
          this.zoneInfo()
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  zoneInfo() {
    const data = {
      id: this.user.id,
      attritutename: 'zoneinfo',
      attritutevalue: 'waiting'
    }
    this.servico.putZoneInfo(data)
      .subscribe(
        response => {
          console.log(response);
          this.router.navigate(['espera']);
        },
        error => {
          console.log(error);
        }
      );
  }

  salvarIMG() {
    const data = {
      id: this.user.id,
      img64: this.urlink.substr(23)
    }
    if (this.controle == 1) {
      data.img64 = this.urlink.substr(22);
    }
    console.log(data)
    this.imgService.putIMG(data)
      .subscribe(
        response => {
          console.log(response)
        },
        error => {
          console.log(error)
        }
      );
  }

  recuperaIMG() {
    this.imgService.getIMG(this.user.id).subscribe((img: any) => {
      if(!img.errorMessage){
        this.urlink = `data:image/jpeg;base64,${img}`;
      }
      this.carregando = false;
    });
  }

  openDialogErro() {
    this.dialog.open(ErrorIMG);
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'error-img-menor.html',
})
export class ErrorIMG {
  constructor(
    public dialog: MatDialog
  ) { }
  fechar() {
    this.dialog.closeAll();
  }
}