import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { ChatGptService } from 'src/app/service/chatGpt/chat-gpt.service';
import { ImgService } from 'src/app/service/img/img.service';


@Component({
  selector: 'app-orientacion-chat-gpt',
  templateUrl: './orientacion-chat-gpt.component.html',
  styleUrls: ['./orientacion-chat-gpt.component.css'],
})
export class OrientacionChatGPTComponent implements OnInit {

  digitando = false;
  resposta = false;
  inputTemporario: any;
  answers_count = 1;
  loadingBTN = false;
  loadingPNG = true;
  auxiliar: any;

  consultaEmAberto: boolean

  urlink: any = "../../../assets/avatar.png";
  iaImg: any = "../../../assets/avatar.png";
  user = {
    id: '',
    symptom: '',
    additional_information: '',
    language: 'colombian spanish'
  }

  responseInfo: any = {
    cause: '',
    treatment: '',
    treatment_pharmacological:'',
    leaflet: '',
    natural: '',
    datetime: ''
  }

  chat_user = {
    user_01: undefined,
    user_02: undefined,
    user_03: undefined,
    user_04: undefined,
    user_05: undefined
  }

  chat_ai = {
    ai_01: undefined,
    ai_02: undefined,
    ai_03: undefined,
    ai_04: undefined,
    ai_05: undefined
  }

  constructor(
    private gpt: ChatGptService,
    private amplifyService: AmplifyService,
    private imgService: ImgService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn") {
        this.router.navigateByUrl("");
      }
      this.user.id = authState.user.username;// retornar o ID do usuario
    })
  }

  ngOnInit(): void {
    this.checarAtendimentoAberto();
    this.recuperaIMG();
  }

  consultar() {
    /*if para verificar se o sintoma esta em branco*/
    this.loadingBTN = true;
    this.gpt.putGPT(this.user).subscribe(
      response => {
        this.loadingBTN = false;
        this.responseInfo = response;
        this.resposta = true;
      },
      error => {
        console.log(error);
      }
    )
  }

  chatPergunta() {
    switch (this.answers_count) {
      case 0:
        this.chat_user.user_01 = this.inputTemporario;
        this.chat();
        break;
      case 1:
        this.chat_user.user_02 = this.inputTemporario;
        this.chat();
        break;
      case 2:
        this.chat_user.user_03 = this.inputTemporario;
        this.chat();
        break;
      case 3:
        this.chat_user.user_04 = this.inputTemporario;
        this.chat();
        break;
      case 4:
        this.chat_user.user_05 = this.inputTemporario;
        this.chat();
        break;
      default:
        console.log("Acabou suas perguntas")
    }
  }

  chat() {
    this.digitando = true;
    const data = {
      id: this.user.id,
      datetime: this.responseInfo.datetime,
      user_input: this.inputTemporario
    }
    this.inputTemporario = '';
    this.gpt.putChat(data).subscribe(
      response => {
        this.auxiliar = response
        switch (this.answers_count) {
          case 0:
            this.chat_ai.ai_01 = this.auxiliar.response;
            break;
          case 1:
            this.chat_ai.ai_02 = this.auxiliar.response;
            break;
          case 2:
            this.chat_ai.ai_03 = this.auxiliar.response;
            break;
          case 3:
            this.chat_ai.ai_04 = this.auxiliar.response;
            break;
          case 4:
            this.chat_ai.ai_05 = this.auxiliar.response;
            break;
          default:
            console.log("Acabou suas perguntas")
        }
        this.digitando = false;
        this.answers_count++;
      },
      error => {
        console.log(error);
      }
    )
  }

  checarAtendimentoAberto() {
    this.gpt.getConsultaAberta(this.user.id).subscribe((usuario: any) => {
      //console.log(this.responseInfo)
      //console.log(usuario)
      if (usuario.done == false) {
        this.consultaEmAberto = usuario.done;
        this.responseInfo.cause = usuario.AI_cause;
        this.responseInfo.treatment = usuario.AI_treatment;
        this.responseInfo.treatment_pharmacological = usuario.AI_treatment_pharmacological;
        this.responseInfo.leaflet = usuario.AI_leaflet;
        this.responseInfo.natural = usuario.AI_natural_medicine;
        this.responseInfo.datetime = usuario.datetime;
        this.user.symptom = usuario.user_symptom;
        this.user.additional_information = usuario.user_additional_information;
        this.resposta = true;
        this.chat_user.user_01 = usuario.user_01;
        this.chat_user.user_02 = usuario.user_02;
        this.chat_user.user_03 = usuario.user_03;
        this.chat_user.user_04 = usuario.user_04;
        this.chat_user.user_05 = usuario.user_05;

        this.chat_ai.ai_01 = usuario.ai_01;
        this.chat_ai.ai_02 = usuario.ai_02;
        this.chat_ai.ai_03 = usuario.ai_03;
        this.chat_ai.ai_04 = usuario.ai_04;
        this.chat_ai.ai_05 = usuario.ai_05;

        this.answers_count = usuario.answers_count;
      }
      //console.log(this.answers_count)
      this.loadingPNG = false;
    })
  }

  finalizar(value: boolean) {
    const data = {
      id: this.user.id,
      datetime: this.responseInfo.datetime,
      evaluation: value
    }
    this.gpt.putFinalizar(data).subscribe(
      response => {
        this.router.navigate(['principal'])
        setTimeout(function () { location.reload(); }, 10)
      },
      error => {
        console.log(error);
      }
    )
  }
  recuperaIMG() {
    this.imgService.getIMG(this.user.id).subscribe((img: any) => {
      if (!img.errorMessage) {
        this.urlink = `data:image/jpeg;base64,${img}`;
        //this.loading = false;
      } else {
        //this.loading = false;
      }
    });
  }

}
