<div class="bg-dark">

  <!--NavBar-->
  <!--<div id="navBarDark"></div>-->
  <!--NavBar-->

  <!--Spacer-->
  <div class="py-5"></div>
  <!--Spacer-->

  <!--Hero Orientacion IA-->
  <div class="container mt-5 mb-5 pt-5" style="min-height: 60vh;">
    <div class="row text-light text-center">
      <h1 class="fw-bold">Transformando la atención médica:</h1>
      <h1 class="fw-bold">Explora nuestro innovador chat de <span class="" style="color: #3182a6;">IA</span> en <span
          class="" style="color: #3182a6;">TEDIMEDICINA</span></h1>
    </div>
    <div class="row text-light text-center col-xl-9 mx-auto mt-5">
      <p class="fs-5">Nuestro chat está diseñado para brindar respuestas y orientación confiables sobre una amplia
        gama de preguntas médicas. Ya sea que necesites información sobre síntomas, diagnósticos o tratamientos,
        nuestro chat está aquí para ayudarte las 24 horas del día, los 7 días de la semana.</p>
    </div>
    <div class="row text-center">
      <div class="mt-3">
         <!--Retirado parar deploy-->
        <!--
        <a href="" class="btn text-light my-1 mx-1" style="background-color: #2e5e88;">Comenzar prueba ahora</a>
        <a href="" class="btn btn-outline-light my-1 mx-1">¡Conoce más!</a>
        -->
      </div>
    </div>
  </div>
  <!--Hero Orientacion IA-->

  <!--Choose us - Orientacion IA-->
  <div class="container-fluid">
    <div class="container rounded-4 shadow bg-light mt-5 mb-5 py-4">
      <div class="row text-center py-3">
        <h1 class="fw-bold">¿Por qué elegir TEDIMEDICINA?</h1>
      </div>
      <div class="row mt-4">
        <!--Item 1-->
        <div class="col-xl-4 col-md-10 mx-auto text-center my-3">
          <h4 class="fw-bold"><i class="bi bi-check2-circle tediColor-one"></i>&nbsp;&nbsp;Respuestas
            confiables, respaldadas por la ciencia</h4>
          <p>Con nuestra avanzada IA, nos aseguramos de proporcionar respuestas médicas precisas y basadas en
            la última evidencia científica.</p>
        </div>

        <!--Item 2-->
        <div class="col-xl-4 col-md-10 mx-auto text-center my-3">
          <h4 class="fw-bold"><i class="bi bi-check2-circle tediColor-one"></i>&nbsp;&nbsp;Privacidad y
            confidencialidad</h4>
          <p>Entendemos la importancia de la privacidad médica. Nuestro sistema está diseñado para proteger
            tus datos y mantener toda la información confidencial. Puedes sentirte seguro al compartir tus
            inquietudes médicas con nosotros.</p>
        </div>

        <!--Item 3-->
        <div class="col-xl-4 col-md-10 mx-auto text-center my-3">
          <h4 class="fw-bold"><i class="bi bi-check2-circle tediColor-one"></i>&nbsp;&nbsp;Experiencia
            personalizada</h4>
          <p>Adaptamos nuestras respuestas a tus necesidades específicas. A medida que interactúas con nuestro
            chat GPT, aprendemos de tus preferencias y ofrecemos recomendaciones personalizadas para mejorar
            tu experiencia de usuario.</p>
        </div>
      </div>
    </div>
  </div>
  <!--Choose us - Orientacion IA-->

  <!--Spacer-->
  <div class="py-5"></div>
  <!--Spacer-->

  <!--Steps - Orientacion IA-->
  <div class="container-fluid">
    <div class="container mb-5">
      <div class="row text-light text-center col-xl-8 mx-auto">
        <h1 class="fw-bold">Cómo funciona la Orientación IA de TEDIMEDICINA</h1>
      </div>
    </div>
    <!--Step 1-->
    <div class="container my-3">
      <div class="row align-items-center">
        <div class="col-xl-5 mx-auto text-start py-4 me-0">
          <p class="tediColor-one fs-6 fw-bold m-0 p-0">PASO 1</p>
          <h1 class="text-light fw-bold">Haznos tus preguntas</h1>
          <p class="text-light">Ingresa tus preguntas o inquietudes médicas en nuestro chat GPT. No importa
            cuán complejas sean, nuestro sistema está preparado para brindarte respuestas claras y
            comprensibles.</p>
        </div>
        <div class="col-xl-5 mx-auto text-center py-4 ms-0">
          <img class="img-fluid rounded-4 shadow" width="400" src="https://res.cloudinary.com/juandacloud/image/upload/v1692313593/afoco-projects/tedi/1_h1mmwy.jpg" alt="step-1" srcset="">
        </div>
      </div>
    </div>
    <!--Step 2-->
    <div class="container my-3">
      <div class="row align-items-center">
        <div class="col-xl-5 mx-auto text-center py-4 me-0">
          <img class="img-fluid rounded-4 shadow" width="400" src="https://res.cloudinary.com/juandacloud/image/upload/v1692313593/afoco-projects/tedi/2_c9xewu.jpg" alt="step-1" srcset="">
        </div>
        <div class="col-xl-5 mx-auto text-start py-4 ms-0">
          <p class="tediColor-one fs-6 fw-bold m-0 p-0">PASO 2</p>
          <h1 class="text-light fw-bold">Inteligencia artificial al rescate</h1>
          <p class="text-light">Nuestra avanzada tecnología de inteligencia artificial analiza tu pregunta y
            busca en nuestra base de datos médica actualizada para proporcionarte una respuesta precisa. La
            IA de TEDIMEDICINA aprende constantemente de nuevas investigaciones y avances en medicina para
            mantenerte al día.</p>
        </div>
      </div>
    </div>
    <!--Step 3-->
    <div class="container my-3">
      <div class="row align-items-center">
        <div class="col-xl-5 mx-auto text-start py-4 me-0">
          <p class="tediColor-one fs-6 fw-bold m-0 p-0">PASO 3</p>
          <h1 class="text-light fw-bold">Respuestas instantáneas</h1>
          <p class="text-light">En cuestión de segundos, recibirás respuestas detalladas y bien fundamentadas
            en tu pantalla. Nuestro objetivo es brindarte información clara y comprensible para que puedas
            tomar decisiones informadas sobre tu salud.</p>
        </div>
        <div class="col-xl-5 mx-auto text-center py-4 ms-0">
          <img class="img-fluid rounded-4 shadow" width="400" src="https://res.cloudinary.com/juandacloud/image/upload/v1692313592/afoco-projects/tedi/3_keth8x.jpg" alt="step-1" srcset="">
        </div>
      </div>
    </div>
  </div>
  <!--Steps - Orientacion IA-->

  <!--Spacer-->
  <div class="py-5"></div>
  <!--Spacer-->

  <!--CTA - Orientacion IA-->
  <div class="container-fluid">
    <div class="container rounded-4 shadow bg-light mt-5 mb-5 py-5">
      <div class="row text-center col-xl-8 mx-auto">
        <h2 class="fw-bold">¡Únete a la revolución de la medicina inteligente con TEDIMEDICINA!</h2>
        <p class="fs-5 mt-3">Estamos emocionados de tenerte como parte de nuestra comunidad. No importa si eres
          un paciente curioso, un profesional de la salud o simplemente</p>
      </div>
      <div class="row text-center">
        <div class="mt-3">
           <!--Retirado parar deploy-->
          <!--
          <a href="" class="btn text-light my-1 mx-1" style="background-color: #2e5e88;">Comenzar prueba ahora</a>
          <a href="" class="btn btn-outline-secondary my-1 mx-1">¡Conoce más!</a>
          -->
        </div>
      </div>
    </div>
  </div>
  <!--CTA - Orientacion IA-->

  <!--Footer-->
  <!--<div id="footerDark"></div>-->
  <app-footer></app-footer>
</div>
