import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Me } from 'src/app/model/modelo-me';
import { Paises } from 'src/app/model/paises';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { ImgService } from 'src/app/service/img/img.service';

@Component({
  selector: 'app-edit-me',
  templateUrl: './edit-me.component.html',
  styleUrls: ['./edit-me.component.css']
})
export class EditMeComponent implements OnInit {

  //Controle do autocomplete
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  options = this.paises.paises
  carregando = true;
  user = {
    id: '',
    role: '',
    email: '',
    zoneinfo: ''
  }
  loading = false;
  conta = {
    img: '',
    nombre: '',
    tipodocumento: '',
    documento: '',
    nacionalidad: '',
    genero: '',
    ciudad: '',
    nacimiento: '',
    direccion: '',
    email: '',
    phone: '',
    rethus: '',
  }
  ME: Me | undefined;

  urlink: any = this.conta.img || "../../../assets/avatar.png";
  percentDone: number = 0;
  uploadSuccess: boolean = false;
  size: any;
  width: number = 0;
  height: number = 0;
  imageChangedEvent: any = ''
  controleIMG: number = 0;
  controle: number = 0;
  avatar: number = 0;

  constructor(
    private paises: Paises,
    public dialog: MatDialog,
    private imgService: ImgService,
    private amplifyService: AmplifyService,
    private servico: CadastroService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn") {
        this.router.navigateByUrl('');
      }
      this.user.id = authState.user.username;// retornar o ID do usuario
      this.user.role = authState.user.attributes.profile;// 
      this.user.email = authState.user.attributes.email;
      this.conta.email = authState.user.attributes.email;
      this.user.zoneinfo = authState.user.attributes.zoneinfo;

    })
  }

  async selectFile(event: any) {
    await this.tamanho(event);
  }

  tamanho(event: any) {
    this.percentDone = 100;
    this.uploadSuccess = true;
    let image: any = event.target.files[0];
    this.size = image.size;
    let fr = new FileReader();
    fr.onload = () => { // when file has loaded
      var img: any = new Image();
      img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        this.cortar(event);
      };
      img.src = fr.result; // This is the data URL 
    };
    fr.readAsDataURL(image);
  }

  cortar(event: any) {
    if (this.width < 100 || this.height < 100) {
      this.openDialogErro();
    } else if (this.width == this.height && this.width <= 256) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (event: any) => {
        this.avatar = 1;
        this.controle = 1;
        this.urlink = event.target.result;
      }
    } else {
      this.controleIMG = 1;
      this.fileChangeEvent(event);
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.urlink = event.base64;
    this.controle = 1;
    this.avatar = 1;
  }

  image64() {
    this.controleIMG = 0;
  }

  ngOnInit(): void {
    this.getMedicoEspecialista();
    this.recuperaIMG();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    //this.conta.nacionalidad = filterValue;
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  verificar() {
    let aux = 0;
    for (let i = 0; i < this.options.length - 1; i++) {
      if (this.options[i] == this.conta.nacionalidad) {
        this.cadastrar_alterar_medico_especialista();
        aux = 1;
      }
    }
    if (aux == 0) {
      this.conta.nacionalidad = '';
    }
  }

  cadastrar_alterar_medico_especialista() {
    this.loading = true;
    const data = {
      avatar: this.avatar,
      id: this.user.id,
      role: this.user.role,// tipo de medico
      name: this.conta.nombre,
      document_type: this.conta.tipodocumento,
      document_number: this.conta.documento,
      gender: this.conta.genero,
      birth: this.conta.nacimiento,
      email: this.user.email,
      nationality: this.conta.nacionalidad,
      city: this.conta.ciudad,
      address: this.conta.direccion,
      phone: this.conta.phone,// Verificar se esta e a variavel
      rethus: this.conta.rethus,
      interest_group: '',
      interest_specialty: ''
    };
    this.servico.putMedicoEspecialista(data)
      .subscribe(
        response => {
          this.loading = false;
          console.log(response);
          this.loading = false;
          if (this.avatar == 1) {
            this.salvarIMG();
          } else {
            this.router.navigate(['principal']);
            setTimeout(function () { location.reload(); }, 0)
          }
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  getMedicoEspecialista() {
    this.servico.getMedicoEspecialista(this.user.id).subscribe((me: Me) => {
      this.ME = me;
      this.construtorMedicoEspecialista(me);

    })
  }

  construtorMedicoEspecialista(mg: any) {
    if (mg.avatar != null) {
      this.avatar = mg.avatar;
    }
    this.conta.nombre = mg.name,
      this.conta.tipodocumento = mg.document_type,
      this.conta.documento = mg.document_number,
      this.conta.genero = mg.gender,
      this.conta.nacionalidad = mg.nationality,
      this.conta.ciudad = mg.city,
      this.conta.direccion = mg.address,
      this.conta.email = this.user.email,
      this.conta.nacimiento = mg.birth,
      this.conta.phone = mg.phone,
      this.conta.rethus = mg.rethus,
      this.carregando = false
  }

  /*Feito*/
  salvarIMG() {
    const data = {
      id: this.user.id,
      img64: this.urlink.substr(23)
    }
    if (this.controle == 1) {
      data.img64 = this.urlink.substr(22);
    }
    console.log(data)
    this.imgService.putIMG(data)
      .subscribe(
        response => {
          console.log(response)
          if (this.user.zoneinfo == 'waiting') {
            this.router.navigate(['espera']);
          } else {
            this.router.navigate(['principalE']);
            setTimeout(function () { location.reload(); }, 0)
          }
        },
        error => {
          console.log(error)
        }
      );
  }

  recuperaIMG() {
    this.imgService.getIMG(this.user.id).subscribe((img: any) => {
      if (!img.errorMessage) {
        this.urlink = `data:image/jpeg;base64,${img}`;
      }
      this.loading = false
    });
  }
  openDialogErro() {
    this.dialog.open(ErrorIMG);
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'error-img-menor.html',
})
export class ErrorIMG {
  constructor(
    public dialog: MatDialog
  ) { }
  fechar() {
    this.dialog.closeAll();
  }
}
