import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { Paciente } from 'src/app/model/modelo-paciente';
import { Mg } from 'src/app/model/modelo-mg';
import { Me } from 'src/app/model/modelo-me';
import { MatDialog } from '@angular/material/dialog';
import { SenhaService } from 'src/app/service/senha/senha.service';

@Component({
  selector: 'app-entrar',
  templateUrl: './entrar.component.html',
  styleUrls: ['./entrar.component.css']
})
export class EntrarComponent implements OnInit {

  user = {
    login: '',
    senha: ''
  }
  loading = false;
  userId: CognitoUserInterface | undefined;
  authState: AuthState | undefined;
  controle = 0;
  email = '';
  code = '';
  senha = '';
  novaSenha = '';
  id = '';
  hide = true;
  spin = false;
  // recebe o id e o email para testar o IF
  cadastrado = {
    id: '',
    verificador: '',
    role: '',
    zoneinfo: ''
  }

  constructor(
    private servico: CadastroService,
    private servicoSenha: SenhaService,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private amplifyService: AmplifyService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      console.log(authState)
      this.cadastrado.id = authState.user.username;
      this.cadastrado.role = authState.user.attributes.profile;
      this.cadastrado.zoneinfo = authState.user.attributes.zoneinfo;
      if (authState.state == "signedIn") {
        if (this.cadastrado.role == 'paciente') {
          this.getPaciente();
        } else if (this.cadastrado.role == 'medico-geral') {
          this.getMedicoGeral();
        } else if (this.cadastrado.role == 'medico-especialista') {
          this.getMedicoEspecialista();
        } else if (this.cadastrado.role == 'administrator') {
          this.router.navigateByUrl('adm');
          //setTimeout(function () { location.reload(); }, 0)
        }
      }
    })
  }

  ngOnInit(): void {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.userId = authData as CognitoUserInterface;
      this.ref.detectChanges();
    })
  }

  getPaciente() {
    this.servico.getPaciente(this.cadastrado.id).subscribe((usuario: Paciente) => {
      this.construtorPaciente(usuario);
    })
  }
  construtorPaciente(usuario: any) {
    this.cadastrado.verificador = usuario.id;
    if (this.cadastrado.id == this.cadastrado.verificador) {
      this.router.navigateByUrl('principal');
      setTimeout(function () { location.reload(); }, 0)
    } else {
      this.router.navigateByUrl('edit-paciente');
      setTimeout(function () { location.reload(); }, 0)
    }
  }

  getMedicoGeral() {
    this.servico.getMedicoGeral(this.cadastrado.id).subscribe((usuario: Mg) => {
      this.construtorMedico(usuario);
    })
  }

  getMedicoEspecialista() {
    this.servico.getMedicoEspecialista(this.cadastrado.id).subscribe((usuario: Me) => {
      this.construtorMedico(usuario);
    })
  }

  construtorMedico(usuario: any) {
    this.cadastrado.verificador = usuario.id;
    if (this.cadastrado.zoneinfo == 'welcome') {
      this.router.navigateByUrl('sala-de-confirmacao');//a mudar
      setTimeout(function () { location.reload(); }, 0)
    } else if (this.cadastrado.zoneinfo == 'waiting') {
      this.router.navigateByUrl('espera');
      setTimeout(function () { location.reload(); }, 0)
    } else if (this.cadastrado.zoneinfo == 'denied' || this.cadastrado.zoneinfo == 'registered' || this.cadastrado.zoneinfo == null) {
      this.router.navigateByUrl('resgistro-medico');
      setTimeout(function () { location.reload(); }, 0)
    } else if (this.cadastrado.zoneinfo == 'accepted') {
      if (this.cadastrado.role == 'medico-especialista') {
        this.router.navigateByUrl('principalE');
      } else if (this.cadastrado.role == 'medico-geral') {
        this.router.navigateByUrl('principalG');
      }
    }
    else {
      console.log('error')
    }
  }

  construtorUsuario(usuario: any) {
    this.cadastrado.verificador = usuario.id;
    if (this.cadastrado.id == this.cadastrado.verificador && this.cadastrado.role == 'paciente') {
      this.router.navigateByUrl('principal');
      setTimeout(function () { location.reload(); }, 0)
    } else if (this.cadastrado.zoneinfo == 'waiting') {
      this.router.navigateByUrl('espera');
      //setTimeout(function () { location.reload(); }, 0)
    } else if (this.cadastrado.role == 'paciente') {
      this.router.navigateByUrl('edit-paciente');
      setTimeout(function () { location.reload(); }, 0)
    } else if ((this.cadastrado.role == 'medico-geral' || this.cadastrado.role == 'medico-especialista') && this.cadastrado.zoneinfo == 'welcome') {
      this.router.navigateByUrl('sala-de-confirmacao');//a mudar
      setTimeout(function () { location.reload(); }, 0)
    } else if ((this.cadastrado.role == 'medico-geral' || this.cadastrado.role == 'medico-especialista') && (this.cadastrado.zoneinfo == 'denied' || this.cadastrado.zoneinfo == 'registered' || this.cadastrado.zoneinfo == null)) {
      this.router.navigateByUrl('resgistro-medico');
      setTimeout(function () { location.reload(); }, 0)
    } else {
      console.log('error')
    }
  }

  async signIn() {
    this.loading = true;
    try {
      const autenticado = await Auth.signIn(this.user.login, this.user.senha);
      this.loading = false;
      if (autenticado.Session != null) {
        this.id = autenticado.username;
        this.controle = 3;
      }
      this.router.navigate(['principal'])
      setTimeout(function () { location.reload(); }, 0)
    } catch (error: any) {
      this.loading = false;
      console.log('error signing in', error);
      if (error.code == "UserNotConfirmedException") {
        this.openDialogEmail();
      } else if (error.code == "UserNotFoundException") {
        this.openDialogUser();
      } else {
        this.openDialogErro();
      }

    }
  }
  async novoCodigo() {
    this.loading = true;
    if (this.email != '') {
      Auth.forgotPassword(this.email)
        .then(data => console.log(data))
        .catch(err => console.log(err));
      this.loading = false;
      await this.openDialogForgot();
      await setTimeout(() => {
        this.controle = 2
      }, 1000)
    }

    this.loading = false;
  }
  novaSenhaCode() {
    this.loading = true;
    if (this.senha == this.novaSenha) {
      Auth.forgotPasswordSubmit(this.email, this.code, this.novaSenha)
        .then(data => console.log(data))
        .catch(err => console.log(err));
      this.loading = false;
      this.logar();
    }
  }
  toRegistra() {
    this.router.navigate(['registrar'])
  }
  esqueceuAsenha() {
    this.controle = 1;
  }
  logar() {
    this.controle = 0;
  }
  openDialogErro() {
    this.dialog.open(SenhaError);
  }
  openDialogEmail() {
    this.dialog.open(EmailMensagem);
  }
  openDialogUser() {
    this.dialog.open(UserMensagem);
  }
  openDialogForgot() {
    this.dialog.open(ForgotMensagem);
  }
  verificarSenha() {
    let senha = this.senha;
    let confirmacao = this.novaSenha;
    if (senha == confirmacao) {
      this.senhaADM();
    } else {
      this.openDialogErro();
    }
  }

  senhaADM() {
    this.loading = true;
    const data = {
      id: this.id,
      newPassword: this.novaSenha
    };
    this.servicoSenha.putPaciente(data)
      .subscribe(
        response => {
          console.log(response);
          this.loading = false;
          this.user.senha = '';
          this.controle = 0;
        },
        error => {
          console.log(error);
        }
      );
  }
  spninButton() {
    this.spin = true;
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'error.login.html',
})
export class SenhaError {
  constructor(
    public dialog: MatDialog
  ) { }
  fechar() {
    this.dialog.closeAll();
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'email.mensagem.html',
})
export class EmailMensagem {
  constructor(
    public dialog: MatDialog
  ) { }
  fechar() {
    this.dialog.closeAll();
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'user.mensagem.html',
})
export class UserMensagem {
  constructor(
    public dialog: MatDialog
  ) { }
  fechar() {
    this.dialog.closeAll();
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'forgot.mensagem.html',
})

export class ForgotMensagem {
  constructor(
    public dialog: MatDialog
  ) { }
  fechar() {
    this.dialog.closeAll();
  }
}
