import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { Paciente } from 'src/app/model/modelo-paciente';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { ChatGptService } from 'src/app/service/chatGpt/chat-gpt.service';
import { ImgService } from 'src/app/service/img/img.service';
//OK

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {

  user = {
    nombre: '',
    id: '',
  }
  alert = false;
  loading = true;
  paciente: Paciente | undefined;
  urlink: any = "../../../assets/avatar.png";

  consultaEmAberto: boolean;

  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private imgService: ImgService,
    private servico: CadastroService,
    private amplifyService: AmplifyService,
    private router: Router,
    private gpt: ChatGptService,
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn") {
        this.router.navigateByUrl("");
      }
      this.user.id = authState.user.username;// retornar o ID do usuario
    })
  }

  ngOnInit(): void {
    this.getPaciente();
    this.recuperaIMG();
    this.innerWidth = window.innerWidth;
    this.checarAtendimentoAberto();
  }

  getPaciente() {
    this.servico.getPaciente(this.user.id).subscribe((paciente: Paciente) => {
      this.paciente = paciente;
      this.construtorPaciente(paciente);
    })
  }
  construtorPaciente(paciente: any) {
    this.user.nombre = paciente.name;
    if (this.user.nombre == '') {
      this.router.navigateByUrl('edit-paciente')
    }
  }

  checarAtendimentoAberto() {
    this.gpt.getConsultaAberta(this.user.id).subscribe((usuario: any) => {
      //console.log(this.responseInfo)
      //console.log(usuario)
      if (usuario.done == false) {
        this.alert = true;
      }
      //console.log(this.answers_count)
    })
  }

  toOrientacao() {
    this.router.navigate(['orientacao'])
  }

  toOrientacaoProgramada() {
    this.router.navigate(['orientacao-programada'])
  }
  toHistorico() {
    this.router.navigate(['historico'])
  }

  toEditpaciente() {
    this.router.navigateByUrl('edit-paciente');
    setTimeout(function () { location.reload(); }, 0)
  }

  recuperaIMG() {
    this.imgService.getIMG(this.user.id).subscribe((img: any) => {
      if (!img.errorMessage) {
        this.urlink = `data:image/jpeg;base64,${img}`;
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  }

}
