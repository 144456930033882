<div style="text-align: center; margin-top: 3%;">
    <h1>Perfil</h1>
</div>

<!--Formulario de cadastro-->

<div class="formulario">
    <form>
        <img src="{{urlink}}" class="avatar">
        <button mat-icon-button (click)="fileInput.click()">
            <mat-icon>library_add</mat-icon>
            <input #fileInput type="file" style="display:none;" (change)="selectFile($event)" />
        </button>
        <br>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Nombre</mat-label>
            <input matInput type="text" id="nombre" name="nombre" [(ngModel)]="conta.nombre" required>
        </mat-form-field>
        <br>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Apellido</mat-label>
            <input matInput type="text" id="apellido" name="apellido" [(ngModel)]="conta.apellido" required>
        </mat-form-field>
        <br>

        <mat-form-field class="cadastro" appearance="fill">
            <mat-label>Aniversario</mat-label>
            <input matInput [matDatepicker]="picker" disabled>
            <mat-datepicker-toggle matSuffix [for]="picker">
            </mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>


        <!--
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Nacimiento</mat-label>
            <input matInput type="date" id="nacimiento" name="nacimiento" [(ngModel)]="conta.nacimiento" required>
        </mat-form-field>
        -->
        <br>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Direccion</mat-label>
            <input matInput type="text" id="direccion" name="direccion" [(ngModel)]="conta.direccion" required>
        </mat-form-field>
        <br>

        <button mat-raised-button color="primary" class="registerbtn" type="submit">OK</button>
    </form>
</div>

<div class="ajuste"></div>