import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-econverde',
  templateUrl: './econverde.component.html',
  styleUrls: ['./econverde.component.css']
})
export class EconverdeComponent implements OnInit {
  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
   
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

}
