<!---->
<div *ngIf="carregando == true">
    <mat-spinner></mat-spinner>
</div>
<!---->

<table style="  margin-right: auto;  margin-left: auto;" *ngIf="carregando == false">
    <tr>
        <th style=" text-align: center; width: 10%;">CODIGO</th>
        <th>APLICACION</th>
    </tr>
    <tr *ngIf="adm.botao1==true">
        <td style=" text-align: center;">1</td>
        <td><button type="submit" (click)="toRegistrar()" mat-button>REGISTRO DE USUARIOS</button></td>
    </tr>
    <tr *ngIf="adm.botao2==true">
        <td style=" text-align: center;">2</td>
        <!-- <td><button type="submit" (click)="toAprovar()" mat-button>2</button></td> -->
        <td><button type="submit" (click)="toAprovar()" mat-button>SOLICITUDE DE INGRESO</button></td>
    </tr>
    <tr *ngIf="adm.botao3==true">
        <td style=" text-align: center;">3</td>
        <td><button mat-button>GRAFICO DE USUARIO</button></td>
    </tr>
    <tr *ngIf="adm.botao4==true">
        <td style=" text-align: center;">4</td>
        <td><button mat-button>ADM</button></td>
    </tr>
    <tr *ngIf="adm.botao5==true">
        <td style=" text-align: center;">5</td>
        <td><button mat-button>ADM</button></td>
    </tr>
</table>

<div style="margin-bottom: 25%;"> </div>