<div>
  <!--NavBar-->
  <!--<app-nav-bar></app-nav-bar>-->
  <!--<div id="navBarDark"></div>-->
  <!--NavBar-->

  <!--Hero 01-->
  <div class="container-fluid bg-body py-5 my-5">
    <div class="container mt-5">
      <div class="row align-items-center">
        <div class="col-md-6 mx-auto">
          <div>
            <h1 class="fw-bold text-dark my-4"><span class="tediColor-one">TEDIMEDICINA</span>, Tu aliado
              inteligente en prevención de enfermedades.</h1>
            <p class="text-dark my-4">Descubre cómo la IA revoluciona la medicina preventiva para cuidar de
              tu salud de manera inteligente y personalizada.</p>
          </div>
          <div class="mt-3 mb-5">
             <!--Retirado parar deploy-->
            <!--
            <a href="" class="btn mx-1 text-light" style="background-color: #2e5e88;">Comenzar prueba ahora</a>
            <a href="" class="btn btn-outline-secondary my-1 mx-1">¡Conoce más!</a>-->
          </div>
        </div>
        <div class="col-md-5 mx-auto">
          <div class="d-flex justify-content-end">
            <img class="img-fluid shadow rounded-4"
              src="https://res.cloudinary.com/juandacloud/image/upload/v1688730212/afoco-projects/tedi/1_ursvrx.jpg"
              alt="">
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Video-->
  <div class="container-fluid py-5">
    <div class="col-md-10 mx-auto shadow rounded-4 bg-dark">
      <div class="row pt-5 px-3">
        <p class="text-center col-md-8 mx-auto fw-bolder text-light fs-3"><span
            class="tediColor-one">TEDIMEDICINA</span>, tu compañero inteligente en la prevención de
          enfermedades.</p>
      </div>
      <div class="text-center">
        <div class="">
          <iframe src="https://www.youtube.com/embed/c1lROmzleYU" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen style="height: 360px; width: 60%;" class="rounded-3 shadow col-md-8 my-5"></iframe>
        </div>
      </div>
    </div>
  </div>

  <!--Features-->
  <div class="container-fluid bg-body py-5 my-5">
    <div class="container">
      <div class="row mb-5">
        <h1 class="text-center col-md-8 mx-auto fw-bold">Servicios de Salud y Prevención de <span
            class="tediColor-one">TEDIMEDICINA</span></h1>
      </div>
      <div class="row">
        <!--Feature-->
        <div class="border rounded-3 shadow mx-auto text-center p-4 my-3 d-flex flex-column justify-content-center"
          style="width: 306px;">
          <div class="row">
            <i class="bi bi-postage fs-1 mb-3 tediColor-one"></i>
          </div>
          <div class="row">
            <p class="fw-bolder fs-5">IA para Prevención de Enfermedades</p>
          </div>
          <div class="row">
            <p>Utilizando tecnología de inteligencia artificial, TEDIMEDICINA ofrece soluciones innovadoras
              para prevenir enfermedades y mejorar la salud de las personas.</p>
          </div>
        </div>
        <!--Feature-->
        <div class="border rounded-3 shadow mx-auto text-center p-4 my-3 d-flex flex-column justify-content-center"
          style="width: 306px;">
          <div class="row">
            <i class="bi bi-clipboard2-data fs-1 mb-3 tediColor-one"></i>
          </div>
          <div class="row">
            <p class="fw-bolder fs-5">Análisis de Datos de Salud</p>
          </div>
          <div class="row">
            <p>Nuestro equipo de IA analiza datos de salud para identificar patrones, tendencias y factores
              de riesgo, permitiendo prevención temprana y personalizada.</p>
          </div>
        </div>
        <!--Feature-->
        <div class="border rounded-3 shadow mx-auto text-center p-4 my-3 d-flex flex-column justify-content-center"
          style="width: 306px;">
          <div class="row">
            <i class="bi bi-shield-lock fs-1 mb-3 tediColor-one"></i>
          </div>
          <div class="row">
            <p class="fw-bolder fs-5">Seguridad y Privacidad de Datos</p>
          </div>
          <div class="row">
            <p>En TEDIMEDICINA, protegemos los datos de nuestros usuarios con medidas rigurosas,
              garantizando confidencialidad y tranquilidad.</p>
          </div>
        </div>
        <!--Feature-->
        <div class="border rounded-3 shadow mx-auto text-center p-4 my-3 d-flex flex-column justify-content-center"
          style="width: 306px;">
          <div class="row">
            <i class="bi bi-person-workspace fs-1 mb-3 tediColor-one"></i>
          </div>
          <div class="row">
            <p class="fw-bolder fs-5">Colaboración con Profesionales de la Salud</p>
          </div>
          <div class="row">
            <p>Trabajamos con médicos, investigadores y profesionales de la salud para ofrecer soluciones
              relevantes, confiables y respaldadas por evidencia científica.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Hero 02-->
  <div class="container-fluid my-5" style="background: linear-gradient(to bottom, #2E507E, #307CA2);">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-4 mx-auto">
          <img class="img-fluid"
            src="https://res.cloudinary.com/juandacloud/image/upload/v1692313263/afoco-projects/tedi/womenIa_lop4a6.png"
            alt="">
        </div>
        <div class="col-md-7 my-5">
          <div class="row align-items-center bg-light mx-auto rounded-4 shadow">
            <div class="col text-center p-5">
              <i class="bi bi-heart-fill fs-1 tediColor-one"></i>
              <p class="text-dark fs-4 mt-3">Tu bienestar, nuestra prioridad.</p>
              <p class="text-dark text-opacity-50">En TEDIMEDICINA, nos preocupamos por tu salud. Nuestra
                aplicación utiliza algoritmos de inteligencia artificial para analizar tus datos y
                brindarte
                consejos personalizados sobre prevención de enfermedades. Empieza a tomar el control de
                tu
                bienestar hoy mismo.</p>
            </div>
            <div class="col text-center p-5">
              <i class="bi bi-lightning-fill fs-1 tediColor-one"></i>
              <p class="text-dark fs-4 mt-3">El futuro de la medicina preventiva.</p>
              <p class="text-dark text-opacity-50">TEDIMEDICINA combina la potencia de la inteligencia
                artificial con la experiencia médica para ofrecerte una herramienta revolucionaria en la
                prevención de enfermedades. Obtén recomendaciones basadas en tu perfil de salud y accede
                a
                información confiable para mejorar tu calidad de vida.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Testimonials-->
  <div class="container-fluid bg-body py-5 my-5">
    <div class="container">
      <div class="row mb-5">
        <h1 class="text-center col-md-8 mx-auto fw-bold">Testimonios</h1>
      </div>
      <div class="row">
        <div
          class="col-md-3 bg-secondary rounded-3 shadow bg-opacity-10 p-3 mx-auto d-flex flex-column justify-content-center mt-4">
          <div class="testimonial-item text-center">
            <div class="my-4">
              <img
                src="https://res.cloudinary.com/juandacloud/image/upload/v1688730211/afoco-projects/tedi/2_ifyfnh.jpg"
                class="img-fluid rounded-circle" alt="Foto paciente 1" style="box-shadow: 0 10px 25px 0 rgba(0,0,0,.3);"
                width="120">
            </div>
            <div class="">
              <h4>Laura Sánchez</h4>
              <p class="tediColor-one fw-bolder">Paciente</p>
              <p class="text-muted">"Gracias a TEDIMEDICINA, pude controlar mi diabetes y mejorar mi
                bienestar. Las recomendaciones personalizadas y los recordatorios me mantuvieron
                motivada. ¡Recomendaría TEDIMEDICINA a cualquiera que quiera tomar el control de su
                salud!"</p>
            </div>
          </div>
        </div>
        <div
          class="col-md-3 bg-secondary rounded-3 shadow bg-opacity-10 p-3 mx-auto d-flex flex-column justify-content-center mt-4">
          <div class="testimonial-item text-center">
            <div class="my-4">
              <img
                src="https://res.cloudinary.com/juandacloud/image/upload/v1688730211/afoco-projects/tedi/3_irnwdd.jpg"
                class="img-fluid rounded-circle" alt="Foto paciente 1" style="box-shadow: 0 10px 25px 0 rgba(0,0,0,.3);"
                width="120">
            </div>
            <div class="">
              <h4>Dr. Roberto Torres</h4>
              <p class="tediColor-one fw-bolder">Oncólogo</p>
              <p class="text-muted">"Como médico especialista en oncología, TEDIMEDICINA ha mejorado
                significativamente la forma en que brindo atención a mis pacientes. La aplicación
                utiliza algoritmos de IA para identificar posibles factores de riesgo y prevenir
                enfermedades relacionadas con el cáncer. Estoy impresionado con los resultados y la
                precisión de las recomendaciones que ofrece TEDIMEDICINA. ¡Es un recurso valioso para la
                medicina preventiva!"</p>
            </div>
          </div>
        </div>
        <div
          class="col-md-3 bg-secondary rounded-3 shadow bg-opacity-10 p-3 mx-auto d-flex flex-column justify-content-center mt-4">
          <div class="testimonial-item text-center">
            <div class="my-4">
              <img
                src="https://res.cloudinary.com/juandacloud/image/upload/v1688730211/afoco-projects/tedi/4_yjeheh.jpg"
                class="img-fluid rounded-circle" alt="Foto paciente 1" style="box-shadow: 0 10px 25px 0 rgba(0,0,0,.3);"
                width="120">
            </div>
            <div class="">
              <h4>Carlos Gómez</h4>
              <p class="tediColor-one fw-bolder">Paciente</p>
              <p class="text-muted">"TEDIMEDICINA ha sido una revelación para mí. Gracias a la
                inteligencia artificial, he podido recibir diagnósticos y recomendaciones médicas
                rápidas y precisas. La aplicación me ha ayudado a prevenir enfermedades y mantener un
                estilo de vida saludable. Estoy agradecido por la comodidad y tranquilidad que
                TEDIMEDICINA me brinda. ¡Lo recomendaría sin dudarlo!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Call to Action-->
  <div class="container-fluid py-5 px-5">
    <div class="col-md-10 mx-auto rounded-4 shadow bg-dark">
      <div class="row align-items-center p-5">
        <div class="col-md-5 mx-auto">
          <p class="fs-5 fw-bolder text-light my-4">¡Regístrate ahora y comienza a cuidar tu salud de forma
            inteligente con TEDIMEDICINA!</p>
          <!--Transformar os links em button para fazer o caminho entre componentes-->
           <!--Retirado parar deploy-->
          <!--
          <a href="" class="btn fw-bold my-3 text-light" style="background-color: #2e5e88;">¡Únete ahora!</a>-->
        </div>
        <div class="col-md-5 text-light text-opacity-75 mx-auto py-4">
          <p><i class="bi bi-check-lg"></i> Recibe recomendaciones personalizadas para prevenir enfermedades
          </p>
          <p><i class="bi bi-check-lg"></i> Realiza un seguimiento de tu progreso y mejora tu calidad de vida
          </p>
          <p><i class="bi bi-check-lg"></i> Conéctate con expertos médicos y comunidad de usuarios</p>
        </div>
      </div>
    </div>
  </div>

  <!--Footer-->
  <app-footer-light></app-footer-light>
  <!--<div id="footerLight"></div>-->

  <!--Main Js-->
  <script type="module" src="./js/main.js"></script>

  <!--js Boostrap-->
  <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.7/dist/umd/popper.min.js"
    integrity="sha384-zYPOMqeu1DAVkHiLqWBUTcbYfZ8osu1Nd6Z89ify25QV9guujx43ITvfi12/QExE"
    crossorigin="anonymous"></script>
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.min.js"
    integrity="sha384-Y4oOpwW3duJdCWv5ly8SCFYWqFDsfob/3GkgExXKV4idmbt98QcxXYs9UoXAB7BZ"
    crossorigin="anonymous"></script>
</div>
