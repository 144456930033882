import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';



@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.css']
})
export class RegistrarComponent implements OnInit {

  public innerWidth: any;
  registro = false;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) {

    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state == "signedIn") {
        this.registro = true;
      }
    })
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  toRegisterP() {
    this.router.navigate(['cadastro-p'])
  }

  toRegisterMG() {
    this.router.navigate(['cadastro-mg'])
  }

  toRegisterME() {
    this.router.navigate(['cadastro-me'])
  }

  toArtista(){
    this.router.navigate([''])
  }

}
