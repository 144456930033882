<table class="cabecalho">
    <h1>{{componente}}</h1>
</table>
<h2 class="borda">REGISTRO DE USUARIO</h2>
<h2 class="borda2">ACTUALIZACION DE INFORMACIONES</h2>
<h2 class="borda3">ACCESO DEL USUARIO</h2>


<div id="div1">
    <form class="form1">
        <mat-form-field appearance="fill" class="form1">
            <mat-label>Nome</mat-label>
            <input matInput type="text" id="name" name="name" [(ngModel)]="user.name" required>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" class="form1">
            <mat-label>Email</mat-label>
            <input matInput type="text" id="email" name="email" [(ngModel)]="user.email" required>
        </mat-form-field>
        <br>
        <button mat-raised-button color="primary" class="btn" type="submit"
        [class.spinner]="loading2" [disabled]="loading2" (click)="registraUsuario()">Registrar</button>
    </form>
</div>


<div class="div2">
    <form class="form1">
        <mat-form-field appearance="fill" class="form1">
            <mat-label>id</mat-label>
            <input matInput type="text" id="name" name="name" [(ngModel)]="id" required>
        </mat-form-field>
        <br>
        <button mat-raised-button color="primary" class="btnBuscar" type="submit" [class.spinner]="loading1" [disabled]="loading1"
            (click)='getUsuarioID()' >Buscar</button>
    </form>
</div>

<div class="div2">
    <form class="form1">
        <mat-form-field appearance="fill" class="form1">
            <mat-label>Nombre</mat-label>
            <input matInput type="text" id="name" name="name" [(ngModel)]="medico.name" required>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" class="form1">
            <mat-label>Email</mat-label>
            <input matInput type="text" id="email" name="email" [(ngModel)]="medico.email" required>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" class="form1">
            <mat-label>Departamento</mat-label>
            <input matInput type="text" id="department" name="department" [(ngModel)]="medico.department">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" class="form1">
            <mat-label>Papel</mat-label>
            <input matInput type="text" id="role" name="role" [(ngModel)]="medico.role">
        </mat-form-field>
        <br>
        <button mat-raised-button color="primary" class="btnAtualizar" type="submit" [class.spinner]="loading" [disabled]="loading"
            (click)='registraUsuarioFinal()'>Atualizar</button>
    </form>
</div>

<table class="borda4">
    <tr style="background-color: #12192b; color: #ffff">
        <th></th>
        <th>#</th>
        <th>APLICACION</th>
    <tr style="background-color:gray;">
        <td>
            <mat-checkbox color='primary' [(ngModel)]="medico.button_01" [disabled]="id==null || id=='' "></mat-checkbox>
        </td>
        <td><b>1</b></td>
        <td>REGISTRO DE USUARIO</td>
    </tr>
    <tr style="background-color: lightgray;">
        <td>
            <mat-checkbox color='primary' [(ngModel)]="medico.button_02" [disabled]="id==null || id=='' "></mat-checkbox>
        </td>
        <td><b>2</b></td>
        <td>SOLICITUDE DE INGRESO</td>
    </tr>
    <tr style="background-color:gray;">
        <td>
            <mat-checkbox color='primary' [(ngModel)]="medico.button_03" disabled></mat-checkbox>
        </td>
        <td><b>3</b></td>
        <td>--</td>
    </tr>
    <tr style="background-color: lightgray;">
        <td>
            <mat-checkbox color='primary' [(ngModel)]="medico.button_04" disabled></mat-checkbox>
        </td>
        <td><b>4</b></td>
        <td>--</td>
    </tr>
    <tr style="background-color:gray;">
        <td>
            <mat-checkbox color='primary' [(ngModel)]="medico.button_05" disabled></mat-checkbox>
        </td>
        <td><b>5</b></td>
        <td>--</td>
    </tr>
</table>