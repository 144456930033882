import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreCadastroService } from 'src/app/service/preCadastro/pre-cadastro.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-pre-cadastro',
  templateUrl: './pre-cadastro.component.html',
  styleUrls: ['./pre-cadastro.component.css']
})
export class PreCadastroComponent implements OnInit {
  
  valor: number = 0
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  matcher = new MyErrorStateMatcher();

  paciente = {
    nombre: '',
    email: '',
    tipodocumento: '',
    documento: '',
    genero: '',
    telefone: '',
    texto: ''
  }

  artista = {
    nombre: '',
    email: '',
    tipodocumento: '',
    documento: '',
    genero: '',
    telefone: '',
    texto: ''
  }

  geral = {
    nombre: '',
    email: '',
    rethus: '',
    genero: '',
    telefone: '',
    texto: ''
  }

  especialista = {
    nombre: '',
    email: '',
    especialidad: '',
    subEspecialidad: '',
    rethus: '',
    genero: '',
    telefone: '',
    texto: ''
  }

  constructor(
    private service : PreCadastroService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  cadastrar() {
    let escolha: string = '';
    let data: any;
    if (this.valor == 1){
      escolha = "patient";
      data = {
        email: this.paciente.email,
        name: this.paciente.nombre,
        document_type: this.paciente.tipodocumento,
        document_number: this.paciente.documento,
        gender: this.paciente.genero,
        whatsapp: this.paciente.telefone,
        comment: this.paciente.texto
      };
    }else if (this.valor == 2){
      escolha = "artist";
      data = {
        email: this.artista.email,
        name: this.artista.nombre,
        document_type: this.artista.tipodocumento,
        document_number: this.artista.documento,
        gender: this.artista.genero,
        whatsapp: this.artista.telefone,
        comment: this.artista.texto
      };
    }else if (this.valor == 3){
      escolha = "general";
      data = {
        email: this.geral.email,
        name: this.geral.nombre,
        rethus: this.geral.rethus,
        gender: this.geral.genero,
        whatsapp: this.geral.telefone,
        comment: this.geral.texto
      };
    }else if (this.valor == 4){
      escolha = "specialist";
      data = {
        email: this.especialista.email,
        name: this.especialista.nombre,
        rethus: this.especialista.rethus,
        gender: this.especialista.genero,
        whatsapp: this.especialista.telefone,
        interest_group: this.especialista.especialidad,
        interest_specialty: this.especialista.subEspecialidad,
        comment: this.especialista.texto
      };
    }
    this.service.putPaciente(data, escolha)
      .subscribe(
        response => {
          this.dialog.open(Confirmacao);
          console.log(response)
        }
      );
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'confirmacao.html',
})
export class Confirmacao {
  constructor(
    public dialog: MatDialog,
    private router: Router
  ) { }
  confirmar() {
    this.router.navigate(['']);
    setTimeout(function () { location.reload(); }, 0)
  }
}