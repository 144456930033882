import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';


export interface PeriodicElement {
  imagem: string;
  data: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
];


@Component({
  selector: 'app-orientaciones-programadas',
  templateUrl: './orientaciones-programadas.component.html',
  styleUrls: ['./orientaciones-programadas.component.css']
})
export class OrientacionesProgramadasComponent implements AfterViewInit {

  displayedColumns: string[] = ['imagem', 'data', 'star'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);


  //@ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) { 
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn") {
        this.router.navigateByUrl("");
      }
    })
  }
  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
  }

  toPrincipal(){
    this.router.navigate(['principal']);
    setTimeout(function(){ location.reload(); }, 0)
  }
  toChat(){
    this.router.navigate(['chat']);
  }
  
}
