import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-solicitar-orientacion',
  templateUrl: './solicitar-orientacion.component.html',
  styleUrls: ['./solicitar-orientacion.component.css']
})
export class SolicitarOrientacionComponent implements OnInit {
  
  /*MAP*/
  title = 'My first AGM project';
  lat = 4.61;
  lng = -74.082;
  /*********/
  posicao = "Orientación";
  data: any;
  cadastro = {
    data : '',
    horarioInicial : '',
    horarioFinal: '',
    endereco : ''
  }
  nome = '';
  valor_pagar= '';
  urlinkMap = '../../../assets/imgemMap.jpg';
  avatar: string = "../../../assets/avatar.png";

  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) { 
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      this.nome = authState.user.attributes.email
      if (authState.state != "signedIn") {
        this.router.navigateByUrl("");
      }
    })
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  ngOnInit(): void {
  }

  toProgramado(){
    this.router.navigate(['programado']);
  }

  moveToSelectedTab(tabName: string) {
    for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
      this.posicao = tabName;
    if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
       {
          (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
          
       }
     }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.data = event.value;
  }
  
  teste(){
    this.posicao = "Confirmacion"
  }
}
