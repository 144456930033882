<br>
<div style="text-align: center; margin-top: 60px; margin-bottom: 15px;">
    <h1>REGISTRO PREVIO</h1>
</div>
<br>
<div>
    <div class="container">
        <mat-radio-group [(ngModel)]="valor">
            <mat-radio-button value="1">Paciente</mat-radio-button>
            <mat-radio-button value="2">Artista</mat-radio-button>
            <mat-radio-button value="3">Médico General</mat-radio-button>
            <mat-radio-button value="4">Médico Especialista</mat-radio-button>
        </mat-radio-group>
    </div>
    <br>
    <div *ngIf="valor==0" class="ajuste">
        
    </div>
    <form class="container2" *ngIf="valor==1" #form="ngForm">
        <h2>Paciente</h2>
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Nombre Completo</mat-label>
            <input matInput type="text" id="nombreP" name="nombreP" [(ngModel)]="paciente.nombre" required>
        </mat-form-field>

        <mat-form-field class="cadastro" appearance="fill">
            <mat-label>Email</mat-label>
            <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher" [(ngModel)]="paciente.email"
                placeholder="Ex. pat@example.com " required>
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Tipo documento</mat-label>
            <mat-select matInput id="documentoTP" name="documentoTP" [(ngModel)]="paciente.tipodocumento" required>
                <mat-option value="CC">CC</mat-option>
                <mat-option value="cedula estrangeria">Cédula Estrangeria</mat-option>
                <mat-option value="Passaporte">Passaporte</mat-option>
                <mat-option value="registro civil">Registro Civil</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Número del documento</mat-label>
            <input matInput type="text" id="documentoP" name="documentoP" [(ngModel)]="paciente.documento" required>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Género</mat-label>
            <mat-select matInput id="generoP" name="generoP" [(ngModel)]="paciente.genero" required>
                <mat-option value="Hombre">Hombre</mat-option>
                <mat-option value="Mujer">Mujer</mat-option>
                <mat-option value="Otro">Otro</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Whatsapp</mat-label>
            <input matInput type="text" id="telefoneP" name="telefoneP" mask="000 0000000" [(ngModel)]="paciente.telefone" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="cadastro">
            <mat-label>¿Qué esperas de uma orientacion médica?</mat-label>
            <textarea matInput style="resize: none;" rows="1" id="textoP" name="textoP" [(ngModel)]="paciente.texto"
                oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
        </mat-form-field>
        
        <button mat-raised-button color="primary" (click)="cadastrar()"  [disabled]="!form.form.valid">Enviar</button>
        <br>
    </form>

    <form class="container2" *ngIf="valor==2" #form="ngForm">
        <h2>Artista</h2>
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Nombre Completo</mat-label>
            <input matInput type="text" id="nombreA" name="nombreA" [(ngModel)]="artista.nombre" required>
        </mat-form-field>

        <mat-form-field class="cadastro" appearance="fill">
            <mat-label>Email</mat-label>
            <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher" [(ngModel)]="artista.email"
                placeholder="Ex. pat@example.com" required>
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Tipo documento</mat-label>
            <mat-select matInput id="documentoTA" name="documentoTA" [(ngModel)]="artista.tipodocumento" required>
                <mat-option value="CC">CC</mat-option>
                <mat-option value="cedula estrangeria">Cédula Estrangeria</mat-option>
                <mat-option value="Passaporte">Passaporte</mat-option>
                <mat-option value="registro civil">Registro Civil</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Número del documento</mat-label>
            <input matInput type="text" id="documentoA" name="documentoA" [(ngModel)]="artista.documento" required>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Género</mat-label>
            <mat-select matInput id="generoA" name="generoA" [(ngModel)]="artista.genero" required>
                <mat-option value="Hombre">Hombre</mat-option>
                <mat-option value="Mujer">Mujer</mat-option>
                <mat-option value="Otro">Otro</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Whatsapp</mat-label>
            <input matInput type="text" id="telefoneA" name="telefoneA" mask="000 0000000" [(ngModel)]="artista.telefone" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="cadastro">
            <mat-label>¿Cuál es tu profesión?</mat-label>
            <textarea matInput style="resize: none;" rows="1"  id="textoA" name="textoA" [(ngModel)]="artista.texto"
                oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="cadastrar()" [disabled]="!form.form.valid">Enviar</button>
        <br>
    </form>

    <form class="container2" *ngIf="valor==3" #form="ngForm">
        <h2>Médico General</h2>
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Nombre Completo</mat-label>
            <input matInput type="text" id="nombreG" name="nombreG" [(ngModel)]="geral.nombre" required>
        </mat-form-field>

        <mat-form-field class="cadastro" appearance="fill">
            <mat-label>Email</mat-label>
            <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher" [(ngModel)]="geral.email"
                placeholder="Ex. pat@example.com" required>
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>ReTHUS</mat-label>
            <input matInput type="text" id="rethusG" name="rethusG" [(ngModel)]="geral.rethus" required>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Género</mat-label>
            <mat-select matInput id="generoG" name="generoG" [(ngModel)]="geral.genero" required>
                <mat-option value="Hombre">Hombre</mat-option>
                <mat-option value="Mujer">Mujer</mat-option>
                <mat-option value="Otro">Otro</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Whatsapp</mat-label>
            <input matInput type="text" id="telefoneG" name="telefoneG" mask="000 0000000" [(ngModel)]="geral.telefone" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="cadastro">
            <mat-label>¿Qué oportunidad esperas de trabajar em uma plataforma médica?</mat-label>
            <textarea matInput style="resize: none;" rows="1"  id="textoG" name="textoG" [(ngModel)]="geral.texto"
                oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="cadastrar()" [disabled]="!form.form.valid">Enviar</button>
        <br>
    </form>

    <form class="container2" *ngIf="valor==4" #form="ngForm">
        <h2>Médico Especialista</h2>
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Nombre Completo</mat-label>
            <input matInput type="text" id="nombreE" name="nombreE" [(ngModel)]="especialista.nombre" required>
        </mat-form-field>

        <mat-form-field class="cadastro" appearance="fill">
            <mat-label>Email</mat-label>
            <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher" [(ngModel)]="especialista.email"
                placeholder="Ex. pat@example.com" required>
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Especialidad</mat-label>
            <input matInput type="text" id="especialidadE" name="especialidadE" [(ngModel)]="especialista.especialidad" required>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Sub-especialidad</mat-label>
            <input matInput type="text" id="subEspecialidadE" name="subEspecialidadE" [(ngModel)]="especialista.subEspecialidad" required>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>ReTHUS</mat-label>
            <input matInput type="text" id="rethusE" name="rethusE" [(ngModel)]="especialista.rethus" required>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Género</mat-label>
            <mat-select matInput id="generoE" name="generoE" [(ngModel)]="especialista.genero" required>
                <mat-option value="Hombre">Hombre</mat-option>
                <mat-option value="Mujer">Mujer</mat-option>
                <mat-option value="Otro">Otro</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Whatsapp</mat-label>
            <input matInput type="text" id="telefoneE" name="telefoneE" mask="000 0000000" [(ngModel)]="especialista.telefone" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="cadastro">
            <mat-label>¿Qué oportunidad esperas de trabajar em uma plataforma médica?</mat-label>
            <textarea matInput style="resize: none;" rows="1" id="textoE" name="textoE" [(ngModel)]="especialista.texto"
                oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="cadastrar()" [disabled]="!form.form.valid">Enviar</button>
        <br>
    </form>
    
</div>
