import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';

@Component({
  selector: 'app-programado',
  templateUrl: './programado.component.html',
  styleUrls: ['./programado.component.css']
})
export class ProgramadoComponent implements OnInit {

  programacao = {
    local: 'German',
    data: '24/03/2020',
    horario: '6 pm'
  }
  nome: string = 'Fulano';
  sobrenome: string = 'Tal';
  avatar: string = "../../../assets/avatar.png";
  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) { 
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn") {
        this.router.navigateByUrl("");
      }
    })
  }
  ngOnInit(): void {
  }

  toPrincipal(){
    this.router.navigate(['principal']);
    setTimeout(function () { location.reload(); }, 0)
  } 

}
