<mat-grid-list [gutterSize]="'30px'" 
               style="padding-top: 0px; padding-bottom: 100px; background-color:rgb(240, 240, 240);" 
               cols="1" 
               rowHeight="50px" 
               *ngIf="innerWidth>600" >

    <div style="margin-top: 60px;"></div>
    
    <mat-grid-tile [rowspan]="5">
        <div class="img" style="width: 100%;">
            <img src="../../../assets/backgrounds/mountain.jpg" style="width: 100%; padding-top: 00px;">
            <p class="titulo-img">Quiénes somos</p>
        </div>
    </mat-grid-tile>
    
    <mat-grid-tile [rowspan]="3">
        <p style="text-align: center;" class="txt-big">No es lo que somos<br> sino lo que <b>hacemos</b> lo que nos define</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="5">
        <img src="../../../assets/backgrounds/roadchild.webp">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="3">
        <p style="text-align: center;" class="txt-big">Conectamos <b>corazones</b> en un solo<br>
             <b>latido</b> para darle valor a las personas
        </p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="5">
        <p style="padding-left:0%; text-align: left;" class="txt-small">En TEDI no hacemos <b>medicina</b> de la forma<br>
             tradicional, hemos redefinido el concepto y lo<br> elevamos a <b>arte</b>. <br><br>
             Pensar en los demás siempre ha sido nuestra<br>
             <b>esencia</b> por eso con TEDI nuestros pacientes,<br>
             médicos generales y especialistas podrán<br>
             interconectarse mirándose a los <b>ojos</b> y sintiéndose<br> en familia.
        </p>
        <img src="../../../assets/backgrounds/maps.webp" style="padding-left:5%; padding-bottom:300px;">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="10">
        <img src="../../../assets/backgrounds/bussola.webp" style="padding-left:0%; padding-bottom:000px;">
        <p style="padding-left:10%; text-align: left;" class="txt-small">
            Impactamos positivamente la <b>vida</b> de las personas<br>
            que usan nuestra plataforma de <b>orientación</b> médica,<br>
            ofreciendo alternativas de empoderamiento a las<br>
            labores que realizan nuestros usuarios.
            <br><br>
            Sabemos que <b>somos</b> diferentes y buscamos ser<br>
            escuchados. No somos un servicio impersonal,<br>
            somos impersonales con lo que no sea una<br>
            atención adecuada en <b>salud</b>.
            <br><br>            
            Amamos lo que hacemos, por eso nuestro<br>
            <b>contenido audiovisual</b> busca generar esperanza y<br>
            empatía en las personas.
            <br><br>          
            Somos coparticipes en la creación de <b>políticas</b><br>
            <b>públicas</b> que beneficien a las personas que no<br>
            pueden tener acceso a salud.
            <br><br>
            Somos <b>aliados</b> estratégicos del sistema general de<br>
            salud en la búsqueda del mejoramiento de los<br>
            indicadores sociales en salud del país, brindando<br>
            oportunidades a las personas para que se<br>
            empoderen de su estado de salud.
        </p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="5" >
        <img src="../../../assets/backgrounds/forest.webp" style="padding-left:0%; padding-bottom:000px;">
    </mat-grid-tile>

    </mat-grid-list>

<!-- RowHeight arruma cada grid -->
<mat-grid-list cols="1" rowHeight="25px"*ngIf="innerWidth<600">
    <mat-grid-tile [rowspan]="6">
        <div class="img" style=" width: 200%;">
            <img src="../../../assets/backgrounds/mountain.jpg" style="height:120%; width: 150%; padding-top: 00px;">
            <p class="titulo-img" style="font-size:16px; top:110px;">Quiénes somos</p>
        </div>
    </mat-grid-tile>
    
    <mat-grid-tile [rowspan]="3">
        <p class="txt-big" style="text-align: center; font-size:20px;" >No es lo que somos<br> sino lo que <b>hacemos</b> lo que nos define</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="5">
        <img src="../../../assets/backgrounds/roadchild.webp" style="width: 100%; padding-top: 00px;">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="3">
        <p style="text-align: center; font-size:20px;" class="txt-big">Conectamos <b>corazones</b> en un solo<br>
             <b>latido</b> para darle valor a las personas
        </p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="8">
        <img src="../../../assets/backgrounds/maps.webp" style="padding-left:5%; padding-bottom:300px;">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="8">
        <p style="padding-left:0%; text-align: left; font-size:14px;" class="txt-small">En TEDI no hacemos <b>medicina</b> de la forma<br>
             tradicional, hemos redefinido el concepto y lo<br> elevamos a <b>arte</b>. <br><br>
             Pensar en los demás siempre ha sido nuestra<br>
             <b>esencia</b> por eso con TEDI nuestros pacientes,<br>
             médicos generales y especialistas podrán<br>
             interconectarse mirándose a los <b>ojos</b> y sintiéndose<br> en familia.
        </p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="8">
        <img src="../../../assets/backgrounds/bussola.webp" style="padding-left:0%; padding-bottom:300px;">
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="20">
        <p style="padding-left:10%; text-align: left; font-size:14px;" class="txt-small">
            Impactamos positivamente la <b>vida</b> de las personas<br>
            que usan nuestra plataforma de <b>orientación</b> médica,<br>
            ofreciendo alternativas de empoderamiento a las<br>
            labores que realizan nuestros usuarios.
            <br><br>
            Sabemos que <b>somos</b> diferentes y buscamos ser<br>
            escuchados. No somos un servicio impersonal,<br>
            somos impersonales con lo que no sea una<br>
            atención adecuada en <b>salud</b>.
            <br><br>            
            Amamos lo que hacemos, por eso nuestro<br>
            <b>contenido audiovisual</b> busca generar esperanza y<br>
            empatía en las personas.
            <br><br>          
            Somos coparticipes en la creación de <b>políticas</b><br>
            <b>públicas</b> que beneficien a las personas que no<br>
            pueden tener acceso a salud.
            <br><br>
            Somos <b>aliados</b> estratégicos del sistema general de<br>
            salud en la búsqueda del mejoramiento de los<br>
            indicadores sociales en salud del país, brindando<br>
            oportunidades a las personas para que se<br>
            empoderen de su estado de salud.
        </p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="6" >
        <img src="../../../assets/backgrounds/forest.webp" style="padding-left:50px; padding-bottom:000px;">
    </mat-grid-tile>

</mat-grid-list>
