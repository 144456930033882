<div class="container-fluid bg-body py-5 my-5">
  <div class="tabScroll">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!-- Position Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          {{element.datetime[8]+element.datetime[9]+'/'+element.datetime[5]+element.datetime[6]+'/'+element.datetime[0]+element.datetime[1]+element.datetime[2]+element.datetime[3]}}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="symptom">
        <th mat-header-cell *matHeaderCellDef> Symptom </th>
        <td mat-cell *matCellDef="let element" (click)="visualizar_status(element)"> {{element.user_symptom}} </td>
      </ng-container>

      <!--<ng-container matColumnDef="status">
        <th mat-header-cell style="width: 20%;" *matHeaderCellDef>
          <p class="table-title">STATUS</p>
        </th>
        <td mat-cell *matCellDef="let element"> <button (click)="visualizar_status(element)" type="button"
            class="btn btn-light" data-bs-toggle="modal" data-bs-target="#Modal"> Historial </button></td>
      </ng-container>-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row (click)="visualizar_status(row)" type="button" data-bs-toggle="modal" data-bs-target="#Modal"
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>


  <!-- Historico -->
  <div class="modal fade" id="Modal" tabindex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content tabScrollHistorico">
        <div class="modal-header">
          <p><b>Data:</b>
            {{responseInfo.datetime[8]+responseInfo.datetime[9]+'/'+responseInfo.datetime[5]+responseInfo.datetime[6]+'/'+responseInfo.datetime[0]+responseInfo.datetime[1]+responseInfo.datetime[2]+responseInfo.datetime[3]}}
          </p>
          <p><b>Symptom:</b> {{responseInfo.user_symptom}}</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <br>
          <h1>Causa</h1>
          <p>{{responseInfo.AI_cause}}</p>

          <br>
          <h1>Tratamiento no farmacologico</h1>
          <p>{{responseInfo.AI_treatment}}</p>

          <br>
          <h1>Tratamiento farmacologico</h1>
          <p>{{responseInfo.AI_treatment_pharmacological}}</p>

          <br>
          <h1>Guia Medicamentosa</h1>
          <p>{{responseInfo.AI_leaflet}}</p>

          <br>
          <h1>Recomendaciones natural</h1>
          <p>{{responseInfo.AI_natural_medicine}}</p>
        </div>
        <div class="footerBtn">
          <button type="button" class="btn btn-primary btnF" data-bs-dismiss="modal" aria-label="Close"
            style="margin-right: 100px;">Fechar</button>
          <button type="button" class="btn btn-primary btnF" data-bs-toggle="modal" data-bs-target="#ModalChat"
            style="margin-left: 100px;">
            Chat
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--Chat-->
  <div class="modal fade" id="ModalChat" tabindex="1" aria-labelledby="ModalChatLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content tabScrollHistorico">
        <div class="modal-header">
          <h1 style="margin-left:46%; ">Chat</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="card-body msg_card_body">

            <div style="text-align: center;"
              *ngIf="responseInfo.user_01==undefined && responseInfo.user_02==undefined && responseInfo.user_03==undefined && responseInfo.user_04==undefined && responseInfo.user_05==undefined">
              <h2>Sin registro de consulta de chat</h2>
            </div>

            <div class="d-flex justify-content-end mb-4" *ngIf="responseInfo.user_01!=undefined">
              <div class="msg_cotainer">
                <h3>{{responseInfo.user_01}}</h3>
              </div>
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{urlink}}" class="rounded-circle user_img_msg">
              </div>
            </div>

            <div class="d-flex justify-content-start mb-4" *ngIf="responseInfo.ai_01!=undefined">
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{iaImg}}" class="rounded-circle user_img_msg">
              </div>
              <div class="msg_cotainer_send">
                <h3>{{responseInfo.ai_01}}</h3>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-4" *ngIf="responseInfo.user_02!=undefined">
              <div class="msg_cotainer">
                <h3>{{responseInfo.user_02}}</h3>
              </div>
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{urlink}}" class="rounded-circle user_img_msg">
              </div>
            </div>

            <div class="d-flex justify-content-start mb-4" *ngIf="responseInfo.ai_02!=undefined">
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{iaImg}}" class="rounded-circle user_img_msg">
              </div>
              <div class="msg_cotainer_send">
                <h3>{{responseInfo.ai_02}}</h3>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-4" *ngIf="responseInfo.user_03!=undefined">
              <div class="msg_cotainer">
                <h3>{{responseInfo.user_03}}</h3>
              </div>
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{urlink}}" class="rounded-circle user_img_msg">
              </div>
            </div>

            <div class="d-flex justify-content-start mb-4" *ngIf="responseInfo.ai_03!=undefined">
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{iaImg}}" class="rounded-circle user_img_msg">
              </div>
              <div class="msg_cotainer_send">
                <h3>{{responseInfo.ai_03}}</h3>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-4" *ngIf="responseInfo.user_04!=undefined">
              <div class="msg_cotainer">
                <h3>{{responseInfo.user_04}}</h3>
              </div>
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{urlink}}" class="rounded-circle user_img_msg">
              </div>
            </div>

            <div class="d-flex justify-content-start mb-4" *ngIf="responseInfo.ai_04!=undefined">
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{iaImg}}" class="rounded-circle user_img_msg">
              </div>
              <div class="msg_cotainer_send">
                <h3>{{responseInfo.ai_04}}</h3>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-4" *ngIf="responseInfo.user_05!=undefined">
              <div class="msg_cotainer">
                <h3>{{responseInfo.user_05}}</h3>
              </div>
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{urlink}}" class="rounded-circle user_img_msg">
              </div>
            </div>

            <div class="d-flex justify-content-start mb-4" *ngIf="responseInfo.ai_05!=undefined">
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{iaImg}}" class="rounded-circle user_img_msg">
              </div>
              <div class="msg_cotainer_send">
                <h3>{{responseInfo.ai_05}}</h3>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary btnF" data-bs-dismiss="modal"
                aria-label="Close">Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
