import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';


export interface PeriodicElement {
  imagem: string;
  data: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { imagem: '', data: '04/11/2020' },
  { imagem: '', data: '04/11/2020' },
  { imagem: '', data: '04/11/2020' },
  { imagem: '', data: '04/11/2020' },
  { imagem: '', data: '04/11/2020' },
  { imagem: '', data: '04/11/2020' },
  { imagem: '', data: '04/11/2020' },
  { imagem: '', data: '04/11/2020' },
  { imagem: '', data: '04/11/2020' },
];


@Component({
  selector: 'app-historial-de-orientaciones',
  templateUrl: './historial-de-orientaciones.component.html',
  styleUrls: ['./historial-de-orientaciones.component.css']
})
export class HistorialDeOrientacionesComponent implements OnInit {

  displayedColumns: string[] = ['imagem', 'data', 'star'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  medico = '';

  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      this.medico = authState.user.attributes.profile;
      if (authState.state != "signedIn" && (authState.user.attributes.profile != "medico-geral" || authState.user.attributes.profile != "medico-especialista")) {
        this.router.navigateByUrl("");
      }
    })
  }

  ngOnInit(): void {
    if (this.medico == '') {
      location.reload();
    }
  }

  toPrincipal() {
    if (this.medico == "medico-geral") {
      this.router.navigate(['principalG']);
    } else if (this.medico == "medico-especialista") {
      this.router.navigate(['principalE']);
    } else {
      this.router.navigateByUrl("");
    }
  }
  toEvalucion() {
    this.router.navigate(['evalucion']);
  }
}
