import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  //escondido = true;//Esconder os botoes na producao
  registro = false;
  dados: any[] = [];
  loading = true;

  constructor(
    private amplifyService: AmplifyService,
    http: HttpClient,
    private router: Router) {
    // http
    //     .post<any[]>
    //     (
    //     'https://0sldkmekk7.execute-api.sa-east-1.amazonaws.com/prod/',
    //     {"correo": "derykro@gmail.com"}
    //     )
    //     .subscribe(
    //       dados => this.dados = dados,
    //       err => console.log(err.message)
    //     );

    //     console.log(this.dados)

    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state == "signedIn") {
        this.registro = true;
        this.loading = false;
      } else{
        this.loading =false;
      }
    })

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    http.get('https://zjxaaamosi.execute-api.sa-east-1.amazonaws.com/costumer/?ClienteID=1')
      .subscribe(
        dados => dados = dados,
        err => console.log(err.message)
      );

    console.log(this.dados)

  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
    this.innerWidth = window.innerWidth;
  }

  /*toRegister() {
    this.router.navigate(['registrar'])
  }*/

}
