import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify, { Auth } from 'aws-amplify';
import amplify from './aws-exports';
Amplify.configure(amplify);
Auth.configure(amplify);

if (environment.production) {
  enableProdMode();
  if (window){
    window.console.log = function(){};// Remove todos os consoles do projeto na hora do Build
  }
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
