import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatGptService {

  private SOLICITAR_ORIENTACAO = 'https://l3erw6lmdg.execute-api.us-east-2.amazonaws.com/prod';
  private SOLICITAR_CHAT = 'https://7awrhul6uk.execute-api.us-east-2.amazonaws.com/prod';
  private FINALIZAR = 'https://ymyufrsftb.execute-api.us-east-2.amazonaws.com/prod';
  private HISTORICO = 'https://l3erw6lmdg.execute-api.us-east-2.amazonaws.com/prod';
  private CONSULTA_EM_ABERTO = 'https://ymyufrsftb.execute-api.us-east-2.amazonaws.com/prod'

  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }

  putGPT(cadastro: any){
    return this.httpClient.put(`${this.SOLICITAR_ORIENTACAO}`, cadastro)
    .pipe(
      retry(2),
      catchError(this.handleError))
  }

  putChat(cadastro: any){
    return this.httpClient.put(`${this.SOLICITAR_CHAT}`, cadastro)
    .pipe(
      retry(2),
      catchError(this.handleError))
  }

  putFinalizar(cadastro: any){
    return this.httpClient.put(`${this.FINALIZAR}`, cadastro)
    .pipe(
      retry(2),
      catchError(this.handleError))
  }

  getHistorico(param: string): Observable<any> {
    return this.httpClient.get<any>(`${this.HISTORICO}?id=` + param, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  getConsultaAberta(param: string): Observable<any> {
    return this.httpClient.get<any>(`${this.CONSULTA_EM_ABERTO}?id=` + param, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  };
}
