import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';


export interface PeriodicElement {
  imagem: string;
  data: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
  {imagem: '', data: '04/11/2020'},
];



@Component({
  selector: 'app-programadas',
  templateUrl: './programadas.component.html',
  styleUrls: ['./programadas.component.css']
})
export class ProgramadasComponent implements OnInit {

  displayedColumns: string[] = ['imagem', 'data', 'star'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);


  //@ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) { 
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn" || authState.user.attributes.profile != "medico-geral") {
        this.router.navigateByUrl("");
      }
    })
  }

  ngOnInit(): void {
  }

  toPrincipal(){
    this.router.navigate(['principalG'])
  }

}
