import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orientacion-ia',
  templateUrl: './orientacion-ia.component.html',
  styleUrls: ['./orientacion-ia.component.css']
})
export class OrientacionIaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
