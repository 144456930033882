import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.css']
})
export class covid19Component implements OnInit {

  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
   
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }


}
