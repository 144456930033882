<div class="ajuste"></div>


<mat-card class="card">
    <mat-card-title>Datos</mat-card-title>
    <hr>
    <canvas id="grafico1" class="graficos"></canvas>
    <br>
    <canvas id="grafico2" class="graficos"></canvas>
    <br>
    <canvas id="grafico3" class="graficos"></canvas>
    <br>
    <canvas id="grafico4" class="graficos"></canvas>


    <button mat-raised-button color="accent" (click)="toPrincipal()">Voltar</button>
</mat-card>


  