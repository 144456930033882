import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { Mg } from 'src/app/model/modelo-mg';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { ImgService } from 'src/app/service/img/img.service';

@Component({
  selector: 'app-principal-geral',
  templateUrl: './principal-geral.component.html',
  styleUrls: ['./principal-geral.component.css']
})
export class PrincipalGeralComponent implements OnInit {

  user = {
    nombre: '',
    id: ''
  }
  medico: string = '';
  loading = true;
  carregando = true;
  urlink: any = "../../../assets/avatar.png";

  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private servico: CadastroService,
    private imgService: ImgService,
    private amplifyService: AmplifyService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      this.medico = authState.user.attributes.profile;
      if (authState.state != "signedIn" || authState.user.attributes.profile != "medico-geral") {
        this.router.navigateByUrl("");
      }
      this.user.id = authState.user.username;// retornar o ID do usuario
    })
  }

  ngOnInit(): void {
    this.getMedicoGeral();
    this.recuperaIMG();
    this.innerWidth = window.innerWidth;
    if (this.user.id == '') { // Recarreca a pag caso nao esteja carregado os dados
      //location.reload();
    }
  }
  disponiveis() {
    this.router.navigate(['orientacion-geral'])
  }
  HC(){
    this.router.navigate(['historico-medico'])
  }
  toMedicoGeral() {
    this.router.navigate(['edit-mg'])
    setTimeout(function () { location.reload(); }, 0)
  }
  toOrientacao() {
    this.router.navigate(['orientacion'])
  }
  toOrientacaoProgramada() {
    this.router.navigate(['geralProgramadas'])
  }
  toHistorico() {
    this.router.navigate(['historial'])
  }
  toDatos() {
    this.router.navigate(['datos'])
  }
  getMedicoGeral() {
    this.servico.getMedicoGeral(this.user.id).subscribe((mg: Mg) => {
      this.construtorMedicoGeral(mg);
    })
  }

  construtorMedicoGeral(mg: any) {
    this.user.nombre = mg.name
  }


  recuperaIMG() {
    this.imgService.getIMG(this.user.id).subscribe((img: any) => {
      if (!img.errorMessage) {
        this.urlink = `data:image/jpeg;base64,${img}`;
        this.carregando = false;
        this.loading = false;
      } else {
        this.carregando = false;
        this.loading = false;
      }
    });
  }
}
