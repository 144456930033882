<div>
    <h1 mat-dialog-title>Solicitação feita {{usuario.datetime_request}}</h1>
    <table>
        <tr>
            <td><b>AREA</b></td>
            <td>{{usuario.role}}</td>
        </tr>
        <tr>
            <td><b>NOMBRE</b></td>
            <td>{{usuario.name}}</td>
        </tr>
        <tr>
            <td><b>GENERO</b></td>
            <td>{{usuario.gender}}</td>
        </tr>
        <tr>
            <td><b>EMAIL</b></td>
            <td>{{usuario.email}}</td>
        </tr>
        <tr>
            <td><b>FECHA NACIMENTO</b></td>
            <td>{{usuario.birth[8]}}{{usuario.birth[9]}}/{{usuario.birth[5]}}{{usuario.birth[6]}}/{{usuario.birth[0]}}{{usuario.birth[1]}}{{usuario.birth[2]}}{{usuario.birth[3]}}</td>
        </tr>
        <tr>
            <td><b>NACIONALIDAD</b></td>
            <td>{{usuario.nationality}}</td>
        </tr>
        <tr>
            <td><b>CIUDAD</b></td>
            <td>{{usuario.city}}</td>
        </tr>
        <tr>
            <td><b>UBICACION</b></td>
            <td>{{usuario.address}}</td>
        </tr>
        <tr>
            <td><b>TIPO DE INDENTIDAD</b></td>
            <td>{{usuario.document_type}}</td>
        </tr>
        <tr>
            <td><b>NUMBERO DE INDENTIDAD</b></td>
            <td>{{usuario.document_number}}</td>
        </tr>
        <tr>
            <td><b>TELEFONO</b></td>
            <td>{{usuario.phone}}</td>
        </tr>
        <tr>
            <td><b>RETHUS</b></td>
            <td>{{usuario.rethus}}</td>
        </tr>
        <tr>
            <td><b>GRUPO INTERESSANTE </b></td>
            <td>{{usuario.interest_group}}</td>
        </tr>
        <tr>
            <td><b>ESPECIALIDADE INTERESSANTE</b></td>
            <td>{{usuario.interest_specialty}}</td>
        </tr>
        <tr>
            <td><b>COMENTARIO</b></td>
            <td>{{usuario.comment}}</td>
        </tr>
    </table>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="onNoClick()" style="margin: 2% 0 0 45%; border: 0; width: 10%; background-color: lightgray;">Voltar</button>
    </div>
</div>