import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {
 
  conta = {
    img: '',
    nombre: 'Fulando',
    apellido: 'Beltrano',
    nacimiento: '',
    direccion: 'Ok',
    documentNumber: '',
  }

  urlink: string="../../../assets/avatar.png";
  selectFile(event: any){
    if(event.target.files){
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (event: any)=>
        this.urlink = event.target.result
    }

  }

  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) { 
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn") {
        this.router.navigateByUrl("");
      }
    })
  }

  ngOnInit(): void {
  }

}
