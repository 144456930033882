<table class="cabecalho" *ngIf="carregando == false">
    <p>{{componente}}</p>
</table>
<!---->
<div *ngIf="carregando == true">
    <mat-spinner></mat-spinner>
</div>
<!---->
<div id="div1" *ngIf="carregando == false">
    <div class="div1">
        <p style="font-size: 22px; text-align: center; margin: 20px 0 10px 0;">SOLICITUDE DE INGRESO</p>
        <table class="table" style="width:100%">
            <tr>
                <td><b>AREA</b></td>
                <td>{{usuario.role}}</td>
            </tr>
            <tr>
                <td><b>NOMBRE</b></td>
                <td>{{usuario.name}}</td>
            </tr>
            <tr>
                <td><b>GENERO</b></td>
                <td>{{usuario.gender}}</td>
            </tr>
            <tr>
                <td><b>EMAIL</b></td>
                <td>{{usuario.email}}</td>
            </tr>
            <tr>
                <td><b>FECHA NACIMENTO</b></td>
                <td>{{usuario.birth | date: 'dd/MM/yyyy'}}</td>
            </tr>
            <tr>
                <td><b>NACIONALIDAD</b></td>
                <td>{{usuario.nationality}}</td>
            </tr>
            <tr>
                <td><b>CIUDAD</b></td>
                <td>{{usuario.city}}</td>
            </tr>
            <tr>
                <td><b>UBICACION</b></td>
                <td>{{usuario.address}}</td>
            </tr>
            <tr>
                <td><b>TIPO DE INDENTIDAD</b></td>
                <td>{{usuario.document_type}}</td>
            </tr>
            <tr>
                <td><b>NUMBERO DE INDENTIDAD</b></td>
                <td>{{usuario.document_number}}</td>
            </tr>
            <tr>
                <td><b>TELEFONO</b></td>
                <td>{{usuario.phone}}</td>
            </tr>
            <tr>
                <td><b>RETHUS</b></td>
                <td>{{usuario.rethus}}</td>
            </tr>
            <tr>
                <td><b>GRUPO INTERESSANTE </b></td>
                <td>{{usuario.interest_group}}</td>
            </tr>
            <tr>
                <td><b>ESPECIALIDADE INTERESSANTE</b></td>
                <td>{{usuario.interest_specialty}}</td>
            </tr>
        </table>

        <p style="font-size: 22px; text-align: center; margin: 20px 0 10px 0;">COMENTARIO</p>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label></mat-label>
            <textarea [(ngModel)]="comentario" matInput></textarea>
        </mat-form-field>

        <button mat-raised-button color="primary" [disabled]="checked==false || usuario.id == ''" [class.spinner]="loadingA"
            (click)="aceitar()">ACEPTAR</button>
        <button style="margin-left: 1%;" [disabled]="comentario =='' || controle == true || usuario.id == ''" [class.spinner]="loading"
            mat-raised-button (click)="negar()">NEGAR</button>
        <mat-checkbox class="box" [(ngModel)]="checked">Liave de Seguridad</mat-checkbox>

    </div>
    <div class="div2">
        <p style="font-size: 22px; text-align: center; margin: 20px auto 10px auto;">HISTORIAL DE SOLICITUDE</p>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table" style="width: 100%;">

            <!-- Position Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell style="width: 20%;" *matHeaderCellDef>
                    <p class="table-title">STATUS</p>
                </th>
                <td mat-cell *matCellDef="let element"> <button mat-button style="width: 100%;"
                        (click)="visualizar_status(element)"> {{element.status}} </button></td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="solicitado">
                <th mat-header-cell style="width: 30%;" *matHeaderCellDef>
                    <p class="table-title">SOLICITADO</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p style="text-align: center; margin: auto auto auto auto;">
                        {{element.datetime_request[8]+element.datetime_request[9]+'/'+element.datetime_request[5]+element.datetime_request[6]+'/'+element.datetime_request[0]+element.datetime_request[1]+element.datetime_request[2]+element.datetime_request[3]}}
                    </p>
                </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="respondido">
                <th mat-header-cell style="width: 30%;" *matHeaderCellDef>
                    <p class="table-title">RESPONDIDO</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p style="text-align: center; margin: auto auto auto auto;">
                        {{element.datetime_response[8]+element.datetime_response[9]+'/'+element.datetime_response[5]+element.datetime_response[6]+'/'+element.datetime_response[0]+element.datetime_response[1]+element.datetime_response[2]+element.datetime_response[3]}}
                    </p>
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="oficinista">
                <th mat-header-cell style="width: 20%;" *matHeaderCellDef>
                    <p class="table-title">OFICINISTA</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p style="text-align: center; margin: auto auto auto auto;"> {{element.attendants_name}} </p>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>