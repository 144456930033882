<div style="margin-top: 60px;"></div>
<!---->
<div *ngIf="carregando == true">
    <mat-spinner></mat-spinner>
</div>
<!---->
<div style="text-align: center; margin-top: 50px; margin-bottom: 50px;" *ngIf="carregando == false">
    <h1>PACIENTE │ TEDI</h1>
</div>
<!------->
<div class="formulario" *ngIf="controleIMG==0 && carregando == false">
    <img src="{{urlink}}" class="avatar">
    <button mat-icon-button (click)="fileInput.click()">
        <mat-icon>library_add</mat-icon>
        <input #fileInput type="file" style="display:none;" (change)="selectFile($event)" />
    </button>
</div>
<!------------------------------------------------------>
<div class="imgCut" *ngIf="controleIMG ==1 && carregando == false">
    <h2>Redimensione a foto de acordo</h2>
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [resizeToWidth]=256
        [resizeToHeight]=256 [cropperMinWidth]=256 [cropperMinHeight]=256 [aspectRatio]="1/1" alignImage="center"
        [cropperStaticHeight]=0 format="png" (imageCropped)="imageCropped($event)"></image-cropper>
    <button (click)="image64()">Confirmar</button>
</div>
<!---------------------->
<div class="formulario" *ngIf="carregando == false">
    <form #form="ngForm">
        <br>
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Nombre Completo</mat-label>
            <input matInput type="text" id="nombre" name="nombre" [(ngModel)]="conta.nombre" required>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Tipo documento</mat-label>
            <mat-select matInput id="documentoT" name="documentoT" [(ngModel)]="conta.tipodocumento" required>
                <mat-option value="CC">CC</mat-option>
                <mat-option value="cedula estrangeria">Cédula Estrangeria</mat-option>
                <mat-option value="Passaporte">Passaporte</mat-option>
                <mat-option value="registro civil">Registro Civil</mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Número del documento</mat-label>
            <input matInput type="text" id="documento" name="documento" [(ngModel)]="conta.documento" required>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Género</mat-label>
            <mat-select matInput id="genero" name="genero" [(ngModel)]="conta.genero" required>
                <mat-option value="Hombre">Hombre</mat-option>
                <mat-option value="Mujer">Mujer</mat-option>
                <mat-option value="Otro">Otro</mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field class="cadastro" appearance="fill">
            <mat-label>Nacionalidad</mat-label>
            <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto"
                [(ngModel)]="conta.nacionalidad" required>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <br>
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Ciudad</mat-label>
            <input matInput type="text" id="ciudad" name="ciudad" [(ngModel)]="conta.ciudad" required>
        </mat-form-field>

        <br>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Direccion</mat-label>
            <input matInput type="text" id="direccion" name="direccion" [(ngModel)]="conta.direccion" required>
        </mat-form-field>

        <br>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Correo</mat-label>
            <input matInput type="text" id="email" name="email" [(ngModel)]="conta.email" required disabled>
        </mat-form-field>

        <br>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Fecha nacimiento</mat-label>
            <input matInput type="date" id="nacimiento" name="nacimiento" [(ngModel)]="conta.nacimiento" required>
        </mat-form-field>

        <br>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Qué esperas de una orientación médica?</mat-label>
            <mat-select matInput id="esperas" name="esperas" [(ngModel)]="conta.esperas" required>
                <mat-option value="Confiabilidade">Confiabilidade</mat-option>
                <mat-option value="Amamilidad">Amamilidad</mat-option>
                <mat-option value="Humanismo">Humanismo</mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <button mat-raised-button class="registerbtn" type="submit" [class.spinner]="loading"
            [disabled]="loading || !form.form.valid" (click)="verificar()">Actualizar</button>
    </form>
</div>

<div class="ajuste"></div>