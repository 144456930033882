<div class="container-fluid bg-body py-5 my-5">
  <div *ngIf="loading == true">
    <mat-spinner></mat-spinner>
  </div>
  <!---->
  <div *ngIf="loading == false">
    <div>
      <img src="{{urlink}}" alt="Avatar" class="avatar">
      <!--
    <button mat-mini-fab color="primary" style="float: right; margin: 3% 10% 0 0 ;">
        <mat-icon>settings</mat-icon>
    </button>
-->
      <br>
      <div style="text-align: center;
                margin-top:-10px;
                margin-bottom:50px;
                font-size: 20pt;">
        {{user.nombre}}
        <img src="../../../assets/icons/edit.png" style="height: 20px; cursor: pointer;" (click)="toEditpaciente()">
      </div>

      <div style="
        width: 80%;
        height: 1px;
        background-color: rgb(160, 160, 160);
        margin-bottom: 70px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 0px;">
      </div>

    </div>
    <mat-grid-list cols="2" rowHeight="200px" style="margin-left: 25%; margin-right: 25%;" gutterSize="5px"
      *ngIf="innerWidth>600">

      <mat-grid-tile>
        <a mat-raised-button class="button-style" href="/orientacion-chat">
          <img src="../../../assets/icons/alert2.png" class="alert" *ngIf="alert == true">
          <br>
          <img src="../../../assets/icons/steto.png" class="icon2" *ngIf="alert == true">
          <img src="../../../assets/icons/steto.png" class="icon" *ngIf="alert == false">
          <br>
          <p>Orientacion</p>
          <br>
        </a>
      </mat-grid-tile>

      <mat-grid-tile>
        <button mat-raised-button class="button-style" (click)="toOrientacaoProgramada()">
          <br>
          <img src="../../../assets/icons/agenda.png" class="icon">
          <br>
          <p style="text-align: center;">Orientaciones Programadas</p>
          <br>
        </button>
      </mat-grid-tile>
      <mat-grid-tile>
        <a mat-raised-button class="button-style" href="/historial">
          <br>
          <img src="../../../assets/icons/calendar.png" class="icon">
          <br>
          <p>Historial</p>
          <br>
        </a>
      </mat-grid-tile>
      <mat-grid-tile>
        <button mat-raised-button class="button-style">
          <br>
          <img src="../../../assets/icons/video.png" class="icon">
          <br>
          Videos
          <br>
        </button>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="2" rowHeight="200px" style="margin-left: 10%; margin-right: 10%;" gutterSize="5px"
      *ngIf="innerWidth<600">
      <mat-grid-tile>

        <button mat-raised-button class="button-style" (click)="toOrientacao()">

          <img src="../../../assets/icons/steto.png" class="icon">
          <br>
          Solicitar <br> Orientacion
          <br>
        </button>
      </mat-grid-tile>

      <mat-grid-tile>
        <button mat-raised-button class="button-style" (click)="toOrientacaoProgramada()">
          <img src="../../../assets/icons/agenda.png" class="icon">
          <br>
          Orientaciones <br> Programadas
          <br>
        </button>
      </mat-grid-tile>
      <mat-grid-tile>
        <button mat-raised-button class="button-style" (click)="toHistorico()">
          <img src="../../../assets/icons/calendar.png" class="icon">
          <br>
          Historial de <br> Orientaciones
          <br>
        </button>
      </mat-grid-tile>
      <mat-grid-tile>
        <button mat-raised-button class="button-style">
          <img src="../../../assets/icons/video.png" class="icon">
          <br>
          Videos
          <br>
          <br>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
<app-footer></app-footer>
