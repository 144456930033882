import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AvaliacaoComponent } from './auth/avaliacao/avaliacao.component';
import { ChatProfissionalComponent } from './auth/chat-profissional/chat-profissional.component';
import { HistoricoComponent } from './auth/historico/historico.component';
import { OrientacionesProgramadasComponent } from './auth/orientaciones-programadas/orientaciones-programadas.component';
import { PerfilComponent } from './auth/perfil/perfil.component';
import { PrincipalComponent } from './auth/principal/principal.component';
import { ProgramadoComponent } from './auth/programado/programado.component';
import { SolicitarOrientacionComponent } from './auth/solicitar-orientacion/solicitar-orientacion.component';
import { smartfilms2020Component } from './estatico/smartfilms2020/smartfilms2020.component';
import { covid19Component } from './estatico/covid19/covid19.component';
import { HomeComponent } from './estatico/home/home.component';
import { EntrarComponent } from './formularios/entrar/entrar.component';
import { RegistrarComponent } from './formularios/registrar/registrar.component';
import { QuienessomosComponent } from './estatico/quienessomos/quienessomos.component';
import { EconverdeComponent } from './estatico/econverde/econverde.component';
import { ComooperamosComponent } from './estatico/comooperamos/comooperamos.component';
import { CadastroPacienteComponent } from './formularios/cadastro-paciente/cadastro-paciente.component';
import { CadastroMeComponent } from './formularios/cadastro-me/cadastro-me.component';
import { CadastroMgComponent } from './formularios/cadastro-mg/cadastro-mg.component';
import { EditPacienteComponent } from './formularios/edit-paciente/edit-paciente.component';
import { EditMgComponent } from './formularios/edit-mg/edit-mg.component';
import { EditMeComponent } from './formularios/edit-me/edit-me.component';
import { AutenticarMedicoComponent } from './auth/autenticar-medico/autenticar-medico.component';
import { SalaEsperaComponent } from './auth/sala-espera/sala-espera.component';
import { AprovarMedicoComponent } from './auth/aprovar-medico/aprovar-medico.component';
import { RegistroDeUsuariosComponent } from './auth/registro-de-usuarios-adm/registro-de-usuarios.component';
import { RegistroMedicoComponent } from './auth/registro-medico/registro-medico.component';
import { SalaDeConfirmacaoMedicaComponent } from './auth/sala-de-confirmacao-medica/sala-de-confirmacao-medica.component';
import { PrincipalEspecialistaComponent } from './auth/medico/especialista/principal-especialista/principal-especialista.component';
import { PrincipalGeralComponent } from './auth/medico/geral/principal-geral/principal-geral.component';
import { OrientacionComponent } from './auth/medico/geral/orientacion/orientacion.component';
import { OrientacionesDispMGeralComponent } from './auth/medico/geral/orientaciones-disp-mgeral/orientaciones-disp-mgeral.component';
import { HistorialDeOrientacionesComponent } from './auth/medico/historial-de-orientaciones/historial-de-orientaciones.component';
import { ProgramadasComponent } from './auth/medico/geral/programadas/programadas.component';
import { EvaluacionComponent } from './auth/medico/evaluacion/evaluacion.component';
import { DatosComponent } from './auth/medico/datos/datos.component';
import { OrientacionesDispMEspecialistaComponent } from './auth/medico/especialista/orientaciones-disp-mespecialista/orientaciones-disp-mespecialista.component';
import { HistoriaClinicaComponent } from './auth/medico/geral/historia-clinica/historia-clinica.component';
import { PreCadastroComponent } from './formularios/pre-cadastro/pre-cadastro.component';
import { ExperienceComponent } from './estatico/experience/experience.component';
import { NuestraVisionComponent } from './estatico/nuestra-vision/nuestra-vision.component';
import { OrientacionIaComponent } from './estatico/orientacion-ia/orientacion-ia.component';
import { OrientacionChatGPTComponent } from './auth/orientacion-chat-gpt/orientacion-chat-gpt.component';

const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'experience', component: ExperienceComponent},
  {path: 'pre-cadastro', component: PreCadastroComponent},
  {path: 'nuestra-vision', component: NuestraVisionComponent},
  {path: 'orientacion-ia', component: OrientacionIaComponent},
  {path: 'login', component: EntrarComponent},
  {path: 'principal', component: PrincipalComponent},
  {path: 'orientacion-chat', component: OrientacionChatGPTComponent},
  {path: 'orientacao', component: SolicitarOrientacionComponent},
  {path: 'historial', component: HistoricoComponent},
  {path: 'edit-paciente', component: EditPacienteComponent},
  /*{path: 'registrar', component: RegistrarComponent},
  {path: 'perfil', component: PerfilComponent},
  {path: 'programado', component: ProgramadoComponent},
  {path: 'orientacao-programada', component: OrientacionesProgramadasComponent},
  {path: 'chat', component: ChatProfissionalComponent},
  {path: 'avaliacao', component: AvaliacaoComponent},
  {path: 'smart2020', component: smartfilms2020Component},
  {path: 'covid19', component: covid19Component},
  {path: 'quienessomos', component: QuienessomosComponent},
  {path: 'econverde', component: EconverdeComponent},
  {path: 'comooperamos', component: ComooperamosComponent},
  {path: 'cadastro-p', component: CadastroPacienteComponent},
  {path: 'cadastro-me', component: CadastroMeComponent},
  {path: 'cadastro-mg', component: CadastroMgComponent},
  {path: 'resgistro-medico', component: RegistroMedicoComponent},
  {path: 'edit-mg', component: EditMgComponent},
  {path: 'edit-me', component: EditMeComponent},
  {path: 'adm', component: AutenticarMedicoComponent},
  {path: 'espera', component: SalaEsperaComponent},
  {path: 'aprovar-medico', component: AprovarMedicoComponent},
  {path: 'registrar-usuario', component: RegistroDeUsuariosComponent},
  {path: 'sala-de-confirmacao', component: SalaDeConfirmacaoMedicaComponent},
  {path: 'principalG', component: PrincipalGeralComponent},
  {path: 'principalE', component: PrincipalEspecialistaComponent},
  {path: 'orientacion', component: OrientacionComponent},
  {path: 'orientacion-geral', component: OrientacionesDispMGeralComponent},
  {path: 'orientacion-esp', component: OrientacionesDispMEspecialistaComponent},
  {path: 'geralProgramadas', component: ProgramadasComponent},
  {path: 'historial', component: HistorialDeOrientacionesComponent},
  {path: 'datos', component: DatosComponent},
  {path: 'evalucion', component: EvaluacionComponent},
  {path: 'historico-medico', component: HistoriaClinicaComponent},*/
  {path: '**',redirectTo: 'home'},
];
@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
