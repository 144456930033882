<div class="ajuste"></div>
<mat-card class="card">
    <mat-card-title>Orientaciones Disponibles</mat-card-title>
    <hr>

    <mat-card>
        <span>{{dadosDisponivel.data | date: 'dd/MM/yyyy'}}</span>
        <br>
        <span>{{dadosDisponivel.distancia}} - </span>
        <span>{{dadosDisponivel.horario}}</span>
        <br>
        <span>{{dadosDisponivel.endereco}}</span>
        <br>
        <span>{{dadosDisponivel.genero}} </span>
        <span> {{dadosDisponivel.idade}}</span>
        <br>
        <span>{{dadosDisponivel.sintoma}}</span>
        <br>

        <button mat-raised-button color="primary" class="botao" *ngIf="innerWidth>600">Aceptar</button>
        <button mat-mini-fab color="primary" style="margin-right: 2%;" *ngIf="innerWidth<600"><mat-icon>done</mat-icon></button>
        
        <button mat-raised-button color="warn" class="botao" *ngIf="innerWidth>600">Rechazar</button>
        <button mat-mini-fab color="warn"  *ngIf="innerWidth<600"><mat-icon>clear</mat-icon></button>

    </mat-card>


</mat-card>