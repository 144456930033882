import {Component, OnInit, Inject} from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatGptService } from 'src/app/service/chatGpt/chat-gpt.service';

import {MatTableModule} from '@angular/material/table';
import { ImgService } from 'src/app/service/img/img.service';

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.css']
})
export class HistoricoComponent implements OnInit {

  user = {
    id: ''
  }

  responseInfo: any = {
    AI_cause: '',
    AI_treatment: '',
    AI_treatment_pharmacological: '',
    AI_leaflet: '',
    AI_natural_medicine: '',
    datetime: '',
    ai_01: undefined,
    ai_02: undefined,
    ai_03: undefined,
    ai_04: undefined,
    ai_05: undefined,
    user_01: undefined,
    user_02: undefined,
    user_03: undefined,
    user_04: undefined,
    user_05: undefined,
  }

  urlink: any = "../../../assets/avatar.png";
  iaImg: any = "../../../assets/avatar.png";

  displayedColumns: string[] = ['date', 'symptom'];
  dataSource: any;

  constructor(
    private gpt: ChatGptService,
    private amplifyService: AmplifyService,
    private router: Router,
    private imgService: ImgService,
    public dialog: MatDialog
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn") {
        this.router.navigateByUrl("");
      }
      this.user.id = authState.user.username;// retornar o ID do usuario
    })
  }

  ngOnInit(): void {
    this.getHistorico();
    this.recuperaIMG();
  }
  getHistorico() {
    this.gpt.getHistorico(this.user.id).subscribe((usuario: any) => {
      //console.log(usuario);
      this.dataSource = usuario;
      //console.log(this.dataSource)
    })
  }

  visualizar_status(dados: any) {
    this.responseInfo = dados;
    console.log(this.responseInfo);
  }
  recuperaIMG() {
    this.imgService.getIMG(this.user.id).subscribe((img: any) => {
      if (!img.errorMessage) {
        this.urlink = `data:image/jpeg;base64,${img}`;

      } else {

      }
    });
  }
}
