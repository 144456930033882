/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:517ae5bb-6cea-49c3-ae39-2bdf8e082dbd",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_AikdfMb1x",
    "aws_user_pools_web_client_id": "3uivan0r12sib9p14o82nuk380",
    "oauth": {}
};

export default awsmobile;