import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { AmplifyService } from 'aws-amplify-angular';
import { AdministradorService } from 'src/app/service/adm/adm.service';


@Component({
  selector: 'app-autenticar-medico',
  templateUrl: './autenticar-medico.component.html',
  styleUrls: ['./autenticar-medico.component.css']
})
export class AutenticarMedicoComponent implements OnInit {

  userId: CognitoUserInterface | undefined;
  authState: AuthState | undefined;

  //Subistituir pelas informacoes do banco de dados
  adm = {
    name: '',
    botao1: false,
    botao2: false,
    botao3: false,
    botao4: false,
    botao5: false,
  }

  carregando = true;

  id: any;

  constructor(
    private amplifyService: AmplifyService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private servico: AdministradorService
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      console.log(authState)
      this.id = authState.user.username;
      if (authState.state != "signedIn" || authState.user.attributes.profile != 'administrator') {
        this.router.navigateByUrl("");
      }
    })
  }

  ngOnInit(): void {
    if(this.id == null ){
      location.reload()
    }
    this.getUsuarioID();
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.userId = authData as CognitoUserInterface;
      this.ref.detectChanges();
    })
  }

  getUsuarioID(){
    this.servico.getAdmID(this.id).subscribe((usuario: any)=>{
      console.log(usuario)
      this.construtorPaciente(usuario);
    })
  }
  construtorPaciente(usuario: any){//colocar tudo false quando conseguir logar com um novo usuario
    this.adm.name = usuario.name,
    this.adm.botao1 = usuario.wbutton_01 || false,// voltar a false quando for testa
    this.adm.botao2 = usuario.wbutton_02 || false,//
    this.adm.botao3 = usuario.wbutton_03 || false,
    this.adm.botao4 = usuario.wbutton_04 || false,
    this.adm.botao5 = usuario.wbutton_05 || false,
    this.carregando = false
  }

  toAprovar() {
    this.router.navigateByUrl('aprovar-medico');
    //setTimeout(function () { location.reload(); }, 0)
  }
  toRegistrar() {
    this.router.navigateByUrl('registrar-usuario');
  }

}
