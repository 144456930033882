
<mat-grid-list cols="1" rowHeight="50px" *ngIf="innerWidth>600" style="background-color:rgb(242, 242, 242)">
    <div style="margin-top: 60px;"></div>

    <mat-grid-tile [rowspan]="10">
        <p class="titulo-img" style="z-index: 1; top:50px; right: 200px;">LA PREVENCIÓN <br> ESTÁ EN TI</p>
        <img src="../../../assets/backgrounds/pandemicc19.webp" style="padding-top: 10%; width:110%; height: 190%;  z-index: 0;">    
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="10">
        <img src="../../../assets/backgrounds/babywashhand.webp" style="position: absolute; border: 10px solid rgb(255, 255, 255); top:75px; left:55%; width:380px; height: 319px;  z-index: 0;"> 
        <p class="texto-img">El COVID-19 es la enfermedad infecciosa causada por el <br>
                             coronavirus que se ha descubierto más recientemente. Una <br>
                             persona puede contraer el COVID-19 por contacto con otra <br>
                             que esté infectada por el virus. La enfermedad puede <br>
                             propagarse de persona a persona a través de las gotículas <br>
                             procedentes de la nariz o la boca que salen despedidas <br>
                             cuando una persona infectada tose o exhala.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="7" style="background-color: rgb(233, 150, 102) ;">
        <p style="text-align: center; position: absolute; font-family: 'Heeboo'; font-size: 40px; z-index: 1; top: 160px; right: 60%; line-height: 120%;">SÍNTOMAS DE ALERTA</p>
            <ul class="texto-img" style="left: 50%; top:80px;">
                <li>Fiebre de difícil control mayor a 37,5º e por más de 3 días </li>
                <li>Tos </li>
                <li>Fatiga </li>
                <li>Malestar general </li>
                <li>Dificultad para respirar </li>
                <li>Secreciones nasales </li>
            </ul>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2" >
        <p style="text-align: left; position: absolute; font-family: 'Heeboo'; font-size: 30px; z-index: 1; top: 00px; right: 60%; line-height: 120%;">ENLACES <br> DE INTERÉS</p>
    </mat-grid-tile>

    <div><img src="../../../assets/backgrounds/c19stayhome.webp" style="position: absolute; top:1500px; left:67.5%; width:560px; height: 900px;  z-index: 0; margin-right:20px;">
         <img src="../../../assets/backgrounds/c19nottoday.webp" style="position: absolute; top:1700px; left:5%; width:369px; height: 524px;  z-index: 0; margin-right:20px;"></div>

    <mat-grid-tile [rowspan]="4">

        <mat-card style="width:370px; height: 120px;">
        <img src="../../../assets/backgrounds/c19alcadia.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
        <div style="float:right; margin-top: 30px; ">
            <p style="text-align: left; font-family: 'Heeboo'; font-size: 20px; z-index: 1; line-height: 120%;">Alcaldía Mayor de Bogotá</p>
            <a mat-raised-button href="https://bogota.gov.co/mi-ciudad/salud/coronavirus" style="color: white; background-color:rgb(41, 41, 41); margin-left: 60px; margin-top: 10px;" >Aquí</a>
        </div>
        </mat-card>

        <mat-card style="margin-left: 50px; width:370px; height: 120px;">
            <img src="../../../assets/backgrounds/c19ministerio.webp" style=" top:75px; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
            <div style="float:right; margin-top: 30px; margin-right: 30px;">
                <p style="text-align: left; font-family: 'Heeboo'; font-size: 20px; z-index: 1; line-height: 120%;">Ministerio de Salud</p>
                <a mat-raised-button href="https://www.minsalud.gov.co/Paginas/default.aspx" style="color: white; background-color:rgb(41, 41, 41); margin-left: 60px; margin-top: 10px;" >Aquí</a>
            </div>
        </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <mat-card style="width:370px; height: 120px;">
        <img src="../../../assets/backgrounds/c19secretaria.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
        <div style="float:right; margin-top: 30px;">
            <p style="text-align: left; font-family: 'Heeboo'; font-size: 20px; z-index: 1; line-height: 120%;">Secretaría de Salud Bogotá</p>
            <a mat-raised-button href="http://saludcapital.gov.co/Paginas2/Inicio.aspx" style="color: white; background-color:rgb(41, 41, 41); margin-left: 60px; margin-top: 10px;" >Aquí</a>
        </div>
        </mat-card>

        <mat-card style="margin-left: 50px; width:370px; height: 120px;">
            <img src="../../../assets/backgrounds/c19colombia.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
            <div style="float:right; margin-top: 30px; margin-right: 30px;">
                <p style="text-align: left; font-family: 'Heeboo'; font-size: 20px; z-index: 1; line-height: 120%;">Coronavirus Colombia</p>
                <a mat-raised-button href="https://coronaviruscolombia.gov.co/Covid19/index.html" style="color: white; background-color:rgb(41, 41, 41); margin-left: 60px; margin-top: 10px;" >Aquí</a>
            </div>
        </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <mat-card style="width:370px; height: 120px;">
        <img src="../../../assets/backgrounds/c19temporeal.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
        <div style="float:right; margin-top: 30px;">
            <p style="text-align: left; font-family: 'Heeboo'; font-size: 20px; z-index: 1; line-height: 120%;">Coronavirus en tiempo real</p>
            <a mat-raised-button href="https://www.abc.es/sociedad/abci-mapa-coronavirus-tiempo-real-202003181035_noticia.html" style="color: white; background-color:rgb(41, 41, 41); margin-left: 60px; margin-top: 10px;" >Aquí</a>
        </div>
        </mat-card>

        <mat-card style="margin-left: 50px; width:370px; height: 120px;">
            <img src="../../../assets/backgrounds/c19plazas.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
            <div style="float:right; margin-top: 10px; margin-right: 50px;">
                <p style="text-align: center; font-family: 'Heeboo'; font-size: 20px; z-index: 1; line-height: 120%;">Plazas Distritales <br> de Mercado</p>
                <a mat-raised-button href="https://828feb24-ba04-41c5-a229-844d9e1fe287.filesusr.com/ugd/548b62_0c1da0059cab454d95401991f1f62db9.pdf" style="color: white; background-color:rgb(41, 41, 41); margin-left: 40px; margin-top: 10px;" >Aquí</a>
            </div>
        </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <mat-card style="width:370px; height: 120px;">
            <img src="../../../assets/backgrounds/c19prueba.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
        <div style="float:right; margin-top: 10px; margin-right: 10px;">
            <p style="text-align: center; font-family: 'Heeboo'; font-size: 20px; z-index: 1; line-height: 120%;">Lineamientos para el uso <br> del pruebas COVID-19</p>
            <a mat-raised-button href="https://828feb24-ba04-41c5-a229-844d9e1fe287.filesusr.com/ugd/5182ac_507604813749451b843c759257d49c5d.pdf" style="color: white; background-color:rgb(41, 41, 41); margin-left: 60px; margin-top: 10px;" >Aquí</a>
        </div>
        </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="12"> 
        <img src="../../../assets/backgrounds/ocean.webp" style=" margin-bottom:550px; width:100%; height: 200%;  z-index: 0;">
        <iframe style="border-radius: 10px; width: 854px; height: 480px; object-fit: cover; position: absolute; left: 25%; top: 60px;"
        width="100%" 
        height="315"
        src="https://www.youtube.com/embed/9Kruo_oTw8w" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
        </iframe>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="12">
        <img src="../../../assets/backgrounds/maskmonalisa.webp" style="position: absolute; border: 10px solid rgb(255, 255, 255); top:10px; left:20%; width:379px; height: 559px;  z-index: 0;"> 
        <p class="texto-img" style="top:150px; left:48%;">
            Ahora que conoces los síntomas debes tener claro que prevenir el <br> coronavirus es más fácil de lo que parece, solo sigue las siguientes <br> recomendaciones:
            <li> Lávate las manos con abundante agua y jabón como mínimo cada 3 horas.</li>
            <li> Hacerlo de manera frecuente reduce en hasta un 50% el riesgo de contraer el COVID-19.</li>
            <li> Evitar las aglomeraciones.</li>
            <li> Mantente hidratado.</li>
            <li> Evita saludar de beso y estrechar manos.</li>
            <li> Mantener informada a tu familia en caso de presentar síntomas.</li></p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2" >
    </mat-grid-tile>

</mat-grid-list>















<mat-grid-list cols="1" rowHeight="50px" *ngIf="innerWidth<600" style="background-color:rgb(242, 242, 242)">
    <div style="margin-top: 20px;"></div>

    <mat-grid-tile [rowspan]="8">
        <p class="titulo-img" style="z-index: 1; top:125px; right: 45px; font-size:16px;">LA PREVENCIÓN <br> ESTÁ EN TI</p>
        <img src="../../../assets/backgrounds/pandemicc19.webp" style="padding-top: 10%; width:175%; height: 100%;  z-index: 0;">    
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        
        <p class="texto-img" style="font-size:12px; left:15%;">El COVID-19 es la enfermedad infecciosa causada por el <br>
                             coronavirus que se ha descubierto más recientemente. Una <br>
                             persona puede contraer el COVID-19 por contacto con otra <br>
                             que esté infectada por el virus. La enfermedad puede <br>
                             propagarse de persona a persona a través de las gotículas <br>
                             procedentes de la nariz o la boca que salen despedidas <br>
                             cuando una persona infectada tose o exhala.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="5">
    <img src="../../../assets/backgrounds/babywashhand.webp" style="font-size:12px; position: absolute; border: 10px solid rgb(255, 255, 255); top:auto; left:auto; width:50%; height: 80%;  z-index: 0;"> 
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="7" style="background-color: rgb(233, 150, 102) ;">
        <p style="text-align: center; position: absolute; font-family: 'Heeboo'; font-size: 20px; z-index: 1; top: 35px; right: auto; line-height: 120%;">SÍNTOMAS DE ALERTA</p>
            <ul class="texto-img" style="left: 5%; top:80px; font-size: 16px;">
                <li>Fiebre de difícil control mayor a 37,5º <br> e por más de 3 días </li>
                <li>Tos </li>
                <li>Fatiga </li>
                <li>Malestar general </li>
                <li>Dificultad para respirar </li>
                <li>Secreciones nasales </li>
            </ul>

    </mat-grid-tile>


    <mat-grid-tile [rowspan]="2" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2" >
        <p style="text-align: center; position: absolute; font-family: 'Heeboo'; font-size: 20px; z-index: 1; top: 00px; right: auto; line-height: 120%;">ENLACES <br> DE INTERÉS</p>
    </mat-grid-tile>

    <div><img src="../../../assets/backgrounds/c19nottoday.webp" style="position: absolute; top:2700px; left:auto; width:100%; height: 524px;  z-index: 0;"></div>

    <mat-grid-tile [rowspan]="4">

        <mat-card style="margin-left: 0%; width:85%; height: 120px;">
        <img src="../../../assets/backgrounds/c19alcadia.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
        <div style="float:right; margin-top: 30px; margin-right: 10px;">
            <p style="text-align: left; font-family: 'Heeboo'; font-size: 14px; z-index: 1; line-height: 120%;">Alcaldía Mayor de Bogotá</p>
            <a mat-raised-button href="https://bogota.gov.co/mi-ciudad/salud/coronavirus" style=" color: white; background-color:rgb(41, 41, 41); margin-left: 0px; margin-top: 10px;" >Aquí</a>
        </div>
        </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <mat-card style="margin-left: 0%; width:85%; height: 120px;">
            <img src="../../../assets/backgrounds/c19ministerio.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
            <div style="float:right; margin-top: 30px; margin-right: 50px;">
                <p style="text-align: left; font-family: 'Heeboo'; font-size: 14px; z-index: 1; line-height: 120%;">Ministerio de Salud</p>
                <a mat-raised-button href="https://www.minsalud.gov.co/Paginas/default.aspx" style=" color: white; background-color:rgb(41, 41, 41); margin-left: 0px; margin-top: 10px;" >Aquí</a>
            </div>
        </mat-card>
    </mat-grid-tile>       
   

    <mat-grid-tile [rowspan]="4">
        <mat-card style="margin-left: 0%; width:85%; height: 120px;">
        <img src="../../../assets/backgrounds/c19secretaria.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
        <div style="float:right; margin-top: 30px; margin-right: 10px;">
            <p style="text-align: left; font-family: 'Heeboo'; font-size: 14px; z-index: 1; line-height: 120%;">Secretaría de Salud Bogotá</p>
            <a mat-raised-button href="http://saludcapital.gov.co/Paginas2/Inicio.aspx" style="color: white; background-color:rgb(41, 41, 41); margin-left: 0px; margin-top: 10px;" >Aquí</a>
        </div>
        </mat-card>
    </mat-grid-tile> 

        <mat-grid-tile [rowspan]="4">
            <mat-card style="margin-left: 0%; width:85%; height: 120px;">
            <img src="../../../assets/backgrounds/c19colombia.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
            <div style="float:right; margin-top: 30px; margin-right: 35px;">
                <p style="text-align: left; font-family: 'Heeboo'; font-size: 14px; z-index: 1; line-height: 120%;">Coronavirus Colombia</p>
                <a mat-raised-button href="https://coronaviruscolombia.gov.co/Covid19/index.html" style="color: white; background-color:rgb(41, 41, 41); margin-left: 0px; margin-top: 10px;" >Aquí</a>
            </div>
        </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <mat-card style="margin-left: 0%; width:85%; height: 120px;">
        <img src="../../../assets/backgrounds/c19temporeal.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
        <div style="float:right; margin-top: 30px; margin-right: 10px;">
            <p style="text-align: left; font-family: 'Heeboo'; font-size: 14px; z-index: 1; line-height: 120%;">Coronavirus en tiempo real</p>
            <a mat-raised-button href="https://www.abc.es/sociedad/abci-mapa-coronavirus-tiempo-real-202003181035_noticia.html" style="color: white; background-color:rgb(41, 41, 41); margin-left: 0px; margin-top: 10px;" >Aquí</a>
        </div>
        </mat-card>
    </mat-grid-tile> 

    <mat-grid-tile [rowspan]="4">
        <mat-card style="margin-left: 0%; width:85%; height: 120px;">
            <img src="../../../assets/backgrounds/c19plazas.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
            <div style="float:right; margin-top: 30px; margin-right: 65px;">
                <p style="text-align: center; font-family: 'Heeboo'; font-size: 14px; z-index: 1; line-height: 120%;">Plazas Distritales <br> de Mercado</p>
                <a mat-raised-button href="https://828feb24-ba04-41c5-a229-844d9e1fe287.filesusr.com/ugd/548b62_0c1da0059cab454d95401991f1f62db9.pdf" style="color: white; background-color:rgb(41, 41, 41); margin-left: 0px; margin-top: 10px;" >Aquí</a>
            </div>
        </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="4">
        <mat-card style="margin-left: 0%; width:85%; height: 120px;">
            <img src="../../../assets/backgrounds/c19prueba.webp" style=" top:75px; left:55%; width:123px; height: 120px;  z-index: 0; margin-right:20px;">
            <div style="float:right; margin-top: 30px; margin-right: 20px;">
            <p style="text-align: center; font-family: 'Heeboo'; font-size: 14px; z-index: 1; line-height: 120%;">Lineamientos para el uso <br> del pruebas COVID-19</p>
            <a mat-raised-button href="https://828feb24-ba04-41c5-a229-844d9e1fe287.filesusr.com/ugd/5182ac_507604813749451b843c759257d49c5d.pdf" style="color: white; background-color:rgb(41, 41, 41); margin-left: 0px; margin-top: 10px;" >Aquí</a>
        </div>
        </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="6" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="7"> 
        <img src="../../../assets/backgrounds/ocean.webp" style=" width:120%; height: 100%;  z-index: 0;">
        <iframe style="border-radius: 10px; width: 75%; height: 200px; object-fit: cover; position: absolute; left: auto; top: 60px;"
        width="100%" 
        height="315"
        src="https://www.youtube.com/embed/9Kruo_oTw8w" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
        </iframe>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2" >
    </mat-grid-tile>


    <mat-grid-tile [rowspan]="14">
        <img src="../../../assets/backgrounds/maskmonalisa.webp" style="position: absolute; border: 10px solid rgb(255, 255, 255); top:0px; left:auto; width:80%; height: 450px;  z-index: 0;"> 
        <p class="texto-img" style="text-align: left; top:500px; left:5%; font-size: 14px;">
            Ahora que conoces los síntomas debes tener claro que prevenir <br> el coronavirus es más fácil de lo que parece, solo sigue las siguientes recomendaciones:
            <li> Lávate las manos con abundante agua y jabón como <br> mínimo cada 3 horas.</li>
            <li> Hacerlo de manera frecuente reduce en hasta un 50% <br> el riesgo de contraer el COVID-19.</li>
            <li> Evitar las aglomeraciones.</li>
            <li> Mantente hidratado.</li>
            <li> Evita saludar de beso y estrechar manos.</li>
            <li> Mantener informada a tu familia en caso de presentar <br> síntomas.</li></p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>

</mat-grid-list>
