<nav class="navbar navbar-expand-lg bg-dark fixed-top mb-5" data-bs-theme="dark">
  <div class="container-fluid px-5 py-1">
    <a class="navbar-brand" href="/">
      <img
        src="https://res.cloudinary.com/juandacloud/image/upload/v1686788991/afoco-projects/tedi/TEDI_Letra_Blanca_LogoNom-01_1_vxaohv.png"
        class="img-fluid" alt="Tedi" width="75">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto text-center mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/orientacion-ia" routerLinkActive="active">Orientación IA</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/experience" routerLinkActive="active">Experiencia Visual</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/nuestra-vision" routerLinkActive="active">Nuestra Visión</a>
        </li>
      </ul>
      <form class="d-flex justify-content-center" role="register">
        <!--Retirado parar deploy-->
        <!--<a class="btn btn-dark" href="/login" *ngIf="autenticado==false" style="background-color: #2e5e88;">Acceso</a>
        <a class="btn btn-dark" href="/principal" *ngIf="autenticado==true"
          style="background-color: #2e5e88;margin-right: 3px;">Área Principal</a>

        <button class="btn btn-dark" type="submit" *ngIf="autenticado==true" style="background-color: #2e5e88;"
          (click)="deslogar()">Cerrar Sesión</button>-->
      </form>
    </div>
  </div>
</nav>
