import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';

@Component({
  selector: 'app-orientaciones-disp-mespecialista',
  templateUrl: './orientaciones-disp-mespecialista.component.html',
  styleUrls: ['./orientaciones-disp-mespecialista.component.css']
})
export class OrientacionesDispMEspecialistaComponent implements OnInit {

  
  // Dados que seram consumidos da api
  dadosDisponivel ={
    data: new Date(),
    distancia: '1,2 km',
    horario: '7pm',
    endereco: 'Calle 72 # 234',
    genero: 'Feminino',
    idade: '42 años',
    sintoma: 'dolor de cabeza'
  }

  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn" || authState.user.attributes.profile != "medico-especialista") {
        this.router.navigateByUrl("");
      }
    })
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

}
