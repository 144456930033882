import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { AdministradorService } from 'src/app/service/adm/adm.service';

@Component({
  selector: 'app-registro-de-usuarios',
  templateUrl: './registro-de-usuarios.component.html',
  styleUrls: ['./registro-de-usuarios.component.css']
})
export class RegistroDeUsuariosComponent implements OnInit {

  componente = 'APLICACION 1 # REGISTRO DE USUARIOS';
  user = {
    name: '',
    email: ''
  }
  auxID: any
  id: any;
  usuario: undefined;
  loading = false;
  loading1 = false;
  loading2 = false;
  medico = {
    name: '',
    email: '',
    role: '',
    department: '',
    button_01: false,
    button_02: false,
    button_03: false,
    button_04: false,
    button_05: false
  }

  displayedColumns: string[] = ['1', '#', 'APLICACION'];
  constructor(
    private amplifyService: AmplifyService,
    private servico: AdministradorService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      console.log(authState)
      if (authState.state != "signedIn" || authState.user.attributes.profile != 'administrator') {
        this.router.navigateByUrl("");
      }
    })
  }

  ngOnInit(): void {
  }
  /*Registra o usuario para receber o id do cognito */
  registraUsuario() {
    this.loading2 = true;
    const data = {
      name: this.user.name,
      email: this.user.email
    };
    if (data.name != '' && data.email != '') {
      this.servico.putUsuario(data)
        .subscribe(
          response => {
            this.loading2 = false;
            this.construirUser(response);
          },
          error => {
            this.loading2 = false;
            console.log(error);
          }
        );
    }
  }
  construirUser(response: any) {
    this.auxID = response.body;// pegando o valor da string e com isso pegar o valor do ID
    console.log(this.auxID);
    this.id = this.auxID.substring(23, 59)
    this.medico.name = this.user.name;
    this.medico.email = this.user.email;
  }
  getUsuarioID() {
    if (this.id != null) {
      this.loading1 = true;
    }
    this.servico.getAdmID(this.id).subscribe((usuario: any) => {
      this.construtorPaciente(usuario);
      this.loading1 = false;
    })
  }
  construtorPaciente(usuario: any) {
    this.medico.name = usuario.name,
      this.medico.email = usuario.email,
      this.medico.department = usuario.department,
      this.medico.role = usuario.role,
      this.medico.button_01 = usuario.wbutton_01 || false,
      this.medico.button_02 = usuario.wbutton_02 || false,
      this.medico.button_03 = usuario.wbutton_03 || false,
      this.medico.button_04 = usuario.wbutton_04 || false,
      this.medico.button_05 = usuario.wbutton_05 || false
  }
  registraUsuarioFinal() {
    if (this.id != null) {
      this.loading = true;

      const data = {
        id: this.id,
        name: this.medico.name,
        email: this.medico.email,
        role: this.medico.role,
        department: this.medico.department,
        button_01: this.medico.button_01,
        button_02: this.medico.button_02,
        button_03: this.medico.button_03,
        button_04: this.medico.button_04,
        button_05: this.medico.button_05,
      };
      this.servico.putUsuarioFinal(data)
        .subscribe(
          response => {
            console.log(response);
            console.log(data);
            setTimeout(function () { location.reload(); }, 0)
          },
          error => {
            console.log(error);
          }
        );
    }
  }
}
