import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdmService {


  private SERVER_URL_CADASTRO_MEDICO = 'https://wbwfcwsvi6.execute-api.us-east-2.amazonaws.com/prod'
  private SERVER_URL_PUT_DECICAO_ADM = 'https://no5p6xu2qb.execute-api.us-east-2.amazonaws.com/prod'
  private SERVER_URL_ZONEINFO = 'https://23wi9iquxl.execute-api.us-east-2.amazonaws.com/prod'

  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }

    /* USUARIO*/
  /* Salva os dados do medico no seu 1 cadastro */
  putMedicoParaAprovacao(cadastro: any) {
    return this.httpClient.put(`${this.SERVER_URL_CADASTRO_MEDICO}`, cadastro)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

      /*ADM */
  /* Altera os dados da ZoneInfo */
  putZoneInfo(cadastro: any) {
    return this.httpClient.put(`${this.SERVER_URL_ZONEINFO}`, cadastro)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  /*Buscar lista de medicos para aprovação*/
  getUsuarioALL(): Observable<any> {
    return this.httpClient.get<any>(`${this.SERVER_URL_CADASTRO_MEDICO}`)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  putAprovarReprovar(cadastro: any) {
    return this.httpClient.put(`${this.SERVER_URL_PUT_DECICAO_ADM}`, cadastro)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

   /*BUSCAR USUARIO PELO ID*/
   getUsuarioID(param: string): Observable<any> {
    return this.httpClient.get<any>(`${this.SERVER_URL_PUT_DECICAO_ADM}?id=` + param, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  delete(cadastro: any) {
    const corpo = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body:{
        datetime_request: cadastro,
      }
    }
    return this.httpClient.delete(`${this.SERVER_URL_CADASTRO_MEDICO}`, corpo)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  };
}
