<div class="container-fluid" *ngIf="loadingPNG == true" style="margin-top: 20%; margin-bottom: 20%;">
  <mat-spinner style="margin: auto;"></mat-spinner>
</div>
<div class="container-fluid bg-body py-5 my-5" *ngIf="resposta == false && loadingPNG == false">
  <br>
  <h1>Solicitar orientación</h1>
  <br>
  <form class="form">
    <h2 style="margin-bottom: -10px;">Síntoma principal</h2>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label></mat-label>
      <input matInput placeholder="" type="text" id="sintoma" name="sintoma" [(ngModel)]="user.symptom" value=""
        style="font-size: large;">
    </mat-form-field>
    <h2 style="margin-bottom: -10px;">Información adicional</h2>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label></mat-label>
      <textarea matInput placeholder="" rows="10" type="text" id="informacion" name="informacion"
        [(ngModel)]="user.additional_information" value="" style="resize: none; font-size: large;"></textarea>
    </mat-form-field>
    <div style="text-align: center;">
      <a mat-raised-button class="button-style btnChat" href="/principal" style="margin-right: 2%;"
        [disabled]="loadingBTN">Regresar</a>
      <button mat-raised-button class="btnChat" color="primary" type="submit" [class.spinner]="loadingBTN"
        [disabled]="loadingBTN" (click)="consultar()">Solicitar</button>
    </div>

  </form>
</div>

<div class="container-fluid bg-body py-5 my-5" *ngIf="resposta == true">

  <div class="orientacion-chat">
    <br>
    <h1>Causa</h1>
    <p>{{responseInfo.cause}}</p>

    <br>
    <h1>Tratamiento no Farmacológico</h1>
    <p>{{responseInfo.treatment}}</p>

    <br>
    <h1>Tratamiento Farmacológico</h1>
    <p>{{responseInfo.treatment_pharmacological}}</p>

    <br>
    <h1>Guia Medicamentosa</h1>
    <p>{{responseInfo.leaflet}}</p>

    <br>
    <h1>Recomendación Natural</h1>
    <p>{{responseInfo.natural}}</p>F
  </div>


  <div style="text-align: center;margin-top: 5%;">
    <!-- Button trigger modal avaliação -->
    <button type="button" class="btn btn-primary btnChat" data-bs-toggle="modal" data-bs-target="#Modal"
      style="margin-right: 3%;">Finzalizar</button>

    <!-- Button trigger modal chat -->
    <button type="button" class="btn btn-primary btnChat" data-bs-toggle="modal" data-bs-target="#ModalChat">
      Chat
    </button>
  </div>


  <!-- Modal avaliação-->
  <div class="modal fade" id="Modal" tabindex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content custom">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="ModalLabel" style="margin-left: 50px;">Cômo califica la orientacion en salud?
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div style="margin: auto;">
          <button type="button" class="btn btn-primary btnChat" style="margin-right:15px;"
            (click)="finalizar(true)">POS</button>
          <button type="button" class="btn btn-secondary btnChat" (click)="finalizar(false)">NEG</button>
        </div>
      </div>
    </div>
  </div>



  <!-- Modal chat-->
  <div class="modal fade" id="ModalChat" tabindex="1" aria-labelledby="ModalChatLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content chatGPT">
        <div class="modal-header">
          <h1 style="margin-left:46%; ">Chat</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="card-body msg_card_body">
            <div class="d-flex justify-content-end mb-4" *ngIf="chat_user.user_01!=undefined">
              <div class="msg_cotainer">
                <h3>{{chat_user.user_01}}</h3>
              </div>
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{urlink}}" class="rounded-circle user_img_msg">
              </div>
            </div>

            <div class="d-flex justify-content-start mb-4" *ngIf="chat_ai.ai_01!=undefined">
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{iaImg}}" class="rounded-circle user_img_msg">
              </div>
              <div class="msg_cotainer_send">
                <h3>{{chat_ai.ai_01}}</h3>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-4" *ngIf="chat_user.user_02!=undefined">
              <div class="msg_cotainer">
                <h3>{{chat_user.user_02}}</h3>
              </div>
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{urlink}}" class="rounded-circle user_img_msg">
              </div>
            </div>

            <div class="d-flex justify-content-start mb-4" *ngIf="chat_ai.ai_02!=undefined">
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{iaImg}}" class="rounded-circle user_img_msg">
              </div>
              <div class="msg_cotainer_send">
                <h3>{{chat_ai.ai_02}}</h3>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-4" *ngIf="chat_user.user_03!=undefined">
              <div class="msg_cotainer">
                <h3>{{chat_user.user_03}}</h3>
              </div>
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{urlink}}" class="rounded-circle user_img_msg">
              </div>
            </div>

            <div class="d-flex justify-content-start mb-4" *ngIf="chat_ai.ai_03!=undefined">
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{iaImg}}" class="rounded-circle user_img_msg">
              </div>
              <div class="msg_cotainer_send">
                <h3>{{chat_ai.ai_03}}</h3>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-4" *ngIf="chat_user.user_04!=undefined">
              <div class="msg_cotainer">
                <h3>{{chat_user.user_04}}</h3>
              </div>
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{urlink}}" class="rounded-circle user_img_msg">
              </div>
            </div>

            <div class="d-flex justify-content-start mb-4" *ngIf="chat_ai.ai_04!=undefined">
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{iaImg}}" class="rounded-circle user_img_msg">
              </div>
              <div class="msg_cotainer_send">
                <h3>{{chat_ai.ai_04}}</h3>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-4" *ngIf="chat_user.user_05!=undefined">
              <div class="msg_cotainer">
                <h3>{{chat_user.user_05}}</h3>
              </div>
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{urlink}}" class="rounded-circle user_img_msg">
              </div>
            </div>

            <div class="d-flex justify-content-start mb-4" *ngIf="chat_ai.ai_05!=undefined">
              <!--Imagem caso necessario-->
              <div class="img_cont_msg">
                <img src="{{iaImg}}" class="rounded-circle user_img_msg">
              </div>
              <div class="msg_cotainer_send">
                <h3>{{chat_ai.ai_05}}</h3>
              </div>
            </div>
          </div>

          <div class="digitando" *ngIf="digitando==true">
            <div class="sent_msg">
              <span class="time_date">digitando...</span>
            </div>
          </div>
        </div>
        <div style="overflow: scroll;"></div>

        <div class="card-footer py-3 border-0">
          <div class="modal-footer">
            <div class="d-flex flex-start w-100">
              <mat-form-field class="full-width" appearance="outline">
                <input matInput placeholder="" type="text" id="informacion" name="informacion"
                  [(ngModel)]="inputTemporario" value="" style="font-size: large;">
              </mat-form-field>
              <button mat-raised-button class="btnSend" type="button" color="primary" (click)="chatPergunta()">
                >
              </button>
            </div>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Modal"
              style="margin: auto;">Finzalizar</button>
            <!--O Tooltip tanto bootstrap quando angularM. nao funciona-->
            <button type="button" class="btn btn-primary" matTooltip="Em desenvolvimento" data-toggle="tooltip"
              data-placement="top" title="Em desenvolvimento" style="margin: auto;" disabled>Orientacion medica
              virtual</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
