<div class="container-fluid py-5 bg-body mt-5">
  <div class="container">
      <div class="row">
          <div class="row text-center mx-auto">
              <div class="col-md-2 mx-auto my-3">
                  <img class="img-fluid" src="https://res.cloudinary.com/juandacloud/image/upload/v1686788991/afoco-projects/tedi/TEDI_Letra_Azul_Completo-01_hqlcas.png" alt="" width="150">
              </div>
          </div>
          <div class="row text-center col-md-6 mx-auto">
              <p class="text-dark text-opacity-50">TEDIMEDICINA facilita priorizar tu salud con tecnología
                  inteligente. Ahorra tiempo valioso y obtén tranquilidad al aprovechar sus avanzadas
                  características de atención médica preventiva.</p>
          </div>
          <div class="row">
              <div class="col-xl-3 mx-auto text-center">
                  <a class="ms-3" href="https://twitter.com/tedimedicina" style="color: #2e5e88ff"><i
                          class="bi bi-twitter"></i></a>
                  <a class="ms-2" href="https://www.facebook.com/tedimedicinaorg" style="color: #2e5e88ff"><i
                          class="bi bi-facebook"></i></a>
                  <a class="ms-2" href="https://www.youtube.com/@tedimedicina" style="color: #2e5e88ff"><i
                          class="bi bi-youtube"></i></a>
              </div>
          </div>
      </div>
      <div class="row col-md-6 text-center mx-auto mt-5">
          <p class="text-dark text-opacity-50">© 2023 TEDIMEDICINA</p>
      </div>
  </div>
  </div>
