import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';

@Component({
  selector: 'app-orientacion',
  templateUrl: './orientacion.component.html',
  styleUrls: ['./orientacion.component.css']
})
export class OrientacionComponent implements OnInit {

  disabled = {
    primeiro: false,
    segundo: false,
    terceiro: false,
    quarto: false,
    quinto: false,
    sexto: false,
    setimo: false
  }

  endereco = {
    primeiro: '',
    segundo: '',
    terceiro: '',
    quarto: '',
    quinto: '',
    sexto: '',
    setimo: ''
  }

  horario = {
    inicial: {
      primeiro: '',
      segundo: '',
      terceiro: '',
      quarto: '',
      quinto: '',
      sexto: '',
      setimo: ''
    },
    final: {
      primeiro: '',
      segundo: '',
      terceiro: '',
      quarto: '',
      quinto: '',
      sexto: '',
      setimo: ''
    },
  }

  dia = new Date().getDate();
  mes = new Date().getMonth();
  ano = new Date().getFullYear();
  date = {
    primeiro: new Date(),
    segundo: new Date(this.ano, this.mes, this.dia + 1),
    terceiro: new Date(this.ano, this.mes, this.dia + 2),
    quarto: new Date(this.ano, this.mes, this.dia + 3),
    quinto: new Date(this.ano, this.mes, this.dia + 4),
    sexto: new Date(this.ano, this.mes, this.dia + 5),
    setimo: new Date(this.ano, this.mes, this.dia + 6)
  }
  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn" || authState.user.attributes.profile != "medico-geral") {
        this.router.navigateByUrl("");
      }
    })
  }

  ngOnInit(): void {
    
  }
  
  toPrincipal() {
    this.router.navigate(['principalG']);
  }

}
