<div class="ajuste"></div>
<mat-card class="card">

    <mat-card-title>Orientacion</mat-card-title>


    <mat-card class="card-interno">
        <mat-checkbox [(ngModel)]="disabled.primeiro" style="float: left;"></mat-checkbox>
        <h3>{{date.primeiro | date: 'fullDate'}}</h3>
        <div class="card-tiempo">
            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Desde</mat-label>
                <mat-select matInput [(ngModel)]="horario.inicial.primeiro" [disabled]="!disabled.primeiro">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Hasta</mat-label>
                <mat-select matInput [(ngModel)]="horario.final.primeiro" [disabled]="!disabled.primeiro">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>
            <br style="margin-bottom: 2%;">
            <mat-form-field appearance="fill" class="endereco">
                <mat-label>Localidade</mat-label>
                <input matInput type="text" id="endereco" name="endereco" [(ngModel)]="endereco.primeiro"
                    [disabled]="!disabled.primeiro">
            </mat-form-field>
        </div>
    </mat-card>

    <mat-card class="card-interno">
        <mat-checkbox [(ngModel)]="disabled.segundo" style="float: left;"></mat-checkbox>
        <h3>{{date.segundo | date: 'fullDate'}}</h3>
        <div class="card-tiempo">
            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Desde</mat-label>
                <mat-select matInput [(ngModel)]="horario.inicial.segundo" [disabled]="!disabled.segundo">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Hasta</mat-label>
                <mat-select matInput [(ngModel)]="horario.final.segundo" [disabled]="!disabled.segundo">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>
            <br style="margin-bottom: 2%;">
            <mat-form-field appearance="fill" class="endereco">
                <mat-label>Localidade</mat-label>
                <input matInput type="text" id="endereco" name="endereco" [(ngModel)]="endereco.segundo"
                    [disabled]="!disabled.segundo">
            </mat-form-field>

        </div>
    </mat-card>

    <mat-card class="card-interno">
        <mat-checkbox [(ngModel)]="disabled.terceiro" style="float: left;"></mat-checkbox>
        <h3>{{date.terceiro | date: 'fullDate'}}</h3>
        <div class="card-tiempo">
            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Desde</mat-label>
                <mat-select matInput [(ngModel)]="horario.inicial.terceiro" [disabled]="!disabled.terceiro">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Hasta</mat-label>
                <mat-select matInput [(ngModel)]="horario.final.terceiro" [disabled]="!disabled.terceiro">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>
            <br style="margin-bottom: 2%;">
            <mat-form-field appearance="fill" class="endereco">
                <mat-label>Localidade</mat-label>
                <input matInput type="text" id="endereco" name="endereco" [(ngModel)]="endereco.terceiro"
                    [disabled]="!disabled.terceiro">
            </mat-form-field>

        </div>
    </mat-card>

    <mat-card class="card-interno">
        <mat-checkbox [(ngModel)]="disabled.quarto" style="float: left;"></mat-checkbox>
        <h3>{{date.quarto | date: 'fullDate'}}</h3>
        <div class="card-tiempo">
            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Desde</mat-label>
                <mat-select matInput [(ngModel)]="horario.inicial.quarto" [disabled]="!disabled.quarto">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Hasta</mat-label>
                <mat-select matInput [(ngModel)]="horario.final.quarto" [disabled]="!disabled.quarto">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>
            <br style="margin-bottom: 2%;">
            <mat-form-field appearance="fill" class="endereco">
                <mat-label>Localidade</mat-label>
                <input matInput type="text" id="endereco" name="endereco" [(ngModel)]="endereco.quarto"
                    [disabled]="!disabled.quarto">
            </mat-form-field>

        </div>
    </mat-card>

    <mat-card class="card-interno">
        <mat-checkbox [(ngModel)]="disabled.quinto" style="float: left;"></mat-checkbox>
        <h3>{{date.quinto | date: 'fullDate'}}</h3>
        <div class="card-tiempo">
            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Desde</mat-label>
                <mat-select matInput [(ngModel)]="horario.inicial.quinto" [disabled]="!disabled.quinto">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Hasta</mat-label>
                <mat-select matInput [(ngModel)]="horario.final.quinto" [disabled]="!disabled.quinto">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>
            <br style="margin-bottom: 2%;">
            <mat-form-field appearance="fill" class="endereco">
                <mat-label>Localidade</mat-label>
                <input matInput type="text" id="endereco" name="endereco" [(ngModel)]="endereco.quinto"
                    [disabled]="!disabled.quinto">
            </mat-form-field>

        </div>
    </mat-card>

    <mat-card class="card-interno">
        <mat-checkbox [(ngModel)]="disabled.sexto" style="float: left;"></mat-checkbox>
        <h3>{{date.sexto | date: 'fullDate'}}</h3>
        <div class="card-tiempo">
            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Desde</mat-label>
                <mat-select matInput [(ngModel)]="horario.inicial.sexto" [disabled]="!disabled.sexto">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Hasta</mat-label>
                <mat-select matInput [(ngModel)]="horario.final.sexto" [disabled]="!disabled.sexto">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>
            <br style="margin-bottom: 2%;">
            <mat-form-field appearance="fill" class="endereco">
                <mat-label>Localidade</mat-label>
                <input matInput type="text" id="endereco" name="endereco" [(ngModel)]="endereco.sexto"
                    [disabled]="!disabled.sexto">
            </mat-form-field>

        </div>

    </mat-card>

    <mat-card class="card-interno">
        <mat-checkbox [(ngModel)]="disabled.setimo" style="float: left;"></mat-checkbox>
        <h3>{{date.setimo | date: 'fullDate'}}</h3>
        <div class="card-tiempo">
            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Desde</mat-label>
                <mat-select matInput [(ngModel)]="horario.inicial.setimo" [disabled]="!disabled.setimo">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Hasta</mat-label>
                <mat-select matInput [(ngModel)]="horario.final.setimo" [disabled]="!disabled.setimo">
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>
            <br style="margin-bottom: 2%;">
            <mat-form-field appearance="fill" class="endereco">
                <mat-label>Localidade</mat-label>
                <input matInput type="text" id="endereco" name="endereco" [(ngModel)]="endereco.setimo"
                    [disabled]="!disabled.setimo">
            </mat-form-field>
        </div>
    </mat-card>
    <button mat-raised-button color="accent" style="margin: 2%" (click)="toPrincipal()"><mat-icon>reply</mat-icon>Voltar</button>
    <button mat-raised-button color="primary" style="margin: 2%"><mat-icon>done</mat-icon>Atualizar</button>

</mat-card>