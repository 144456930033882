import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { AdmService } from 'src/app/service/adm2/adm.service';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { UsuarioADM } from 'src/app/model/modelo-usuario-adm';
import { AdministradorService } from 'src/app/service/adm/adm.service';

@Component({
  selector: 'app-aprovar-medico',
  templateUrl: './aprovar-medico.component.html',
  styleUrls: ['./aprovar-medico.component.css']
})
export class AprovarMedicoComponent implements OnInit {

  checked = false;
  comentario = '';
  controle = false;
  componente = 'APLICACION 2 # SOLICITUDE DE INGRESO';
  controle_delete: boolean = false;
  controle_cadastro: boolean = false;
  controle_zoneInfo: boolean = false;
  controle_medico: boolean = false;
  loading = false;
  loadingA = false;
  carregando = true;
  adm = {
    name: '',
  }
  id: any;

  usuario = {
    avatar: 0,
    nationality: '',
    document_type: '',
    datetime_request: '',
    condition: '',
    address: '',
    document_number: '',
    email: '',
    interest_specialty: '',
    name: '',
    gender: '',
    interest_group: '',
    city: '',
    rethus: '',
    role: '',
    birth: '',
    id: '',
    phone: '',
    attendants_name: ''
  }

  displayedColumns: string[] = ['status', 'solicitado', 'respondido', 'oficinista'];
  dataSource: any;

  constructor(
    private amplifyService: AmplifyService,
    private servico: AdmService,
    private servicoCadastro: CadastroService,
    private servicoADM: AdministradorService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      this.id = authState.user.username;
      if (authState.state != "signedIn" || authState.user.attributes.profile != 'administrator') {
        this.router.navigateByUrl("");
      }
    })
  }

  ngOnInit(): void {
    if (this.id == null) {
      location.reload()
    }
    this.getADM();
    this.getUsuarioALL();
  }

  reload(data:any) {
    if (this.controle_cadastro == true && this.controle_medico == true && this.controle_zoneInfo == true) {
      this.deletar(data.datetime_request);
    }
  }

  getADM() {
    this.servicoADM.getAdmID(this.id).subscribe((usuario: any) => {
      this.construtorADM(usuario);
    })
  }

  construtorADM(usuario: any) {
    this.adm.name = usuario.name;
  }

  getUsuarioALL() {

    this.servico.getUsuarioALL().subscribe((usuario: any) => {
      console.log(usuario)
      if (usuario.length != 0) {
        this.construtorUsuario(usuario[0]);
        this.carregando = false
      } else {
        this.carregando = false
      }
    })
  }

  construtorUsuario(usuario: any) {
    this.usuario.avatar = usuario.avatar,
      this.usuario.nationality = usuario.nationality,
      this.usuario.document_type = usuario.document_type,
      this.usuario.datetime_request = usuario.datetime_request,
      this.usuario.condition = usuario.condition,
      this.usuario.address = usuario.address,
      this.usuario.document_number = usuario.document_number,
      this.usuario.email = usuario.email,
      this.usuario.interest_specialty = usuario.interest_specialty,
      this.usuario.name = usuario.name,
      this.usuario.gender = usuario.gender,
      this.usuario.interest_group = usuario.interest_group,
      this.usuario.city = usuario.city,
      this.usuario.rethus = usuario.rethus,
      this.usuario.role = usuario.role,
      this.usuario.birth = usuario.birth,
      this.usuario.id = usuario.id,
      this.usuario.phone = usuario.phone,
      this.getUsuarioID();
  }

  getUsuarioID() {
    this.servico.getUsuarioID(this.usuario.id).subscribe((usuario: any) => {
      this.dataSource = usuario;
    })
  }

  aceitar() {
    this.checked = false;
    this.loadingA = true;
    const data = {
      avatar: this.usuario.avatar,
      id: this.usuario.id,
      datetime_request: this.usuario.datetime_request, // ver como ira receber
      role: this.usuario.role,
      name: this.usuario.name,
      document_type: this.usuario.document_type,
      document_number: this.usuario.document_number,
      gender: this.usuario.gender,
      birth: this.usuario.birth,
      email: this.usuario.email,
      nationality: this.usuario.nationality,
      city: this.usuario.city,
      address: this.usuario.address,
      phone: this.usuario.phone,
      rethus: this.usuario.rethus,
      interest_group: this.usuario.interest_group,
      interest_specialty: this.usuario.interest_specialty,
      attendants_name: this.adm.name,
      comment: '',
      status: 'aceptado'
    };
    this.servico.putAprovarReprovar(data)
      .subscribe(
        response => {
          console.log(response);
          this.zoneInfo('welcome')
          if (this.usuario.role == 'medico-geral') {
            this.putMedicoGeral(data);
          } else if (this.usuario.role == 'medico-especialista') {
            this.putMedicoEspecialista(data);
          }
          this.controle_cadastro = true;
          this.reload(data);
        },
        error => {
          this.loadingA = false;
          console.log(error);
        }
      );
  }

  negar() {
    this.controle = true;
    this.loading = true;
    const data = {
      avatar: this.usuario.avatar,
      id: this.usuario.id,
      datetime_request: this.usuario.datetime_request, // ver como ira receber
      role: this.usuario.role,
      name: this.usuario.name,
      document_type: this.usuario.document_type,
      document_number: this.usuario.document_number,
      gender: this.usuario.gender,
      birth: this.usuario.birth,
      email: this.usuario.email,
      nationality: this.usuario.nationality,
      city: this.usuario.city,
      address: this.usuario.address,
      phone: this.usuario.phone,
      rethus: this.usuario.rethus,
      interest_group: this.usuario.interest_group,
      interest_specialty: this.usuario.interest_specialty,
      attendants_name: this.adm.name,
      comment: this.comentario,
      status: 'negado'
    };
    this.servico.putAprovarReprovar(data)
      .subscribe(
        response => {

          console.log(response);
          this.zoneInfo('denied')
          if (this.usuario.role == 'medico-geral') {
            this.putMedicoGeral(data);
          } else if (this.usuario.role == 'medico-especialista') {
            this.putMedicoEspecialista(data);
          }
          this.controle_cadastro = true;
          this.reload(data);
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  deletar(data: any) {
    this.servico.delete(data).subscribe(
      response => {
        console.log(response);
        //setTimeout(function () { location.reload(); }, 1000)
      },
      error => {
        console.log(error);
      }
    )
  }

  putMedicoGeral(data: any) {
    this.servicoCadastro.putMedicoGeral(data)
      .subscribe(
        response => {
          console.log(response);
          this.controle_medico = true;
          this.reload(data);
        },
        error => {
          console.log(error);
        }
      );
  }

  putMedicoEspecialista(data: any) {
    this.servicoCadastro.putMedicoEspecialista(data)
      .subscribe(
        response => {
          console.log(response);
          this.controle_medico = true;
          this.reload(data);
        },
        error => {
          console.log(error);
        }
      );
  }

  zoneInfo(status: any) {
    const data = {
      id: this.usuario.id,
      attritutename: 'zoneinfo',
      attritutevalue: status
    }
    this.servico.putZoneInfo(data)
      .subscribe(
        response => {
          console.log(response);
          this.controle_zoneInfo = true;
        },
        error => {
          console.log(error);
        }
      );
  }

  visualizar_status(dados: any) {
    this.dialog.open(Visializar_Usuario_Status, {
      width: '40%',
      data: dados
    });
  }
}

@Component({
  selector: 'dialog-visualizar-dados',
  templateUrl: 'dialog-visualizar-dados.html',
  styleUrls: ['./aprovar-medico.component.css']

})

export class Visializar_Usuario_Status {

  constructor(
    public dialogRef: MatDialogRef<Visializar_Usuario_Status>,
    @Inject(MAT_DIALOG_DATA) public usuario: UsuarioADM) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
