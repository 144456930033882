<mat-grid-list cols="1" rowHeight="50px" *ngIf="innerWidth>600" style="background-color:rgb(242, 242, 242)">
    <div style="margin-top: 60px;"></div>
    <mat-grid-tile [rowspan]="10">
        <p class="titulo-img">Cómo operamos</p>
        <img src="../../../assets/backgrounds/stelarsky.webp" style="width: 100%; height: auto; display: block; margin-top: 250px; margin-left: auto; margin-right: auto ; border-radius: 0px; pointer-events: none;" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2">
        <p class="texto-out-img">Cambiamos la <b>forma</b> en que las personas se ven a sí mismos y <b>maximizamos</b> <br>
             su potencial con el apoyo de la <b>tecnología</b> para generar conexiones que <br>
              redunden en valor agregado para quienes prestan como para quienes <br> reciben nuestro <b>servicio</b> de orientación médica.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="2" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="14">
        <img src="../../../assets/backgrounds/personcamera.webp" style="position: absolute; border: 10px solid rgb(255, 255, 255); top:50px; left:25%; width:470px; height: 426px;  z-index: 0;"> 
        <p class="texto-img" style="top:0px; left:57.5%;">
            Nuestra plataforma tecnológica <br> permite la <b>conexión</b> de personas con <br> necesidades de atención en salud con <br> personas dispuestas a suplir dichas <br>
             necesidades de forma <b>eficaz</b> y <br> oportuna propendiendo siempre por <br> satisfacer los requerimientos de los <br> actores involucrados en la <b>prestación</b> <br> del servicio.
             <br><br>
             Licitamos para generar contenido <br> audiovisual <b>original</b> socialmente <br> responsable.
             <br><br>
             <b>Hacemos Branded</b> Content con el <br> corazón para promover los valores de <br> las empresas que quieren dejar huella <br> en nuestro país.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>



</mat-grid-list>

<!-- RowHeight arruma cada grid -->
<mat-grid-list cols="1" rowHeight="25px"*ngIf="innerWidth<600">
    <div style="margin-top: 30px;"></div>
    <mat-grid-tile [rowspan]="6">
        <img src="../../../assets/backgrounds/stelarsky.webp" style="width: 100%; height: auto; display: block; margin-top:100px; margin-left: auto; margin-right: auto ; border-radius: 0px; pointer-events: none;" >
        <p class="titulo-img" style="font-size: 40px; width: 100%; top:100px; right:50px;">Cómo operamos</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="6">
        <p class="texto-out-img" style="font-size: 16px; width: 100%; top:auto; left:auto; margin-left: 5%;">Cambiamos la <b>forma</b> en que las personas se ven a sí mismos y <b>maximizamos</b>
             su potencial con el apoyo de la <b>tecnología</b> para generar conexiones que
              redunden en valor agregado para quienes prestan como para quienes reciben nuestro <b>servicio</b> de orientación médica.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>

    
    <mat-grid-tile [rowspan]="16" >
        <img src="../../../assets/backgrounds/personcamera.webp" style="position: absolute; border: 0px solid rgb(255, 255, 255); top:00px; padding-right: 5px; width:470px; height: 426px;  z-index: 0;"> 
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="18">
        <p class="texto-img" style="top:0px; left:70px; font-size: 16px;">
            Nuestra plataforma tecnológica <br> permite la <b>conexión</b> de personas con <br> necesidades de atención en salud con <br> personas dispuestas a suplir dichas <br>
             necesidades de forma <b>eficaz</b> y <br> oportuna propendiendo siempre por <br> satisfacer los requerimientos de los <br> actores involucrados en la <b>prestación</b> <br> del servicio.
             <br><br>
             Licitamos para generar contenido <br> audiovisual <b>original</b> socialmente <br> responsable.
             <br><br>
             <b>Hacemos Branded</b> Content con el <br> corazón para promover los valores de <br> las empresas que quieren dejar huella <br> en nuestro país.</p>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="1" >
    </mat-grid-tile>



</mat-grid-list>
