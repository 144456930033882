import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { Mg } from 'src/app/model/modelo-mg';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { ImgService } from 'src/app/service/img/img.service';

@Component({
  selector: 'app-historia-clinica',
  templateUrl: './historia-clinica.component.html',
  styleUrls: ['./historia-clinica.component.css']
})
export class HistoriaClinicaComponent implements OnInit {

  urlink: any = "../../../assets/avatar.png";
  medico = {
    img: '',
    id: '',
    nome: '',
    sexo: '',
    idade: 0,
    nacionalidade: '',
    especialidade: ''
  }
  /*Botao de controle Antecedentes*/
  btn = {
    familiares: true,
    perinatales: true,
    desarollo: true,
    patologico: true,
    quirurgico: true,
    farmacologico: true,
    alergico: 0,
    alergico_farmocologico: true,
    alergico_alimentario: true,
    alergico_animales: true,
    alergico_latex: true,
    transfusiones: true,
    habitos: true
  }
  /*Botao de controle de Revision por Sistema*/
  btn2={
    piel: true,
    neurosensorial: true,
    cardiovascular: true,
    respiratorio: true,
    gastrointestinal: true,
    genitourinario: true,
    musculo_esqueletico: true,
    endocrino: true,
    mental: true,
    nevioso: true
  }
  /*Botao de controle de Examen Fisico*/
  btn3 = {
    piel: true,
    cabeza: true,
    cuello: true,
    torax: true,
    abdomen: true,
    genitourinario: true,
    esqueletico: true,
    neurologico: true,
    mental: true
  }
  cabecalho = 'Motivo de Consulta';
  controleBotao = 0;
  motivo_de_consulta = '';
  enfermedad_actual = '';
  
  btn_alergico_Farmacologia: number = 0;
  btn_alergico_Alimentarios: number = 0;
  btn_alergico_Animales: number = 0;
  btn_alergico_Latex: number = 0;

  constructor(
    private servico: CadastroService,
    private imgService: ImgService,
    private amplifyService: AmplifyService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      this.medico.especialidade = authState.user.attributes.profile;
      if (authState.state != "signedIn" || authState.user.attributes.profile != "medico-geral") {
        this.router.navigateByUrl("");
      }
      this.medico.id = authState.user.username;// retornar o ID do usuario
    })
  }

  ngOnInit(): void {
    this.recuperaIMG();
    this.getMedicoGeral();
    
  }

  recuperaIMG() {
    this.imgService.getIMG(this.medico.id).subscribe((img: any) => {
      if (!img.errorMessage) {
        this.medico.img = `data:image/jpeg;base64,${img}`;
      } else {

      }
    });
  }

  getMedicoGeral() {
    const hoje = new Date()
    this.servico.getMedicoGeral(this.medico.id).subscribe((mg: any) => {
      this.medico.nome = mg.name;
      this.medico.sexo = mg.gender;
      this.medico.nacionalidade = mg.nationality;

      let ano = parseInt(mg.birth[0] + mg.birth[1] + mg.birth[2] + mg.birth[3])
      let mes = parseInt(mg.birth[5] + mg.birth[6])
      let dia = parseInt(mg.birth[7] + mg.birth[8])

      this.medico.idade = hoje.getFullYear() - ano;
      const aux = hoje.getMonth() - mes;
      if (aux < 0 || (aux === 0 && hoje.getDate() < dia)) {
        this.medico.idade--;
      }

    })
  }
  onLinkClick(event: MatTabChangeEvent) {
    this.controleBotao = event.index;
    if (event.index == 0) {
      this.cabecalho = 'Motivo de Consulta';
    } else if (event.index == 1) {
      this.cabecalho = 'Enfermedad Actual';
    } else if (event.index == 2) {
      this.cabecalho = 'Antecedentes';
    } else if (event.index == 3) {
      this.cabecalho = 'Revision por Sistemas';
    } else if (event.index == 4) {
      this.cabecalho = 'Examen Fisico';
    } else if (event.index == 5) {
      this.cabecalho = 'Analisis y Plan';
    }
  }
  autoResize() {
    let objTextArea: any = document.getElementById('txtTextArea');
    if (objTextArea.scrollHeight > objTextArea.offsetHeight) objTextArea.rows += 1
    else if (objTextArea.scrollHeight < objTextArea.offsetHeight) {
      objTextArea.rows -= 1;
    }
  }
  /*Controle dos botoes de ANtecedentes*/
  add_Alergico_Farmacologia(){
    this.btn_alergico_Farmacologia ++
  }
  add_Alergico_Alimentarios(){
    this.btn_alergico_Alimentarios ++
  }
  add_Alergico_Animales(){
    this.btn_alergico_Animales ++
  }
  add_Alergico_Latex(){
    this.btn_alergico_Latex ++
  }
  familiares() {
    if (this.btn.familiares)
      this.btn.familiares = false;
    else
      this.btn.familiares = true;
  }
  perinatales() {
    if (this.btn.perinatales)
      this.btn.perinatales = false;
    else
      this.btn.perinatales = true;
  }
  desarollo() {
    if (this.btn.desarollo)
      this.btn.desarollo = false;
    else
      this.btn.desarollo = true
  }
  patologico() {
    if (this.btn.patologico)
      this.btn.patologico = false;
    else
      this.btn.patologico = true;
  }
  quirurgico() {
    if (this.btn.quirurgico)
      this.btn.quirurgico = false;
    else
      this.btn.quirurgico = true;
  }
  farmacologico() {
    if (this.btn.farmacologico)
      this.btn.farmacologico = false;
    else
      this.btn.farmacologico = true
  }
  alergico() {
    if (this.btn.alergico == 0)
      this.btn.alergico = 1
    else
      this.btn.alergico = 0
  }
  alergico_farmocologico() {
    if (this.btn.alergico_farmocologico)
      this.btn.alergico_farmocologico = false;
    else
      this.btn.alergico_farmocologico = true;
  }
  alergico_alimentario() {
    if (this.btn.alergico_alimentario)
      this.btn.alergico_alimentario = false;
    else
      this.btn.alergico_alimentario = true;
  }
  alergico_animales() {
    if (this.btn.alergico_animales)
      this.btn.alergico_animales = false;
    else
      this.btn.alergico_animales = true;
  }
  alergico_latex() {
    if (this.btn.alergico_latex)
      this.btn.alergico_latex = false;
    else
      this.btn.alergico_latex = true;
  }
  transfusiones() {
    if (this.btn.transfusiones)
      this.btn.transfusiones = false;
    else
      this.btn.transfusiones = true;
  }
  habitos() {
    if (this.btn.habitos)
      this.btn.habitos = false;
    else
      this.btn.habitos = true;
  }
  /*Controle dos botoes de ANtecedentes*/
  /*Controle dos botoes de Revision por Sistemas*/
  pielYfaneras() {
    if (this.btn2.piel)
      this.btn2.piel = false;
    else
      this.btn2.piel = true;
  }
  sistemaNeurosensorial() {
    if (this.btn2.neurosensorial)
      this.btn2.neurosensorial = false;
    else
      this.btn2.neurosensorial = true;
  }
  sistemaCardiovascular() {
    if (this.btn2.cardiovascular)
      this.btn2.cardiovascular = false;
    else
      this.btn2.cardiovascular = true;
  }
  sistemaRespiratorio() {
    if (this.btn2.respiratorio)
      this.btn2.respiratorio = false;
    else
      this.btn2.respiratorio = true;
  }
  sistemaGastrointestinal() {
    if (this.btn2.gastrointestinal)
      this.btn2.gastrointestinal = false;
    else
      this.btn2.gastrointestinal = true;
  }
  sistemaGenitourinario() {
    if (this.btn2.genitourinario)
      this.btn2.genitourinario = false;
    else
      this.btn2.genitourinario = true;
  }
  sistemaMusculoEsqueletico() {
    if (this.btn2.musculo_esqueletico)
      this.btn2.musculo_esqueletico = false;
    else
      this.btn2.musculo_esqueletico = true;
  }
  sistemaEndocrino() {
    if (this.btn2.endocrino)
      this.btn2.endocrino = false;
    else
      this.btn2.endocrino = true;
  }
  sistemaMental() {
    if (this.btn2.mental)
      this.btn2.mental= false;
    else
      this.btn2.mental = true;
  }
  sistemaNevioso() {
    if (this.btn2.nevioso)
      this.btn2.nevioso = false;
    else
      this.btn2.nevioso = true;
  }
  /*Controle dos botoes de Revision por Sistemas*/
  /*Controle dos Examen Fisico*/
  piel() {
    if (this.btn3.piel)
      this.btn3.piel = false;
    else
      this.btn3.piel = true;
  }
  cabeza() {
    if (this.btn3.cabeza)
      this.btn3.cabeza = false;
    else
      this.btn3.cabeza = true;
  }
  cuello() {
    if (this.btn3.cuello)
      this.btn3.cuello = false;
    else
      this.btn3.cuello = true;
  }
  torax() {
    if (this.btn3.torax)
      this.btn3.torax = false;
    else
      this.btn3.torax = true;
  }
  abdomen() {
    if (this.btn3.abdomen)
      this.btn3.abdomen = false;
    else
      this.btn3.abdomen = true;
  }
  genitourinario() {
    if (this.btn3.genitourinario)
      this.btn3.genitourinario = false;
    else
      this.btn3.genitourinario = true;
  }
  musculoEsqueletico() {
    if (this.btn3.esqueletico)
      this.btn3.esqueletico = false;
    else
      this.btn3.esqueletico = true;
  }
  examenNeurologico() {
    if (this.btn3.neurologico)
      this.btn3.neurologico = false;
    else
      this.btn3.neurologico = true;
  }
  examenMental() {
    if (this.btn3.mental)
      this.btn3.mental = false;
    else
      this.btn3.mental = true;
  }
}
