import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';

@Component({
  selector: 'app-sala-espera',
  templateUrl: './sala-espera.component.html',
  styleUrls: ['./sala-espera.component.css']
})
export class SalaEsperaComponent implements OnInit {

  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
//      console.log(authState)
      if (authState.state != "signedIn" && (authState.user.attributes.profile != 'medico-especialista' || authState.user.attributes.profile != 'medico-geral' )) {
        this.router.navigateByUrl("");
      }
    })
   }


  ngOnInit(): void {
  }

}
