import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { AdmService } from 'src/app/service/adm2/adm.service';
import { Auth } from 'aws-amplify';
@Component({
  selector: 'app-sala-de-confirmacao-medica',
  templateUrl: './sala-de-confirmacao-medica.component.html',
  styleUrls: ['./sala-de-confirmacao-medica.component.css']
})
export class SalaDeConfirmacaoMedicaComponent implements OnInit {

  id: String = '';
  medico = '';

  constructor(
    private amplifyService: AmplifyService,
    private servico: AdmService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      this.id = authState.user.username;
      this.medico = authState.user.attributes.profile;
      console.log(authState)
      if (authState.state != "signedIn" && (authState.user.attributes.profile != 'medico-especialista' || authState.user.attributes.profile != 'medico-geral')) {
        this.router.navigateByUrl("");
      }
    })
  }

  ngOnInit(): void {
  }

  confirmar() {
    const data = {
      id: this.id,
      attritutename: 'zoneinfo',
      attritutevalue: 'accepted'
    }
    this.servico.putZoneInfo(data)
      .subscribe(
        response => {
          console.log(response);
          //this.deslogar(); // forca o delogamento para que o usuario logue e atualize a situacao dele
          if (this.medico == 'medico-especialista') {
            this.router.navigateByUrl('principalE');
          } else if (this.medico == 'medico-geral') {
            this.router.navigateByUrl('principalG');
          }
        },
        error => {
          console.log(error);
        }
      );
  }
  /*Recurso caso nao consiga mudar o status na aws*/
  async deslogar() {
    this.router.navigate([''])
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

}
