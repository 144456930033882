<div style="margin-top: 60px;"></div>

<div style="padding-top: 25px; padding-bottom: 15px; border: 0px solid red;
            border-bottom-width: 1px; border-bottom-color: rgb(200, 200, 200) ; background-color:rgb(255, 255, 255);">
    <p class="title">Registrar</p>
</div>
<mat-grid-list [gutterSize]="'30px'"
    style="padding-top: 0px; padding-bottom: 100px; background-color:rgb(240, 240, 240);" cols="1" rowHeight="50px"
    *ngIf="innerWidth>600">

    <mat-grid-tile [rowspan]="6" style="background-color:rgb(242, 242, 242); margin-top: 00px;">
        <div>
            <img src="../../../assets/backgrounds/paciente.webp" class="avatar" style="right: 58%; top: 80px;">
            <mat-card class="card" style="left: 5%;">
                <mat-card-header>
                    <mat-card-title
                        style="position:absolute ; font-size: 20pt; font-family: 'Heeboo'; top:25px; right: 400px;">
                        Paciente</mat-card-title>
                    <mat-card-subtitle
                        style="position:absolute ; font-size: 12pt; font-family: 'Heeboo'; top: 60px ; right: 252.5px;">
                        Orientación en salud es lo que quiero</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p
                        style="font-family: 'Heeboo' ;font-size: 16px; top: 90px; margin-left: 225px; position: relative;">
                        Desde nuestra plataforma tecnológica podrás acceder a orientaciones en
                        salud por parte de médicos generales y la opción de interconsultar
                        especialistas TEDI en un mismo servicio los cuales están dispuestos
                        a ayudarte porque se preocupan por ti.
                    </p>
                    <button mat-button *ngIf="registro==false" class="register-btn" (click)="toRegisterP()"
                        style="top: 110px; margin-left: 220px; position: relative;">Comenzar</button>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="6" style="background-color:rgb(242, 242, 242); margin-top: 00px;">
        <div>
            <img src="../../../assets/backgrounds/medicoimg.png" class="avatar" style="left: 55%; top: 80px;">
            <mat-card class="card" style="right: 12.5%;">
                <mat-card-header>
                    <mat-card-title
                        style="position:absolute ; font-size: 20pt; font-family: 'Heeboo'; top:25px; left: 20px;">Medico
                        General</mat-card-title>
                    <mat-card-subtitle
                        style="position:absolute ; font-size: 12pt; font-family: 'Heeboo'; top: 60px ; left: 20px;">
                        Atender es mi motivación</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p style="font-family: 'Heeboo' ;font-size: 16px; top: 90px; margin-left: 5px; position: relative;">
                        Desde nuestra plataforma tecnológica podrás acceder a las solicitudes de <br> servicios
                        demandados por pacientes que necesitan de ti, porque tú les interesas. <br>
                        Te brindamos la oportunidad de empoderarte de tu conocimiento y recibir el <br> pago por cada
                        servicio prestado, así como poder flexibilizar tu tiempo según <br> tus expectativas.
                    </p>
                    <button mat-button *ngIf="registro==false" class="register-btn" (click)="toRegisterMG()"
                        style="top: 100px; margin-left: 400px; position: relative;">Comenzar</button>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="6" style="background-color:rgb(242, 242, 242); margin-top: 00px;">
        <div>
            <img src="../../../assets/backgrounds/especialistaimg.webp" class="avatar" style="right: 58%; top: 80px;">
            <mat-card class="card" style="left: 5%;">
                <mat-card-header>
                    <mat-card-title
                        style="position:absolute ; font-size: 20pt; font-family: 'Heeboo'; top:25px; right: 280px;">
                        Médico Especialista</mat-card-title>
                    <mat-card-subtitle
                        style="position:absolute ; font-size: 12pt; font-family: 'Heeboo'; top: 60px ; right: 295px;">
                        Calidad especializada es mi fin</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p
                        style="font-family: 'Heeboo' ;font-size: 16px; top: 90px; margin-left: 225px; position: relative;">
                        Desde nuestra plataforma tecnológica podrás acceder a las solicitudes de servicio realizadas por
                        los pacientes a través de los médicos generales, al momento de la orientación en salud.
                        Te brindamos la oportunidad de empoderarte de tu conocimiento y recibir el pago por cada
                        servicio prestado, así como poder flexibilizar tu tiempo según tus expectativas.
                    </p>
                    <button mat-button *ngIf="registro==false" class="register-btn" (click)="toRegisterME()"
                        style="top: 110px; margin-left: 220px; position: relative;">Comenzar</button>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>

    <mat-grid-tile [rowspan]="6" style="background-color:rgb(242, 242, 242); margin-top: 00px;">
        <div>
            <img src="../../../assets/backgrounds/artista.jpeg" class="avatar" style="left: 55%; top: 80px;">
            <mat-card class="card" style="right: 12.5%;">
                <mat-card-header>
                    <mat-card-title
                        style="position:absolute ; font-size: 20pt; font-family: 'Heeboo'; top:25px; left: 20px;">Artista</mat-card-title>
                    <mat-card-subtitle
                        style="position:absolute ; font-size: 12pt; font-family: 'Heeboo'; top: 60px ; left: 20px;">
                        Mi arte es mi mejor retrato</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p style="font-family: 'Heeboo' ;font-size: 16px; top: 90px; margin-left: 5px; position: relative;">
                        Te preguntarás qué relación existe entre un artista y una plataforma <br> 
                        tecnológica en salud, pues déjanos decirte que tiene todo que ver, <br> 
                        toda vez que estamos haciendo medicina enmarcada en arte y queremos que <br>
                        el mundo lo sepa.
                    </p>
                    <button mat-button *ngIf="registro==false" class="register-btn" (click)="toArtista()"
                        style="top: 100px; margin-left: 400px; position: relative;">Comenzar</button>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>

</mat-grid-list>