
<mat-tab-group animationDuration="800ms" mat-stretch-tabs class="tab">
    
    <mat-tab label="Orientación">
        <!--<br>
        <h1 class="text">Orientación</h1>
        <hr>-->
        <div style="margin-top: 5%;"></div>
        <h2 class="text">Fecha de orientación</h2>
        <mat-form-field class="data" appearance="fill">
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker"
                (dateInput)="addEvent('input', $event)" disabled>
            <mat-datepicker-toggle matSuffix [for]="picker">
            </mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
        <h2 class="text">Tiempo deseado</h2>
        <div class="card-tiempo">
            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Desde</mat-label>
                <mat-select matInput [(ngModel)]="cadastro.horarioInicial" required>
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="tiempoIF">
                <mat-label>Hasta</mat-label>
                <mat-select matInput [(ngModel)]="cadastro.horarioFinal" required>
                    <mat-option value="0:00">0:00</mat-option>
                    <mat-option value="1:00">1:00</mat-option>
                    <mat-option value="2:00">2:00</mat-option>
                    <mat-option value="3:00">3:00</mat-option>
                    <mat-option value="4:00">4:00</mat-option>
                    <mat-option value="5:00">5:00</mat-option>
                    <mat-option value="6:00">6:00</mat-option>
                    <mat-option value="7:00">7:00</mat-option>
                    <mat-option value="8:00">8:00</mat-option>
                    <mat-option value="9:00">9:00</mat-option>
                    <mat-option value="10:00">10:00</mat-option>
                    <mat-option value="11:00">11:00</mat-option>
                    <mat-option value="12:00">12:00</mat-option>
                    <mat-option value="13:00">13:00</mat-option>
                    <mat-option value="14:00">14:00</mat-option>
                    <mat-option value="15:00">15:00</mat-option>
                    <mat-option value="16:00">16:00</mat-option>
                    <mat-option value="17:00">17:00</mat-option>
                    <mat-option value="18:00">18:00</mat-option>
                    <mat-option value="19:00">19:00</mat-option>
                    <mat-option value="20:00">20:00</mat-option>
                    <mat-option value="21:00">21:00</mat-option>
                    <mat-option value="22:00">22:00</mat-option>
                    <mat-option value="23:00">23:00</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div style="margin-bottom: 5%;"></div>
        <div class="card-tiempo">
            <button mat-raised-button tabindex="-1" type="button" color="primary"
                (click)='moveToSelectedTab("Direccion")'>Próxima</button>
        </div>
        <div style="margin-bottom: 5%;"></div>
    </mat-tab>

    <mat-tab label="Direccion">
        <!--<br>
        <h1 class="text">Direccion</h1>
        <hr>-->
        <!-- this creates a google map on the page with the given lat/lng from -->
        <!-- the component as the initial center of the map: -->
        <div class="map">
            <agm-map [latitude]="lat" [longitude]="lng">
                <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
        </div>

        <mat-form-field appearance="fill" class="local">
            <mat-label>Direccion</mat-label>
            <input matInput [(ngModel)]="cadastro.endereco" required>
        </mat-form-field>
        <br>

        <div class="ajuste2"></div>
        <div class="card-tiempo">
            <button mat-raised-button tabindex="-1" type="button" color="accent"
                (click)='moveToSelectedTab("Orientación")' style="margin-left: 1%;">Voltar</button>
            <button mat-raised-button tabindex="-1" type="button" color="primary"
                (click)='moveToSelectedTab("Confirmacion")' style="margin-left: 2%;">Próxima</button>
        </div>
        <div style="margin-bottom: 10%;"></div>
    </mat-tab>

    <mat-tab label="Confirmacion">
        <!--<br>
        <h1 class="text">Confirmacion</h1>
        <hr>
        <div class="ajuste"></div>
        <br>-->

        <mat-card class="card">

            <h1 class="text">Paciente</h1>
            <h3 class="text">Confirmacao dos dados</h3>
            <br>
            <img src="{{avatar}}" class="avatar">
            <mat-card-content>
                <br>
                <div>{{nome}}</div>
                <br>
                <div>{{data | date: 'dd/MM/yyyy'}}</div>
                <br>
                <div>{{cadastro.horarioInicial}} - {{cadastro.horarioFinal}}</div>
                <br>
                <div>${{valor_pagar}}</div>
                <br>
                <div>{{cadastro.endereco}}</div>
                <br>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button tabindex="-1" type="button" color="accent"
                    (click)='moveToSelectedTab("Direccion")'>Voltar</button>
                <button mat-raised-button color="primary" style="margin-left: 8%;"
                    *ngIf="data == ''|| cadastro.horarioInicial =='' || cadastro.horarioFinal =='' || cadastro.endereco ==''"
                    disabled>Buscar</button>
                <button mat-raised-button color="primary" (click)="toProgramado()" style="margin-left: 8%;"
                    *ngIf="data !=''&& cadastro.horarioInicial !='' && cadastro.horarioFinal !='' && cadastro.endereco !=''">Buscar</button>
            </mat-card-actions>
        </mat-card>
    </mat-tab>
</mat-tab-group>