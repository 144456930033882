import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-smartfilms2020',
  templateUrl: './smartfilms2020.component.html',
  styleUrls: ['./smartfilms2020.component.css']
})
export class smartfilms2020Component implements OnInit {

  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
   
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }


}
