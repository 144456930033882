<div style="margin-top: 60px;"></div>

<div style="font-family: 'Tangerine'; text-align: center; margin-top: 150px; margin-bottom: 80px; right: auto; left: auto; font-size: 100px;">
    <p>Médico General</p>
</div>

<div class="formulario">
    <form>
        <!--<img src="{{urlink}}" class="avatar">
        <button mat-icon-button (click)="fileInput.click()">
            <mat-icon>library_add</mat-icon>
            <input #fileInput type="file" style="display:none;" (change) ="selectFile($event)" />
        </button>-->
        <br>
        
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Email</mat-label>
            <input matInput type="text" id="name" name="name" [(ngModel)]="loginAws.email" required>
        </mat-form-field>
        <br>

        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Senha</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" id="senha" name="senha" [(ngModel)]="loginAws.senha" required>

            <mat-icon mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"[attr.aria-pressed]="hide">
                {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" class="cadastro">
            <mat-label>Confirmar Senha</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" id="senha" name="senha" [(ngModel)]="loginAws.confirmarSenha" required>

            <mat-icon mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"[attr.aria-pressed]="hide">
                {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </mat-form-field>
        <br>
        <br>
        <button mat-raised-button class="registerbtn" type="submit" (click)="verificarSenha()">Registrar</button>
    
    </form>
</div>

<div  class="ajuste"></div>