import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider} from 'angularx-social-login';
import { HttpClientModule } from '@angular/common/http';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgxImageCompressService } from 'ngx-image-compress';
import { Ng2ImgMaxModule } from 'ng2-img-max'
import { NgxImageCompressorModule } from 'ngx-image-compressor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMaskModule } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './estatico/home/home.component';
import { FooterComponent } from './estatico/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CabecalhoComponent } from './estatico/cabecalho/cabecalho.component';
import { RegistrarComponent } from './formularios/registrar/registrar.component';
import { EntrarComponent } from './formularios/entrar/entrar.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { PrincipalComponent } from './auth/principal/principal.component';
import { PerfilComponent } from './auth/perfil/perfil.component';
import { SolicitarOrientacionComponent } from './auth/solicitar-orientacion/solicitar-orientacion.component';
import { ProgramadoComponent } from './auth/programado/programado.component';
import { OrientacionesProgramadasComponent } from './auth/orientaciones-programadas/orientaciones-programadas.component';
import { ChatProfissionalComponent } from './auth/chat-profissional/chat-profissional.component';
import { HistoricoComponent } from './auth/historico/historico.component';
import { AvaliacaoComponent } from './auth/avaliacao/avaliacao.component';
import { smartfilms2020Component } from './estatico/smartfilms2020/smartfilms2020.component';
import { covid19Component } from './estatico/covid19/covid19.component';
import { QuienessomosComponent } from './estatico/quienessomos/quienessomos.component';
import { EconverdeComponent } from './estatico/econverde/econverde.component';
import { ComooperamosComponent } from './estatico/comooperamos/comooperamos.component';
import { MatSelectModule} from '@angular/material/select';
import { CadastroPacienteComponent } from './formularios/cadastro-paciente/cadastro-paciente.component';
import { CadastroMeComponent } from './formularios/cadastro-me/cadastro-me.component';
import { CadastroMgComponent } from './formularios/cadastro-mg/cadastro-mg.component';
import { EditPacienteComponent } from './formularios/edit-paciente/edit-paciente.component';
import { EditMgComponent } from './formularios/edit-mg/edit-mg.component';
import { EditMeComponent } from './formularios/edit-me/edit-me.component';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { AmplifyService } from 'aws-amplify-angular';
import { AutenticarMedicoComponent } from './auth/autenticar-medico/autenticar-medico.component';
import { SalaEsperaComponent } from './auth/sala-espera/sala-espera.component';
import { AprovarMedicoComponent } from './auth/aprovar-medico/aprovar-medico.component';
import { RegistroDeUsuariosComponent } from './auth/registro-de-usuarios-adm/registro-de-usuarios.component';
import { RegistroMedicoComponent } from './auth/registro-medico/registro-medico.component';
import { SalaDeConfirmacaoMedicaComponent } from './auth/sala-de-confirmacao-medica/sala-de-confirmacao-medica.component';
import { AgmCoreModule } from '@agm/core';
import { OrientacionComponent } from './auth/medico/geral/orientacion/orientacion.component';
import { HistorialDeOrientacionesComponent } from './auth/medico/historial-de-orientaciones/historial-de-orientaciones.component';
import { EvaluacionComponent } from './auth/medico/evaluacion/evaluacion.component';
import { DatosComponent } from './auth/medico/datos/datos.component';
import { PrincipalEspecialistaComponent } from './auth/medico/especialista/principal-especialista/principal-especialista.component';
import { PrincipalGeralComponent } from './auth/medico/geral/principal-geral/principal-geral.component';
import { OrientacionesDispMGeralComponent } from './auth/medico/geral/orientaciones-disp-mgeral/orientaciones-disp-mgeral.component';
import { OrientacionesDispMEspecialistaComponent } from './auth/medico/especialista/orientaciones-disp-mespecialista/orientaciones-disp-mespecialista.component';
import { ProgramadasComponent } from './auth/medico/geral/programadas/programadas.component';
import { Chart, ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController,
  PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale,
  TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle} from 'chart.js';
import { HistoriaClinicaComponent } from './auth/medico/geral/historia-clinica/historia-clinica.component';
import { PreCadastroComponent } from './formularios/pre-cadastro/pre-cadastro.component';
import { FooterLightComponent } from './estatico/footer-light/footer-light.component';
import { NavBarComponent } from './estatico/nav-bar/nav-bar.component';
import { ExperienceComponent } from './estatico/experience/experience.component';
import { NuestraVisionComponent } from './estatico/nuestra-vision/nuestra-vision.component';
import { OrientacionIaComponent } from './estatico/orientacion-ia/orientacion-ia.component';
import { OrientacionChatGPTComponent } from './auth/orientacion-chat-gpt/orientacion-chat-gpt.component';
Chart.register( ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController,
  PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale,
  TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle
);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    CabecalhoComponent,
    RegistrarComponent,
    EntrarComponent,
    PrincipalComponent,
    PerfilComponent,
    SolicitarOrientacionComponent,
    ProgramadoComponent,
    OrientacionesProgramadasComponent,
    ChatProfissionalComponent,
    HistoricoComponent,
    AvaliacaoComponent,
    smartfilms2020Component,
    covid19Component,
    QuienessomosComponent,
    EconverdeComponent,
    ComooperamosComponent,
    CadastroPacienteComponent,
    CadastroMeComponent,
    CadastroMgComponent,
    EditPacienteComponent,
    EditMgComponent,
    EditMeComponent,
    AutenticarMedicoComponent,
    SalaEsperaComponent,
    AprovarMedicoComponent,
    RegistroDeUsuariosComponent,
    RegistroMedicoComponent,
    SalaDeConfirmacaoMedicaComponent,
    OrientacionComponent,
    HistorialDeOrientacionesComponent,
    EvaluacionComponent,
    DatosComponent,
    PrincipalEspecialistaComponent,
    PrincipalGeralComponent,
    OrientacionesDispMGeralComponent,
    OrientacionesDispMEspecialistaComponent,
    ProgramadasComponent,
    HistoriaClinicaComponent,
    PreCadastroComponent,
    FooterLightComponent,
    NavBarComponent,
    ExperienceComponent,
    NuestraVisionComponent,
    OrientacionIaComponent,
    OrientacionChatGPTComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatMenuModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatCardModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    SocialLoginModule,
    MatSelectModule,
    AmplifyUIAngularModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    Ng2ImgMaxModule,
    NgxImageCompressorModule,
    ImageCropperModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCSk062Ua_46jCcVud9Xy2xPUio-9iXhu8'//Google Maps
    }),
  ],
  providers: [HttpClientModule,
    AmplifyService,
    NgxImageCompressService,
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('Codigo da empresa')
          },
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
