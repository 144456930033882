<div style="margin-top: 4.65%;"></div>

<div class="container" style="margin-left: 20px; min-height: 580px;">
    <div>
        <br>
        <div class="container">
            <hr style="margin-right: 15px; height: 100px;">
            <img src="{{medico.img}}" alt="Avatar" class="avatar">
            <div style="margin-left: 15px; margin-top: 15px;">
                <div>{{medico.nome}}</div>
                <div>{{medico.sexo}}, {{medico.idade}} años.</div>
                <div>{{medico.nacionalidade}}</div>
            </div>
            <span class="spacer"></span>
            <span class="spacer"></span>
            <span class="spacer"></span>
            <span class="spacer"></span>
            <button mat-raised-button style="background-color: orange; color: white;" class="btn1">ESPECIALISTA</button>
            <span class="spacer"></span>
            <hr style="height: 50px; margin-top: 1.5%;">
            <span class="spacer"></span>
            <button mat-raised-button color="primary" class="btn1">TERMINAR</button>
            <span class="spacer"></span>
        </div>
        <div style="margin-top: 10px; margin-left: 15px;">
            <h3 class="btnConcentimento">CONSENTIMENTO INFORMADO</h3>
            <!--<button mat-raised-button class="btnConcentimento">CONSENTIMENTO INFORMADO</button>-->
        </div>

        <mat-tab-group mat-stretch-tabs (selectedTabChange)="onLinkClick($event)">
            <mat-tab label="Motivo de Consulta">
                <br>
                <h4>Motivo de Consulta</h4>
                <mat-form-field appearance="outline" style="width: 97%;">
                    <textarea matInput style="resize: none;" rows="1" [(ngModel)]="motivo_de_consulta"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
            </mat-tab>

            <mat-tab label="Enfermedad Actual">
                <br>
                <h4>Enfermedad Actual</h4>
                <mat-form-field appearance="outline" style="width: 97%;">
                    <textarea matInput style="resize: none;" rows="1" [(ngModel)]="enfermedad_actual"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
            </mat-tab>

            <mat-tab label="Antecedentes">
                <br>
                <h4 *ngIf="!btn.familiares" (click)="familiares()" class="pointer">Familiares</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn.familiares">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn.perinatales" (click)="perinatales()" class="pointer">Perinatales</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn.perinatales">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn.desarollo" (click)="desarollo()" class="pointer">Desarollo Infantil</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn.desarollo">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn.patologico" (click)="patologico()" class="pointer">Patológico</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn.patologico">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn.quirurgico" (click)="quirurgico()" class="pointer">Quirúrgico</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn.quirurgico">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn.farmacologico" (click)="farmacologico()" class="pointer">Farmacológico</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn.farmacologico">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <div style="display: flex; flex-direction: column;">
                    <h4 *ngIf="btn.alergico!=0" (click)="alergico()" class="pointer">Alérgico</h4>
                    <div *ngIf="!btn.alergico_farmocologico && btn.alergico!=0">
                        <button mat-button disabled>
                            <mat-icon appearance="outline">keyboard_arrow_right</mat-icon> Farmacológico
                        </button>
                        <br>
                        <input type="text" class="ipt">
                        <button mat-icon-button *ngIf="btn_alergico_Farmacologia==0"
                            (click)="add_Alergico_Farmacologia()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Farmacologia>0">
                        <button mat-icon-button *ngIf="btn_alergico_Farmacologia==1"
                            (click)="add_Alergico_Farmacologia()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Farmacologia>1">
                        <button mat-icon-button *ngIf="btn_alergico_Farmacologia==2"
                            (click)="add_Alergico_Farmacologia()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Farmacologia>2">
                        <button mat-icon-button *ngIf="btn_alergico_Farmacologia==3"
                            (click)="add_Alergico_Farmacologia()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Farmacologia>3">
                        <br>
                    </div>
                    <div *ngIf="!btn.alergico_alimentario && btn.alergico!=0">
                        <button mat-button disabled>
                            <mat-icon appearance="outline">keyboard_arrow_right</mat-icon> Alimentarios
                        </button>
                        <br>
                        <input type="text" class="ipt">
                        <button mat-icon-button *ngIf="btn_alergico_Alimentarios==0"
                            (click)="add_Alergico_Alimentarios()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Alimentarios>0">
                        <button mat-icon-button *ngIf="btn_alergico_Alimentarios==1"
                            (click)="add_Alergico_Alimentarios()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Alimentarios>1">
                        <button mat-icon-button *ngIf="btn_alergico_Alimentarios==2"
                            (click)="add_Alergico_Alimentarios()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Alimentarios>2">
                        <button mat-icon-button *ngIf="btn_alergico_Alimentarios==3"
                            (click)="add_Alergico_Alimentarios()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Alimentarios>3">
                        <br>
                    </div>
                    <div *ngIf="!btn.alergico_animales && btn.alergico!=0">
                        <button mat-button disabled>
                            <mat-icon appearance="outline">keyboard_arrow_right</mat-icon> Animales
                        </button>
                        <br>
                        <input type="text" class="ipt">
                        <button mat-icon-button *ngIf="btn_alergico_Animales==0" (click)="add_Alergico_Animales()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Animales>0">
                        <button mat-icon-button *ngIf="btn_alergico_Animales==1" (click)="add_Alergico_Animales()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Animales>1">
                        <button mat-icon-button *ngIf="btn_alergico_Animales==2" (click)="add_Alergico_Animales()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Animales>2">
                        <button mat-icon-button *ngIf="btn_alergico_Animales==3" (click)="add_Alergico_Animales()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Animales>3">
                        <br>
                    </div>
                    <div *ngIf="!btn.alergico_latex && btn.alergico!=0">
                        <button mat-button disabled>
                            <mat-icon appearance="outline">keyboard_arrow_right</mat-icon> Látex
                        </button>
                        <br>
                        <input type="text" class="ipt">
                        <button mat-icon-button *ngIf="btn_alergico_Latex==0" (click)="add_Alergico_Latex()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Latex>0">
                        <button mat-icon-button *ngIf="btn_alergico_Latex==1" (click)="add_Alergico_Latex()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Latex>1">
                        <button mat-icon-button *ngIf="btn_alergico_Latex==2" (click)="add_Alergico_Latex()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Latex>2">
                        <button mat-icon-button *ngIf="btn_alergico_Latex==3" (click)="add_Alergico_Latex()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <br>
                        <input type="text" class="ipt" *ngIf="btn_alergico_Latex>3">
                        <br>
                    </div>
                </div>
                <h4 *ngIf="!btn.transfusiones" (click)="transfusiones()" class="pointer">Transfusiones</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn.transfusiones">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn.habitos" (click)="habitos()" class="pointer">Hábitos</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn.habitos">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
            </mat-tab>

            <mat-tab label="Revision por Sistemas">
                <br>
                <h4>Sintomas Generales</h4>
                <mat-form-field appearance="outline" style="width: 97%;">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4>Intensidade del Dolor</h4>
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                    <mat-button-toggle value="0">0<br>sin dolor</mat-button-toggle>
                    <mat-button-toggle value="1">1<br>dolor leve</mat-button-toggle>
                    <mat-button-toggle value="2">2<br>dolor moderado</mat-button-toggle>
                    <mat-button-toggle value="3">3<br>dolor severo</mat-button-toggle>
                    <mat-button-toggle value="4">4<br>dolor muy severo</mat-button-toggle>
                    <mat-button-toggle value="5">5<br>máximo dolor</mat-button-toggle>
                </mat-button-toggle-group>
                <br>
                <h4 style="margin-top: 1%;">Duración y Periodicidad Del Dolor</h4>
                <mat-form-field appearance="outline" style="width: 97%;">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4>Localización y Distrilbuición</h4>
                <mat-form-field appearance="outline" style="width: 97%;">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>

                <h4 *ngIf="!btn2.piel" (click)="pielYfaneras()" class="pointer">Piel y Faneras</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn2.piel">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>

                <h4 *ngIf="!btn2.neurosensorial" (click)="sistemaNeurosensorial()" class="pointer">Sistema Neurosensorial</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn2.neurosensorial">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>

                <h4 *ngIf="!btn2.cardiovascular" (click)="sistemaCardiovascular()" class="pointer">Sistema Cardiovascular</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn2.cardiovascular">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>

                <h4 *ngIf="!btn2.respiratorio" (click)="sistemaRespiratorio()" class="pointer">Sistema Respiratorio</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn2.respiratorio">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>

                <h4 *ngIf="!btn2.gastrointestinal" (click)="sistemaGastrointestinal()" class="pointer">Sistema Gastrointestinal</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn2.gastrointestinal">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>

                <h4 *ngIf="!btn2.genitourinario" (click)="sistemaGenitourinario()" class="pointer">Sistema Genitourinario</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn2.genitourinario">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>

                <h4 *ngIf="!btn2.musculo_esqueletico" (click)="sistemaMusculoEsqueletico()" class="pointer">Sistema Musculo Esquelético</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn2.musculo_esqueletico">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>

                <h4 *ngIf="!btn2.endocrino" (click)="sistemaEndocrino()" class="pointer">Sistema Endocrino</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn2.endocrino">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>

                <h4 *ngIf="!btn2.mental" (click)="sistemaMental()" class="pointer">Sistema Mental</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn2.mental">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>

                <h4 *ngIf="!btn2.nevioso" (click)="sistemaNevioso()" class="pointer">Sistema Nevioso</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn2.nevioso">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
            </mat-tab>

            <mat-tab label="Examen Fisico">
                <br>
                <h4>Estado General del Paciente</h4>
                <mat-form-field appearance="outline" style="width: 97%;">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4>Signos Vitales</h4>
                <b>FC </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" class="ipt2"> lpm
                <br>
                <b>FR </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" class="ipt2"> rpm
                <br>
                <b>PA </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" class="ipt2"> mm/hg
                <br>
                <b>T </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" class="ipt2"> °c
                <br>
                <b>SATO2</b><input type="text" class="ipt2"> %
                <br>
                <h4 *ngIf="!btn3.piel" (click)="piel()" class="pointer">Piel</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn3.piel">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn3.cabeza" (click)="cabeza()" class="pointer">Cabeza</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn3.cabeza">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn3.cuello" (click)="cuello()" class="pointer">Cuello</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn3.cuello">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn3.torax" (click)="torax()" class="pointer">Tórax</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn3.torax">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn3.abdomen" (click)="abdomen()" class="pointer">Abdomen</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn3.abdomen">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn3.genitourinario" (click)="genitourinario()" class="pointer">Genitourinario</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn3.genitourinario">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn3.esqueletico" (click)="musculoEsqueletico()" class="pointer">Musculo Esquelético</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn3.esqueletico">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn3.neurologico" (click)="examenNeurologico()" class="pointer">Examen Neurológico</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn3.neurologico">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4 *ngIf="!btn3.mental" (click)="examenMental()" class="pointer">Examen Mental</h4>
                <mat-form-field appearance="outline" style="width: 97%;" *ngIf="!btn3.mental">
                    <textarea matInput style="resize: none;" rows="1"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
            </mat-tab>

            <mat-tab label="Analisis y Plan">
                <br>
                <h4>Analisis</h4>
                <mat-form-field appearance="outline" style="width: 97%;">
                    <textarea matInput style="resize: none;" rows="1" [(ngModel)]="enfermedad_actual"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
                <h4>Pan</h4>
                <mat-form-field appearance="outline" style="width: 97%;">
                    <textarea matInput style="resize: none;" rows="1" [(ngModel)]="enfermedad_actual"
                        oninput='if(this.scrollHeight > this.offsetHeight) this.rows += 1'></textarea>
                </mat-form-field>
            </mat-tab>

        </mat-tab-group>
    </div>
    <div class="container2">
        <div class="cabecalho">{{cabecalho}}</div>
        <!--Antecedentes-->
        <button mat-raised-button class="btn2" *ngIf="controleBotao==2 && btn.familiares" (click)="familiares()">
            <mat-icon>keyboard_arrow_left</mat-icon> Familiares
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==2 && btn.perinatales" (click)="perinatales()">
            <mat-icon>keyboard_arrow_left</mat-icon> Perinatales
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==2 && btn.desarollo" (click)="desarollo()">
            <mat-icon>keyboard_arrow_left</mat-icon> Desarollo Infantil
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==2 && btn.patologico" (click)="patologico()">
            <mat-icon>keyboard_arrow_left</mat-icon> Patológico
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==2 && btn.quirurgico" (click)="quirurgico()">
            <mat-icon>keyboard_arrow_left</mat-icon> Quirúrgico
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==2 && btn.farmacologico" (click)="farmacologico()">
            <mat-icon>keyboard_arrow_left</mat-icon> Farmacológico
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==2 && btn.alergico == 0" (click)="alergico()">
            <mat-icon>keyboard_arrow_left</mat-icon> Alérgico
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==2 && btn.alergico == 1" (click)="alergico()">
            <mat-icon>keyboard_arrow_down</mat-icon> Alérgico
        </button>
        <button mat-button style="text-align: left;"
            *ngIf="controleBotao==2 && btn.alergico == 1 && btn.alergico_farmocologico"
            (click)="alergico_farmocologico()">
            <mat-icon>keyboard_arrow_left</mat-icon> Farmacológicos
        </button>
        <button mat-button style="text-align: left;"
            *ngIf="controleBotao==2 && btn.alergico == 1 && btn.alergico_alimentario" (click)="alergico_alimentario()">
            <mat-icon>keyboard_arrow_left</mat-icon> Alimentarios
        </button>
        <button mat-button style="text-align: left;"
            *ngIf="controleBotao==2 && btn.alergico == 1 && btn.alergico_animales" (click)="alergico_animales()">
            <mat-icon>keyboard_arrow_left</mat-icon> Animales
        </button>
        <button mat-button style="text-align: left;" *ngIf="controleBotao==2 && btn.alergico == 1 && btn.alergico_latex"
            (click)="alergico_latex()">
            <mat-icon>keyboard_arrow_left</mat-icon> Látex
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==2 && btn.transfusiones" (click)="transfusiones()">
            <mat-icon>keyboard_arrow_left</mat-icon> Transfusiones
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==2 && btn.habitos" (click)="habitos()">
            <mat-icon>keyboard_arrow_left</mat-icon> Hábitos
        </button>
        <!--Antecedentes-->
        <!--Revision por Sistemas-->
        <button mat-raised-button class="btn2" *ngIf="controleBotao==3 && btn2.piel" (click)="pielYfaneras()">
            <mat-icon>keyboard_arrow_left</mat-icon> Piel y Faneras
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==3 && btn2.neurosensorial" (click)="sistemaNeurosensorial()">
            <mat-icon>keyboard_arrow_left</mat-icon> Sistema Neurosensorial
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==3 && btn2.cardiovascular" (click)="sistemaCardiovascular()">
            <mat-icon>keyboard_arrow_left</mat-icon> Sistema Cardiovascular
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==3 && btn2.respiratorio" (click)="sistemaRespiratorio()">
            <mat-icon>keyboard_arrow_left</mat-icon> Sistema Respiratorio
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==3 && btn2.gastrointestinal" (click)="sistemaGastrointestinal()">
            <mat-icon>keyboard_arrow_left</mat-icon> Sistema Gastrointestinal
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==3 && btn2.genitourinario" (click)="sistemaGenitourinario()">
            <mat-icon>keyboard_arrow_left</mat-icon> Sistema Genitourinario
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==3 && btn2.musculo_esqueletico" (click)="sistemaMusculoEsqueletico()">
            <mat-icon>keyboard_arrow_left</mat-icon> Sistema Musculo Esquelético
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==3 && btn2.endocrino" (click)="sistemaEndocrino()">
            <mat-icon>keyboard_arrow_left</mat-icon> Sistema Endocrino
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==3 && btn2.mental" (click)="sistemaMental()">
            <mat-icon>keyboard_arrow_left</mat-icon> Sistema Mental
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==3 && btn2.nevioso" (click)="sistemaNevioso()">
            <mat-icon>keyboard_arrow_left</mat-icon> Sistema Nevioso
        </button>
        <!--Revision por Sistemas-->
        <!--Examen Fisico-->
        <button mat-raised-button class="btn2" *ngIf="controleBotao==4 && btn3.piel" (click)="piel()">
            <mat-icon>keyboard_arrow_left</mat-icon> Piel
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==4 && btn3.cabeza" (click)="cabeza()">
            <mat-icon>keyboard_arrow_left</mat-icon> Cabeza
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==4 && btn3.cuello" (click)="cuello()">
            <mat-icon>keyboard_arrow_left</mat-icon> Cuello
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==4 && btn3.torax" (click)="torax()">
            <mat-icon>keyboard_arrow_left</mat-icon> Tórax
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==4 && btn3.abdomen" (click)="abdomen()">
            <mat-icon>keyboard_arrow_left</mat-icon> Abdomen
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==4 && btn3.genitourinario" (click)="genitourinario()">
            <mat-icon>keyboard_arrow_left</mat-icon> Genitourinario
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==4 && btn3.esqueletico" (click)="musculoEsqueletico()">
            <mat-icon>keyboard_arrow_left</mat-icon> Musculo Esquelético
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==4 && btn3.neurologico" (click)="examenNeurologico()">
            <mat-icon>keyboard_arrow_left</mat-icon> Examen Neurológico
        </button>
        <button mat-raised-button class="btn2" *ngIf="controleBotao==4 && btn3.mental" (click)="examenMental()">
            <mat-icon>keyboard_arrow_left</mat-icon> Examen Mental
        </button>
        <!--Examen Fisico-->
    </div>
</div>