<div>

  <!--NavBar-->
  <div id="navBarDark"></div>
  <!--NavBar-->

  <!--Spacer-->
  <div class="py-5"></div>
  <!--Spacer-->
  <!--Spacer-->
  <div class="py-4"></div>
  <!--Spacer-->

  <!--Hero - Vision-->
  <div class="container-fluid">
    <!--Us-->
    <div class="col-xl-11 mx-auto bg-dark rounded-4 shadow d-flex align-items-center"
      style="min-height: 65vh; background-image: url(https://res.cloudinary.com/juandacloud/image/upload/v1688694016/afoco-projects/tedi/background-image_s47caa.jpg); background-attachment: fixed;">
      <div class="mx-5">
        <!--Spacer-->
        <div class="py-4"></div>
        <!--Spacer-->
        <div class="text-center">
          <h1 class="fw-bold my-4 text-light fs-1">Descubre nuestra visión</h1>
          <div class="col-xl-8 mx-auto">
            <p class="text-light">En TEDIMEDICINA, no solo somos lo que somos, sino lo que hacemos.
              Redefinimos
              la medicina tradicional y la elevamos a un arte. Nuestro enfoque se basa en utilizar la
              inteligencia artificial para la prevención de enfermedades, conectando corazones en un solo
              latido y brindando un valor inmenso a las personas. Con TEDI, creamos un espacio donde
              pacientes, médicos generales y especialistas pueden interconectarse, mirarse a los ojos y
              sentirse en familia.</p>
          </div>
        </div>
        <div class="my-4 text-center">
           <!--Retirado parar deploy-->
          <!--
          <a href="" class="btn text-light my-1 mx-1" style="background-color: #2e5e88;">Comenzar prueba ahora</a>
          <a href="" class="btn btn-light my-1 mx-1">¡Conoce más!</a>
          -->
        </div>
        <!--Spacer-->
        <div class="py-4"></div>
        <!--Spacer-->
      </div>
    </div>
  </div>
  <!--Hero - Vision-->

  <!--Spacer-->
  <div class="py-4"></div>
  <!--Spacer-->
  <!--Spacer-->
  <div class="py-4"></div>
  <!--Spacer-->

  <!--Us-->
  <div class="container-fluid">
    <div class="container text-center col-xl-6">
      <p class="text-secondary fs-5">Quiénes somos</p>
      <h1 class="fw-bold mb-5">Nuestra pasión por la atención médica de calidad</h1>
      <p>En Tedimedicina, nuestra pasión es brindar atención médica de calidad y accesible para todos. Somos un
        equipo de profesionales apasionados y altamente calificados en medicina y tecnología, comprometidos en
        establecer un puente entre pacientes y médicos especialistas a través de nuestra plataforma tecnológica.
        Únete a nosotros y experimenta la excelencia médica combinada con la comodidad de la tecnología.</p>
    </div>
  </div>
  <!--Us-->

  <!--Spacer-->
  <div class="py-4"></div>
  <!--Spacer-->
  <!--Spacer-->
  <div class="py-4"></div>
  <!--Spacer-->

  <!--Work-->
  <div class="container-fluid">
    <div class="col-xl-10 rounded-4 shadow mx-auto px-4"
      style="background: linear-gradient(to bottom, #307CA2, #739ed9);">
      <div class="row">
        <div class="col-xl-5 mx-auto d-flex align-items-center my-5">
          <div>
            <p class="text-light fs-5">Nuestros logros y estadísticas</p>
            <h4 class="fw-bold mb-4 fs-3">Nuestro compromiso con la excelencia y la innovación ha llevado a
              resultados significativos para nuestros usuarios.</h4>
               <!--Retirado parar deploy-->
          <!--
            <a href="" class="btn btn-light my-1 mx-1">Comenzar prueba ahora</a>
          -->
          </div>
        </div>
        <div class="col-xl-5 mx-auto my-5">
          <div class="row">
            <h1 class="fw-bold" style="font-size: 80px;">1,000</h1>
            <h5 class="fw-bold text-light">Red de médicos especialistas</h5>
            <p class="">Vamos a expandir nuestra red de profesionales altamente calificados en diversas
              especialidades médicas.</p>
          </div>
          <div class="row">
            <h1 class="fw-bold" style="font-size: 80px;">50%</h1>
            <h5 class="fw-bold text-light">Soluciones con inteligencia artificial </h5>
            <p class="">Implementaremos soluciones avanzadas que mejoren la precisión diagnóstica, brindando a
              los pacientes resultados más confiables y tratamientos más efectivos.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Work-->

  <!--Spacer-->
  <div class="py-4"></div>
  <!--Spacer-->
  <!--Spacer-->
  <div class="py-4"></div>
  <!--Spacer-->

  <!--Us-->
  <div class="container-fluid">
    <div class="container text-center col-xl-6">
      <p class="text-secondary fs-5">Cómo operamos</p>
      <h1 class="fw-bold mb-5">La magia detrás de nuestra plataforma</h1>
      <p>En Tedimedicina, operamos con una plataforma fácil de usar respaldada por la inteligencia artificial. Obtén
        acceso a médicos especialistas altamente calificados y realiza consultas médicas, preguntas y seguimiento de
        tratamientos a través de nuestra interfaz en línea. Estamos aquí para brindarte atención médica confiable y
        accesible en cualquier momento y desde cualquier lugar. Descubre la magia de nuestra plataforma y experimenta
        una nueva forma de cuidado de la salud.</p>
    </div>
  </div>
  <!--Us-->

  <!--Spacer-->
  <div class="py-4"></div>
  <!--Spacer-->

  <!--Footer-->
  <app-footer></app-footer>

</div>
