import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImgService {

 
  private SERVER_URL_IMG = 'https://ha6yllsqu3.execute-api.us-east-2.amazonaws.com/prod';
  
  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }
  
  putIMG(cadastro: any){
    return this.httpClient.put(`${this.SERVER_URL_IMG}`, cadastro)
    .pipe(
      retry(2),
      catchError(this.handleError))
  }
  
  getIMG(param: string): Observable<any> {
    return this.httpClient.get<any>(`${this.SERVER_URL_IMG}?id=` + param, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  };
}
