import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-cadastro-me',
  templateUrl: './cadastro-me.component.html',
  styleUrls: ['./cadastro-me.component.css']
})
export class CadastroMeComponent implements OnInit {

  loginAws = {
    email: '',
    senha: '',
    confirmarSenha: '',
    profile: 'medico-especialista',
    zoneinfo: 'registered'
  }
  hide = true;
  urlink: string = "../../../assets/avatar.png";
  loading = false;
  selectFile(event: any) {
    if (event.target.files) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (event: any) =>
        this.urlink = event.target.result
    }
  }

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }
  
  openDialogErro(){
    this.dialog.open(SenhaError);
  }
  openDialogEmail(){
    this.dialog.open(Confirmacao);
  }
  openDialogErroCadastro(){
    this.dialog.open(ErroCadastro);
  }
  openDialogEmailExist(){
    this.dialog.open(EmailExists);
  }
  async signUp() {
    let username = this.loginAws.email;
    let password = this.loginAws.senha;
    let profile = this.loginAws.profile;
    let zoneinfo = this.loginAws.zoneinfo;
    try {
      const { user } = await Auth.signUp({
        username, password, attributes: {
          profile, 
          zoneinfo
        }
      });
      this.loading = false;
      this.openDialogEmail();
    } catch (error) {
      this.loading = false;
      this.testeDados(error)
    }
  }

  testeDados(objeto: any){
    if (objeto.code == 'InvalidParameterException'){
      this.openDialogErroCadastro();
  }

    if (objeto.code == 'UsernameExistsException'){
      this.openDialogEmailExist();
    }
  }

  verificarSenha(){
    this.loading = true;
    let senha = this.loginAws.senha;
    let confirmacao = this.loginAws.confirmarSenha;
    if(senha.length < 8){
      console.log(senha.length)
      this.openDialogErroCadastro();
    }else if (senha == confirmacao){
      this.signUp();
    }else{
      this.openDialogErro();
    }
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'erro-senha-me.html',
})
export class SenhaError {
  constructor(
    public dialog: MatDialog
  ) { }
  fechar(){
    this.dialog.closeAll();
  }
}


@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'confirmada-cadastro-me.html',
})
export class Confirmacao {
  constructor(
    public dialog: MatDialog,
    private router: Router,
  ) { }
  toLogin(){
    this.dialog.closeAll();
    this.router.navigate(['logar'])
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'erro-cadastro-me.html',
})
export class ErroCadastro {
  constructor(
    public dialog: MatDialog
  ) { }
  fechar(){
    this.dialog.closeAll();
  }
}


@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'erro-email-exist.html',
})
export class EmailExists {
  constructor(
    public dialog: MatDialog
  ) { }
  fechar(){
    this.dialog.closeAll();
  }
}