import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';

@Component({
  selector: 'app-chat-profissional',
  templateUrl: './chat-profissional.component.html',
  styleUrls: ['./chat-profissional.component.css']
})
export class ChatProfissionalComponent implements OnInit {

  data = "04/11/2020";
  horario = "6 pm";
  nome = "Jose";
  urlink ="../../../assets/avatar.png"

  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) { 
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      if (authState.state != "signedIn") {
        this.router.navigateByUrl("");
      }
    })
  }

  ngOnInit(): void {
  }

  toOrinetacaProgramada(){
    this.router.navigate(['orientacao-programada']);
  }

}
