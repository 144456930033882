<div class="bg-dark">

  <!--NavBar-->
  <!--<div id="navBarDark"></div>-->
  <!--NavBar-->

  <!--Spacer-->
  <div class="pt-5 pb-3 bg-transparent"></div>

  <!--Carousel-->
  <div class="container-fluid p-5 col-md-9">
    <div id="carouselExample" class="carousel slide shadow rounded-4 col-12 mx-auto">
      <div class="carousel-inner rounded-4 shadow">
        <div class="carousel-item active">
          <a href="https://youtu.be/GG3Eg7LDKC4">
            <img src="https://res.cloudinary.com/juandacloud/image/upload/v1692313281/afoco-projects/tedi/1_pwfwj8.jpg" class="d-block col-12" alt="...">
          </a>
        </div>
        <div class="carousel-item">
          <img src="https://res.cloudinary.com/juandacloud/image/upload/v1692313280/afoco-projects/tedi/3_fzi4ps.jpg" class="d-block col-12" alt="...">
        </div>
        <div class="carousel-item">
          <img src="https://res.cloudinary.com/juandacloud/image/upload/v1692313281/afoco-projects/tedi/5_ue9mpv.jpg" class="d-block col-12" alt="...">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>

  <!--Section Playlist-->
  <div class="container-fluid py-5">
    <div class="col-md-10 mx-auto">
      <div>
        <p class="text-light fs-4 text fw-semibold">Playlist</p>
      </div>
      <div class="row">
        <div class="col">
          <a href="https://youtube.com/playlist?list=PLNOMiKpKt0LShYImqpVFve2Vw81-Lvt_y&si=8cWoOSSjegg4rblu">
            <img class="border-3 border border-secondary rounded shadow col-12"
              src="https://res.cloudinary.com/juandacloud/image/upload/v1686788991/afoco-projects/tedi/experience/playlist/1_zxyx4s.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtube.com/playlist?list=PLNOMiKpKt0LSzVCVVRagv3gioENq62zPt&si=Fu3Ezz1KXhGLWxxE">
            <img class="border-3 border border-secondary rounded shadow col-12"
              src="https://res.cloudinary.com/juandacloud/image/upload/v1686788992/afoco-projects/tedi/experience/playlist/2_swsfif.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtube.com/playlist?list=PLNOMiKpKt0LRBDpS3UKfNBI3v70fKcPeR&si=xcjy2NU9Oa346wzS">
            <img class="border-3 border border-secondary rounded shadow col-12"
              src="https://res.cloudinary.com/juandacloud/image/upload/v1686788991/afoco-projects/tedi/experience/playlist/3_qwqusd.jpg" alt="" srcset="">
          </a>
        </div>
      </div>
    </div>
  </div>

  <!--Section Banner--> <!--Problema de vazamento da pagina, o problema é o uso do p-0 na div comentada-->
  <!--<div class="container-fluid p-0 my-5">-->
  <div class="container-fluid my-5">
    <div class="row rounded shadow p-0"
      style="background-image: url('https://res.cloudinary.com/juandacloud/image/upload/v1692313271/afoco-projects/tedi/banner2_ebk7w1.jpg'); background-position: center; background-repeat: no-repeat; background-size: cover; min-height: 40vh;">
      <div class="col-md-5 d-flex flex-column text-center align-items-center p-5">
        <a href=""><img class="img-fluid col-md-3" src="assets/images/TEDI_Letra Azul_Logo-Nom-02.png"
            alt="logo-Blue"></a>
        <p class="col-6 fs-4 fw-bold text-dark my-4 text-uppercase">Tu bienestar, nuestra prioridad</p>
        <div>
           <!--Retirado parar deploy-->
          <!--
          <a href="" class="btn text-light my-1" style="background-color: #2e5e88;">¡Conoce más!</a>
          -->
        </div>
      </div>
      <div class="col-md-6"></div>
    </div>
  </div>


  <!--Relatos de vida-->
  <div class="container-fluid py-5">
    <div class="col-md-10 mx-auto">
      <div>
        <p class="text-light fs-4 text fw-semibold">Relatos de Vida</p>
      </div>
      <div class="row mb-5">
        <div class="col">
          <a href="https://youtu.be/8MdWGBuBCFo">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692465243/afoco-projects/tedi/experience/playlist/relatosDeVida/4_awwrqu.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/-T5hRRRCHiE">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692465244/afoco-projects/tedi/experience/playlist/relatosDeVida/5_watili.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/E5HsQyvCVlQ">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692465243/afoco-projects/tedi/experience/playlist/relatosDeVida/6_idtobl.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/XnvIgfyG0T0">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692465243/afoco-projects/tedi/experience/playlist/relatosDeVida/7_xnhl7z.jpg" alt="" srcset="">
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <a href="https://youtu.be/umwS3v-BhJ0">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692465243/afoco-projects/tedi/experience/playlist/relatosDeVida/8_mpy85p.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/PU1SP6xOSRk">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692465243/afoco-projects/tedi/experience/playlist/relatosDeVida/9_knoocm.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/MBvOktCQzAc">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692465243/afoco-projects/tedi/experience/playlist/relatosDeVida/10_ocrftz.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/jMkbtPXypos">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692465244/afoco-projects/tedi/experience/playlist/relatosDeVida/11_vxf1nt.jpg" alt="" srcset="">
          </a>
        </div>
      </div>
    </div>
  </div>

  <!--Life Stories-->
  <div class="container-fluid py-5">
    <div class="col-md-10 mx-auto">
      <div>
        <p class="text-light fs-4 text fw-semibold">Life Stories</p>
      </div>
      <div class="row mb-5">
        <div class="col">
          <a href="https://youtu.be/CbU_hnLtprE">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692466096/afoco-projects/tedi/experience/playlist/lifeStories/12_glryo8.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/AZlfBpWuTtg">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692466096/afoco-projects/tedi/experience/playlist/lifeStories/13_clykxf.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/mltUjPf0N0o">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692466097/afoco-projects/tedi/experience/playlist/lifeStories/14_dlnviw.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/K3HibU6oFao">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692466097/afoco-projects/tedi/experience/playlist/lifeStories/15_eyarv1.jpg" alt="" srcset="">
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <a href="https://youtu.be/82vvpVK8uM0">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692466096/afoco-projects/tedi/experience/playlist/lifeStories/16_my4gu5.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/cijFhZbcsEQ">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692466096/afoco-projects/tedi/experience/playlist/lifeStories/17_o9yyyy.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/cijFhZbcsEQ">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692466096/afoco-projects/tedi/experience/playlist/lifeStories/18_qilowd.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/WO4DryPrlS4">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692466096/afoco-projects/tedi/experience/playlist/lifeStories/19_itozbq.jpg" alt="" srcset="">
          </a>
        </div>
      </div>
    </div>
  </div>

  <!--Storie di Vita-->
  <div class="container-fluid py-5">
    <div class="col-md-10 mx-auto">
      <div>
        <p class="text-light fs-4 text fw-semibold">Storie di Vita</p>
      </div>
      <div class="row mb-5">
        <div class="col">
          <a href="https://youtu.be/73mftIwrLBU">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467152/afoco-projects/tedi/experience/playlist/StorieDiVita/22_asz7gb.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/WK8Dijw-lBo">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467153/afoco-projects/tedi/experience/playlist/StorieDiVita/23_rwhuj0.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/0Jxnhq238tk">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467153/afoco-projects/tedi/experience/playlist/StorieDiVita/24_ctbc2s.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/sIYLfXqz9J4">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467153/afoco-projects/tedi/experience/playlist/StorieDiVita/25_gb2jtk.jpg" alt="" srcset="">
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <a href="https://youtu.be/EvDhYo8b6qQ">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467153/afoco-projects/tedi/experience/playlist/StorieDiVita/26_bqbpbu.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/zfFYAsdwPMs">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467153/afoco-projects/tedi/experience/playlist/StorieDiVita/27_eqs13j.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/akPnUQY-yJY">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467153/afoco-projects/tedi/experience/playlist/StorieDiVita/28_c5thhg.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/vLG3TmuhgeQ">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467153/afoco-projects/tedi/experience/playlist/StorieDiVita/29_lla8ve.jpg" alt="" srcset="">
          </a>
        </div>
      </div>
    </div>
  </div>

  <!--Histoires de Vie-->
  <div class="container-fluid py-5">
    <div class="col-md-10 mx-auto">
      <div>
        <p class="text-light fs-4 text fw-semibold">Histoires de Vie</p>
      </div>
      <div class="row mb-5">
        <div class="col">
          <a href="https://youtu.be/yiktlDtwZ7M">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467713/afoco-projects/tedi/experience/playlist/HistoiresDeVie/31_c4oitd.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/d0p4X6uGwi0">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467713/afoco-projects/tedi/experience/playlist/HistoiresDeVie/32_zy67fk.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/c1ItHsVh5rU">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467712/afoco-projects/tedi/experience/playlist/HistoiresDeVie/33_onjkml.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/kMvx7Qkeg7c">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467712/afoco-projects/tedi/experience/playlist/HistoiresDeVie/34_pgdu59.jpg" alt="" srcset="">
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <a href="https://youtu.be/LXmGKBoyjJU">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467712/afoco-projects/tedi/experience/playlist/HistoiresDeVie/35_wujkxm.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/-AFtXSKIjKY">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467712/afoco-projects/tedi/experience/playlist/HistoiresDeVie/36_vcfdei.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/Zlsln3F-f1s">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467712/afoco-projects/tedi/experience/playlist/HistoiresDeVie/37_nstvc9.jpg" alt="" srcset="">
          </a>
        </div>
        <div class="col">
          <a href="https://youtu.be/m33HQo1a0lg">
            <img class="border-3 rounded shadow col-12" src="https://res.cloudinary.com/juandacloud/image/upload/v1692467712/afoco-projects/tedi/experience/playlist/HistoiresDeVie/38_nuymys.jpg" alt="" srcset="">
          </a>
        </div>
      </div>
    </div>
  </div>
  <!--Footer-->
  <app-footer></app-footer>
  <!--<div id="footerDark"></div>-->
</div>
