import { Component, HostListener, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { onAuthUIStateChange, AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { AmplifyService } from 'aws-amplify-angular';
import { Auth } from 'aws-amplify';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { Paciente } from 'src/app/model/modelo-paciente';
import { AdministradorService } from 'src/app/service/adm/adm.service';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css']
})
export class CabecalhoComponent implements OnInit {
  escondido = false;//Esconder os botoes na producao

  autenticado = false;
  cadastro = false;
  zoneinfo = '';
  registrado = false;
  profile = '';
  public innerWidth: any;
  userId: CognitoUserInterface | undefined;
  authState: AuthState | undefined;
  usuario = ' ';

  adm = {
    name: '',
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private servico: CadastroService,
    private ref: ChangeDetectorRef,
    private amplifyService: AmplifyService,
    private servicoADM: AdministradorService,
    private router: Router
  ) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$.subscribe(authState => {
      console.log(authState)
      if (authState.state == "signedIn") {
        this.usuario = authState.user.e
        this.autenticado = true;
        this.zoneinfo = authState.user.attributes.zoneinfo;
        this.profile = authState.user.attributes.profile;
        this.getPaciente(authState.user.username);
        this.getUsuarioID(authState.user.username);
      }
    })
  }

  getPaciente(id: string) {
    this.servico.getPaciente(id).subscribe((usuario: Paciente) => {
      if (usuario.id == id) {
        this.registrado = true;
      }
      if (usuario.name != null){
        this.cadastro = true;
      }
    })
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.userId = authData as CognitoUserInterface;
      this.ref.detectChanges();
    })
  }

  getUsuarioID(id: string) {
    this.servicoADM.getAdmID(id).subscribe((usuario: any) => {
      this.construirADMnome(usuario);
    })
  }
  construirADMnome(usuario: any){
    this.adm.name = usuario.name;
  }
  // Encaminha para a pag de logar
  toLogar() {
    this.router.navigate(['logar'])
  }
  // Encaminha para a pag de registrar
  toRegistra() {
    this.router.navigate(['registrar'])
  }
  toPerfil() {
    this.router.navigate(['perfil'])
  }
  toExperincia() {
    this.router.navigate(['experiencia'])
  }
  toComunidad() {
    this.router.navigate(['comunidad'])
  }
  toNosotros() {
    this.router.navigate(['nosotros'])
  }
  toEdit(){
    this.router.navigate(['edit-paciente'])
    setTimeout(function () { location.reload(); })
  }
  toPrincipal() {
    this.router.navigate(['principal'])
    setTimeout(function () { location.reload(); })
  }
  toADM(){
    this.router.navigate(['adm'])
  }
  toPrecadastro(){
    this.router.navigate(['pre-cadastro'])
  }
  toMedico(){
    if (this.zoneinfo == 'welcome') {
      this.router.navigateByUrl('sala-de-confirmacao');//a mudar
      setTimeout(function () { location.reload(); }, 0)
    } else if (this.zoneinfo == 'waiting') {
      this.router.navigateByUrl('espera');
      //setTimeout(function () { location.reload(); }, 0)
    } else if (this.zoneinfo == 'denied' || this.zoneinfo == 'registered' || this.zoneinfo == null) {
      this.router.navigateByUrl('resgistro-medico');
      setTimeout(function () { location.reload(); }, 0)
    } else if (this.zoneinfo == 'accepted') {
      console.log(this.profile)
      if (this.profile == 'medico-especialista') {
        this.router.navigateByUrl('principalE');
      } else if (this.profile == 'medico-geral') {
        this.router.navigateByUrl('principalG');
      }
    }
     else {
      console.log('error')
    }
  }

  async deslogar() {
    this.autenticado = false;
    this.router.navigate([''])
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  toHome() {
    setTimeout(function () { location.reload(); })
    this.router.navigate([''])
  }
  toQuienessomos() {
    this.router.navigate(['quienessomos'])
  }
  toEconverde() {
    this.router.navigate(['econverde'])
  }
  toComooperamos() {
    this.router.navigate(['comooperamos'])
  }
  toSmartfilms2020() {
    this.router.navigate(['smart2020'])
  }
  toCovid19() {
    this.router.navigate(['covid19'])
  }
  ngOnDestroy() {
    return onAuthUIStateChange;
  }
}
