<br>
<h1 class="texto">Orietaciones Programadas</h1>
<hr>

<mat-card class="example-card">
    <mat-card-header>
        <div mat-card-avatar class="example-header-image"> </div>
        <mat-card-title class="texto">Dr. {{nome}}</mat-card-title>
        <mat-card-subtitle class="texto">{{horario}}</mat-card-subtitle>
        <mat-card-subtitle class="texto">{{data}}</mat-card-subtitle>

    </mat-card-header>
    <mat-card-content>
        <h2 class="texto">Avaliacao</h2>
        <button mat-icon-button color="accent" *ngFor="let r of ratingArr; index as i" 
            (click)="onClick(i)" class="star">
            <mat-icon>
                {{ returnStar(i) }}
            </mat-icon>
        </button>
    </mat-card-content>
    <mat-card-content>
        <h2 class="texto">Comentarcio</h2>
        <mat-form-field appearance="fill" class="chat">
            <textarea matInput class="chatBox"></textarea>
        </mat-form-field>
    </mat-card-content>
    <button mat-raised-button color="primary" style="left: 80%;">Enviar</button>
</mat-card>

<button mat-raised-button color="warn" class='botaoVoltar' (click)='toHistorico()'>
    <mat-icon>reply</mat-icon>voltar
</button>